/**
 * Created by agf on 18/12/2016.
 */

/***
 * Variables comunes para todas las vistas de admin
 * $scope.roles
 * $scope.adminFolders
 * $scope.companies
 */
 
contaApp.controller('UserAdminController', ['$scope', 'toastr', '$http', 'RemoteFilesService', function ($scope, toastr, $http, RemoteFilesService) {
    var $ctrl = this;
    var currentApp = $('#wrapper').data('app-id');
    $scope.informacion_disponible = false;

    //lista de roles - $scope.roles
    $http.get(currentApp + '/role_list').then(function (res) {
        $scope.roles = res.data;
    });

    //carpetas de la Asesoria - $scope.adminFolders
    RemoteFilesService.listFoldersAdmin().then(function (res) {
        
        $scope.adminFolders = res;

        //Lista de empresas $scope.companies
        $http.get(currentApp + '/1/empresa_list').then(function (res2) {
            var resutls = [];
            angular.forEach(res2.data.empresasList, function (o, i) {
                o.avilableFolders = angular.copy($scope.adminFolders);
                var carpeta = o.carpeta.display_name;
                // console.log(carpeta);
                angular.forEach(o.avilableFolders, function (obj, ii) {
                    var lista = obj.name;
                    // console.log(lista);
                    // console.log(carpeta == lista);
                    if (carpeta == lista) obj.selected = true;
                });
                resutls.push(o);
            });
            $scope.companies = resutls;
        });

        $scope.informacion_disponible = true;
    });

    $ctrl.showPanel = function ($event) {
        $event.stopImmediatePropagation();
        $scope.selectedPanel = $($event.target).data('panel');
        $scope.$broadcast($scope.selectedPanel, $scope);
        console.log($scope.selectedPanel);
    };

    //función para publicar mensajes en panel
    $scope.showNotification = function (text, type) {

        //damos formato en función del tipo de alerta
        switch (type) {
        case 'success':
            toastr.success(text);
            break;
        case 'success-top':
            toastr.success(text);
            window.scrollTo(0, 0);
            break;
        case 'info':
            toastr.info(text);
            break;
        case 'warning':
            toastr.warning(text);
            break;
        case 'error':
            toastr.error(text, 
            {
                closeButton: true
            });
            break;
        }
    };

    $scope.localLang = {
        selectAll: "Elegir todos",
        selectNone: "Ninguno",
        reset: "Deshacer",
        search: "Escribir para buscar...",
        nothingSelected: "Sin selección"
    };
}]);