// Menu abierto en movil por defecto
if (($(window).width() < 768) && !$("#wrapper").hasClass("toggled")) {
    //$("#wrapper").toggleClass("toggled");
    $("#menu-toggle2").toggleClass("collapsed");
}

//Toggle button
$("#menu-toggle2").click(function(e) {
    e.stopPropagation();
    $("#wrapper").toggleClass("toggled");
    $("#menu-toggle2").toggleClass("collapsed");
});

$('.panel-heading a').click(function() {
    $('.panel-heading').removeClass('panel-active');
    $(this).parents('.panel-heading').addClass('panel-active');
});


$('.entrada_menu').click(function(e) {
    e.stopPropagation();
    if ($(window).width() < 768) {
        $("#wrapper").toggleClass("toggled");
        $("#menu-toggle2").toggleClass("collapsed");
    }
});

