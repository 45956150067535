contaApp.controller('GastosController', ['$scope', '$http', '$filter', function($scope, $http, $filter, $visits) {

  $scope.listaImprimible = [];

  //LOGICA PARA MOSTRAR Y GUARDAR CLIENTES
//---
  $scope.saveClientVisible = false;
  $scope.saveChangesVisible = false;

  $scope.saveClientShow = function() {
    if ($scope.newGasto.cif != undefined) {
        if (!cifResgister()) {
          $scope.saveClientVisible = true;
          return true;
        } else {
          $scope.saveClientVisible = false;
          return  false;
        }
      } 
  };

  $scope.saveChangesShow = function() {
    if ($scope.newGasto.cif != undefined) {
      if (cifResgister() && clientIsChanged()) {
        $scope.saveChangesVisible = true;
        return true;
      } else {
        $scope.saveChangesVisible = false;
        return false;
      }
    }
  };


  function clientIsChanged() {
    client = getClient($scope.newGasto.cif);
    if (client[0] != undefined) {
      return ((client[0].nombre_proveedor != $scope.newGasto.name) || (client[0].email != $scope.newGasto.email) || (client[0].direccion != $scope.newGasto.direccion));
    } else {
      return false;
    }
  }

  function getClient(cif) {
    return $.map($scope.proveedorList, function (item) {
      if (item.cif_proveedor == cif) {
          return item;
      }
      return null;
    });
  }

  function updateProveedor() {
    return $.map($scope.proveedorList, function (item) {
      if (item.cif_proveedor == $scope.newGasto.cif) {
          
          //local
          item.nombre_proveedor = $scope.newGasto.name;
          item.email = $scope.newGasto.email;
          item.direccion = $scope.newGasto.direccion;

          //servidor
          $scope.updateProveedorToServer({nombre_proveedor: $scope.newGasto.name.toUpperCase(), cif_proveedor: $scope.newGasto.cif, email: $scope.newGasto.email, direccion: $scope.newGasto.direccion });
          return true;
      }
      return false;
    });
  }

  function cifResgister() {
    var cifInList = false;
    for (i in $scope.proveedorList) 
    {
      if ($scope.newGasto.cif == $scope.proveedorList[i].cif_proveedor ) {
        cifInList =true;
      }
    }
    return cifInList;  
  } 

  //AUTOCOMPLETAR CLIENTES/DNI
  $('#proveedor_gasto').autocomplete({
      source: function (request, response) {
          var term = request.term;
          var data = proveedorAutoComplete(term, $scope);
          response(data);
      },
      minLength: 1,
      focus: function (event, ui) {
          //$(this).val(ui.item.cif_proveedor + ' - ' + ui.item.nombre_proveedor);
          return false;
      },
      select: function (event, ui) {
          $(this).val(ui.item.nombre_proveedor);
          $scope.newGasto.cif = ui.item.cif_proveedor;
          $scope.newGasto.name = ui.item.nombre_proveedor;
          $scope.newGasto.email = ui.item.email;
          $scope.newGasto.direccion = ui.item.direccion;
          $scope.$digest();
          return false;
      },
      messages: {
          noResults: '',
          results: function () {
          }
      }
  }).autocomplete('instance')._renderItem = function (ul, item) {
      return $("<li>")
          .append("<span class='ac-option'>" + item.cif_proveedor + " - " + item.nombre_proveedor + "</span>")
          .appendTo(ul);
  };

  function proveedorAutoComplete(term, $scope) {
      return $.map($scope.proveedorList, function (item) {
          if (item.nombre_proveedor.match(term.toUpperCase()) || item.cif_proveedor.match(term)) {
              return item;
          }
          return null;
      });
  };
//----
  //CALCULOS LIVE DEL FORMULARIO      
  $scope.fecha_hoy = function() {
    $( "#dateinput-gastos" ).datepicker( "setDate", moment().format('DD/MM/YYYY') );
    return moment().format('DD/MM/YYYY');
  }

  $scope.calcular_iva = function() {

    var base = $scope.calcular_base();
    if ( ($scope.newGasto.base != undefined) || (($scope.newGasto.base_imponible0 != undefined) || ($scope.newGasto.base_imponible1 != undefined) || ($scope.newGasto.base_imponible2 != undefined) ||($scope.newGasto.base_imponible3 != undefined)) ) {
            switch ($scope.newGasto.type_iva.id) {
                case "0": return 0;
                case "1": var total = parseFloat(base * 0.04 || 0); break;
                case "2": var total = parseFloat(base * 0.1 || 0); break;
                case "3": var total = parseFloat(base * 0.21 || 0); break;
                case "4": 
                    if ($scope.newGasto.base_imponible1 != undefined) { var base_imponible1 = $scope.newGasto.base_imponible1.toString().trim().replace(",","."); }
                    if ($scope.newGasto.base_imponible2 != undefined) { var base_imponible2 = $scope.newGasto.base_imponible2.toString().trim().replace(",","."); }
                    if ($scope.newGasto.base_imponible3 != undefined) { var base_imponible3 = $scope.newGasto.base_imponible3.toString().trim().replace(",","."); }
                    var total = parseFloat(base_imponible1 * 0.04 || 0) + parseFloat(base_imponible2 * 0.1 || 0) + parseFloat(base_imponible3 * 0.21 || 0);
                    break;
            } 
          return Math.round(total * 100) / 100 || 0;
    } else {
      return '';
    }
  }
  
  $scope.calcular_total = function() {
      var base = $scope.calcular_base();
      var iva =  $scope.calcular_iva();
      var recargo = $scope.calcular_recargo();
      var retenciones = $scope.calcular_retenciones();

      if ( base > 0 ) {
          cero_o_null = "0";
      } else {
           cero_o_null = null;
      }

      var total = base + iva + recargo - retenciones;
      $scope.newGasto.total_gasto =  Math.round(total * 100) / 100 || cero_o_null;
      return Math.round(total * 100) / 100 || cero_o_null;
  }

  $scope.calcular_base = function() {
    if ( ($scope.newGasto.base != undefined) || (($scope.newGasto.base_imponible0 != undefined) ||($scope.newGasto.base_imponible1 != undefined) || ($scope.newGasto.base_imponible2 != undefined) ||($scope.newGasto.base_imponible3 != undefined)) ) {
            if ($scope.newGasto.base != undefined) {base = $scope.newGasto.base.toString().trim().replace(",",".");}
            switch ($scope.newGasto.type_iva.id) {
                case "0": var total = parseFloat(base || 0); break;
                case "1": var total = parseFloat(base || 0); break;
                case "2": var total = parseFloat(base || 0); break;
                case "3": var total = parseFloat(base || 0); break;
                case "4": 
                    if ($scope.newGasto.base_imponible0 != undefined) { var base_imponible0 = $scope.newGasto.base_imponible1.toString().trim().replace(",","."); }
                    if ($scope.newGasto.base_imponible1 != undefined) { var base_imponible1 = $scope.newGasto.base_imponible1.toString().trim().replace(",","."); }
                    if ($scope.newGasto.base_imponible2 != undefined) { var base_imponible2 = $scope.newGasto.base_imponible2.toString().trim().replace(",","."); }
                    if ($scope.newGasto.base_imponible3 != undefined) { var base_imponible3 = $scope.newGasto.base_imponible3.toString().trim().replace(",","."); }
                    var total = parseFloat(base_imponible0 || 0) + parseFloat(base_imponible1 || 0) + parseFloat(base_imponible2 || 0) + parseFloat(base_imponible3 || 0); break;
            } 
          return Math.round(total * 100) / 100 || null;
    } else {
      return '';
    }
  }


  $scope.calcular_recargo = function() {
    var base = $scope.calcular_base();
    if ( ($scope.newGasto.base != undefined) || (($scope.newGasto.base_imponible0 != undefined) || ($scope.newGasto.base_imponible1 != undefined) || ($scope.newGasto.base_imponible2 != undefined) ||($scope.newGasto.base_imponible3 != undefined)) ) {
            switch ($scope.newGasto.type_iva.id) {
                case "0": return 0;
                case "1": var total = parseFloat(base * 0.005 || 0); break;
                case "2": var total = parseFloat(base * 0.014 || 0); break;
                case "3": var total = parseFloat(base * 0.052 || 0); break;
                case "4": 
                    if ($scope.newGasto.base_imponible1 != undefined) { var base_imponible1 = $scope.newGasto.base_imponible1.toString().trim().replace(",","."); }
                    if ($scope.newGasto.base_imponible2 != undefined) { var base_imponible2 = $scope.newGasto.base_imponible2.toString().trim().replace(",","."); }
                    if ($scope.newGasto.base_imponible3 != undefined) { var base_imponible3 = $scope.newGasto.base_imponible3.toString().trim().replace(",","."); }
                    var total = parseFloat(base_imponible1 * 0.005 || 0) + parseFloat(base_imponible2 * 0.014 || 0) + parseFloat(base_imponible3 * 0.052 || 0); break;
            } 
          //$scope.newGasto.aplicaRecargo multiplica para dejar a cero si no lo aplicamos
          return Math.round(total * $scope.newGasto.aplicaRecargo * 100) / 100 || 0;
    } else {
      return '';
    }
  }

  $scope.calcular_retenciones = function() {
      var base = $scope.calcular_base();
      total_retenciones = base * parseFloat($scope.newGasto.retencion.trim().replace(",",".") || 0) * 0.01 ;
      $scope.newGasto.total_retenciones =  Math.round(total_retenciones * 100) / 100 || 0;
      return $scope.newGasto.total_retenciones;
  }

  //LIMPIAR FORMULARIO
  $scope.clear_form = function () {

    //LIMPIAR ATRIBUTOS DE FORMULARIO
    $scope.gastosForm.$setUntouched();
    $scope.gastosForm.$setPristine();
    $scope.gastosForm.$submitted = false;

    $( "#dateinput-gastos" ).datepicker( "setDate", moment(Date.now()).format('DD/MM/YYYY') );
    $scope.newGasto.date =  moment().format('DD/MM/YYYY');    
    
    $scope.newGasto.control = '';
    $scope.newGasto.concepto = '';
    $scope.newGasto.idgasto = '';
    $scope.newGasto.name = '';
    $scope.newGasto.cif = '';
    $scope.newGasto.email = '';
    $scope.newGasto.direccion = '';
    $scope.newGasto.observaciones = '';
    $scope.newGasto.base = '';

    $scope.newGasto.base_imponible0 = '';
    $scope.newGasto.base_imponible1 = '';
    $scope.newGasto.base_imponible2 = '';
    $scope.newGasto.base_imponible3 = '';

    $scope.newGasto.retenciones = '';
    //$scope.newGasto.type_gasto = $scope.data.typeGastoOptions[0];
    $scope.newGasto.type_iva = $scope.data.ivaOptions[3];
    $scope.saveClientVisible = false;
    $scope.saveChangesVisible = false;
    //actualizamos globlamente el número de gasto actual
    $scope.$parent.idGastoActual++;
  }

  $scope.clear_lote = function () {
    $scope.xlsxvisible = false;
    $scope.$parent.gastos = [];
    $scope.$parent.idLoteGastosActual++;
    $scope.lote_gastos_cargado_sin_enviar = false;
  }

//SUBMIT 
  $scope.gastosFormSubmit = function($event)  {
    $event.stopPropagation();
     
    if ($scope.gastosForm.$invalid) {
        $scope.gastosForm.$setSubmitted();
        $scope.showAlert('El formulario contiene datos incorrectos', 'error');
    } else {
      $scope.anadirGasto();
    }
  }
    
  //AGREGRAR UN GASTO AL LOTE DE GASTOS EN SERVIDOR-LOCAL(TABLA)
  $scope.anadirGasto = function () {
    
    //guardar proveedor proveedor si es nuevo
    if ( ($scope.newGasto.save_client) && ($scope.saveClientVisible) ){


      if ($scope.newGasto.name == undefined) $scope.newGasto.name = '';

      //Insertamos proveedor en local
      $scope.proveedorList.push({nombre_proveedor: $scope.newGasto.name.toUpperCase(), cif_proveedor: $scope.newGasto.cif, email: $scope.newGasto.email, direccion: $scope.newGasto.direccion });

      //Insertamos proveedor en servidor
      $scope.insertProveedorToServer({nombre_proveedor: $scope.newGasto.name.toUpperCase(), cif_proveedor: $scope.newGasto.cif, email: $scope.newGasto.email, direccion: $scope.newGasto.direccion });
    }

    //Actualizamos proveedor si ha cambiado datos (mismo NIF)
    if ( ($scope.newGasto.save_changes) && ($scope.saveChangesVisible) )  {
      updateProveedor();
    }
     //si han introducido coma ',' para los decimales
    $scope.newGasto.base = $scope.calcular_base();
    if ($scope.newGasto.base_imponible0 != undefined) { $scope.newGasto.base_imponible0 = $scope.newGasto.base_imponible1.toString().trim().replace(",","."); }    
    if ($scope.newGasto.base_imponible1 != undefined) { $scope.newGasto.base_imponible1 = $scope.newGasto.base_imponible1.toString().trim().replace(",","."); }
    if ($scope.newGasto.base_imponible2 != undefined) { $scope.newGasto.base_imponible2 = $scope.newGasto.base_imponible2.toString().trim().replace(",","."); }
    if ($scope.newGasto.base_imponible3 != undefined) { $scope.newGasto.base_imponible3 = $scope.newGasto.base_imponible3.toString().trim().replace(",","."); }

//---- GUARDAR GASTO EN SERVIDOR (Y SI SALE BIEN EN LOCAL)
    //preparamos los datos
        switch ($scope.newGasto.type_iva.id ) {
          case '0':
              $scope.newGasto.base_imponible0 = $scope.newGasto.base;
              $scope.newGasto.base_imponible1 = $scope.newGasto.base_imponible2 =  $scope.newGasto.base_imponible3 = 0;
          break;
          case '1':
              $scope.newGasto.base_imponible1 = $scope.newGasto.base;
              $scope.newGasto.base_imponible0 = $scope.newGasto.base_imponible2 =  $scope.newGasto.base_imponible3 = 0;
          break;
          case '2':
              $scope.newGasto.base_imponible2 = $scope.newGasto.base;
              $scope.newGasto.base_imponible0 = $scope.newGasto.base_imponible1 =  $scope.newGasto.base_imponible3 = 0;
          break;
          case '3':
              $scope.newGasto.base_imponible3 = $scope.newGasto.base;
              $scope.newGasto.base_imponible0 = $scope.newGasto.base_imponible1 =  $scope.newGasto.base_imponible2 = 0;

          break;
          case '4':
            $scope.newGasto.base =
              parseFloat($scope.newGasto.base_imponible0 || 0)
              + parseFloat($scope.newGasto.base_imponible1 || 0)
              + parseFloat($scope.newGasto.base_imponible2 || 0)
              + parseFloat($scope.newGasto.base_imponible3 || 0);
          break;
        }

    //Preparamos los datos del formulario para crear el registro de inserción
    $scope.newGasto.total_iva = $scope.calcular_iva();
    $scope.newGasto.total_gasto = $scope.calcular_total();
    $scope.newGasto.total_recargo = $scope.calcular_recargo();
    $scope.newGasto.total_retenciones = $scope.calcular_retenciones();

    //"ÑAPA" PORQUE AL RELACIÓN JQUERY ANGULAR ME CAMBIA A VECES EL VALOR MOMENT A STRING
    if (typeof $scope.newGasto.date == "string") {
      fecha_insert =  moment($scope.newGasto.date, 'DD/MM/YYYY');
    } else {
      fecha_insert =  $scope.newGasto.date;
    }

    register = {
      fecha: moment(fecha_insert).format('DD/MM/YYYY'),
      fecha_date: fecha_insert,
      idgasto: $scope.newGasto.idgasto,
      control: $scope.newGasto.control,
      concepto: $scope.newGasto.concepto,
      proveedor_nombre: $scope.newGasto.name,
      proveedor_cif: $scope.newGasto.cif,
      base_imponible: $scope.newGasto.base,
      base_imponible0: $scope.newGasto.base_imponible0,
      base_imponible1: $scope.newGasto.base_imponible1,
      base_imponible2: $scope.newGasto.base_imponible2,
      base_imponible3: $scope.newGasto.base_imponible3,
      total_iva: $scope.newGasto.total_iva,
      total_recargo: $scope.newGasto.total_recargo,
      total_retenciones: $scope.newGasto.total_retenciones,
      total_gasto: $scope.newGasto.total_gasto,
      observaciones: $scope.newGasto.observaciones,
    }
    $scope.insertGastoToServer($scope.idLoteGastosActual, register, register);
  };

  //FUCIONES DE TABLA

  $scope.predicates = ['fecha', 'proveedor_proveedor', 'tipo_gasto', 'base_imponible', 'total_iva', 'total_gasto', 'cuenta_cargo', 'cuenta_cargo'];
  $scope.selectedPredicate = $scope.predicates[0];

  $scope.getters={
    fecha: function (value) {
        return new Date(moment(value.fecha, 'D-M-YYYY').toISOString());
    }
  }
  
  //remove to the real data holder
  $scope.removeItem = function removeItem(row) {
      var index = $scope.$parent.gastos.indexOf(row);
      if (index !== -1) {
        //BORRAR EN SERVIDOR
        $scope.deleteGastoToServer($scope.idLoteGastosActual, row.id_gasto_insertado, index);
      }
      //FALTA BORRAR DEL SERVIDOR
  };

  $scope.editItem = function editItem(row) {
    var index = $scope.$parent.gastos.indexOf(row);
    if (index !== -1) {
        $scope.newGasto = row;
        $scope.$parent.gastos.splice(index, 1);
        window.scrollTo(0, 0);
    }
  };

  // ACCIONES POR REMESA
  $scope.sendFTP = function ($event) {
    $event.stopImmediatePropagation();
    $scope.updateLoteStatusToServer($scope.idLoteGastosActual, "ftp");
    $scope.showNotification('Fichero xlsx enviado por FTP. Se ha generado el lote ' + $scope.idLoteGastosActual, 'success');
    $scope.clear_lote();
  };

  $scope.sendEmail = function ($event) {
    $event.stopImmediatePropagation();

     $http({
        method: 'POST',
        url: '/email/lote-gastos-to-asesor',
        data: {
          _token: $scope.csfrToken,
          empresa_id: $scope.empresa_id,
          idlote: $scope.idLoteGastosActual
        },
        responseType: 'arraybuffer' 
      }).then(function success(response) {
        $scope.updateLoteStatusToServer($scope.idLoteGastosActual, "mail");
      }, function error(response) {
          $scope.showNotification('No se ha podido enviar el email. Por favor, vuelva a intentarlo pasados unos instantes', 'error');
      });
  };



 //generar tabla de lote de gastos en xlsx
  $scope.exportList2xlsx = function ($event) {
     $event.stopImmediatePropagation();
     var gastosExcel = [];
     for (var i=0; i<$scope.$parent.gastos.length; i++) { 
        gasto = $scope.$parent.gastos[i];
        gastosExcel.push($scope.fromFormatoStandardGastosToERP(gasto, 'despachos'));
     } 
     $scope.$parent.exportList2xlsx(gastosExcel);
     $scope.showNotification('Descarga de fichero .xlsx correcta', 'success');
  };

//En función del ERP generar un excel a partir de un objeto con el formato necesario
  $scope.fromFormatoStandardGastosToERP = function(gasto, formato_erp) {
    if (gasto.total_recargo > 0) {
      var aplicaRecargo = 1;
    } else {
      var aplicaRecargo = 0;
    }

    factor_retenciones = gasto.total_retenciones/gasto.base_imponible*100;

    switch (formato_erp) {
      case 'despachos':
        var gasto_formateado = {

          Lote: gasto.idlote,
          Gasto: gasto.idgasto,
          Fecha: gasto.fecha,
          
          'Cliente': gasto.proveedor_nombre,
          'CIF/DNI': gasto.proveedor_cif,
          'Concepto': gasto.concepto,          
          'Control': gasto.control,                    
          '%Retención': number_format_es_ES(gasto.retenciones_factor),

          'Base Imponible': number_format_es_ES(gasto.base_imponible),
          'Total IVA': number_format_es_ES(gasto.total_iva),
          'Total Recargo': number_format_es_ES(gasto.total_recargo),
          'Cuota Retención': number_format_es_ES(gasto.total_retenciones),          
          'Importe Factura': number_format_es_ES(gasto.total_gasto),

          'Base Imponible0': number_format_es_ES(gasto.base_imponible0),

          'Base Imponible1': number_format_es_ES(gasto.base_imponible1),
          'Iva1': '4',
          'Cuota Iva1': number_format_es_ES(gasto.base_imponible1 * 0.04),
          '%RecEq1': '0,005',
          'Cuota Rec1': number_format_es_ES(gasto.base_imponible1 * 0.005  * aplicaRecargo),

          'Base Imponible2': number_format_es_ES(gasto.base_imponible2),
          'Iva2': '10',
          'Cuota Iva2': number_format_es_ES(gasto.base_imponible2 * 0.1),
          'RecEq2': '0,014',
          'Cuota Rec2': number_format_es_ES(gasto.base_imponible2 * 0.014  * aplicaRecargo),

          'Base Imponible3': number_format_es_ES(gasto.base_imponible3),
          'Iva3': '21',
          'Cuota Iva3': number_format_es_ES(gasto.base_imponible3 * 0.21),
          'RecEq3': '0,052',
          'Cuota Rec3': number_format_es_ES(gasto.base_imponible3 * 0.052  * aplicaRecargo),

          'Observaciones': gasto.observaciones,

        }
        break;
    }
    return gasto_formateado;
  }


  //generar tabla de lote de gastos en pdf
  $scope.pdfReceiveGastos = function ($event) {
    $event.stopPropagation();
          $http({
          method: 'POST',
          url: '/pdf/tabla-gastos',
          data: {_token: $scope.csfrToken, gastos: $scope.$parent.gastos, empresa: $scope.empresaActiva.nombre, lote: $scope.lote},
          responseType: 'arraybuffer' 
      }).then(function success(response) {
           var file = new Blob([response.data], {type: 'application/pdf'});
           saveAs(file, $scope.empresaActiva.nombre + '_' + moment(Date.now()).format('YYYY-MM-DD_HH-mm-ss') + '.pdf');

      }, function error(response) {
              $scope.showNotification('Petición fallida: ' + response.message, 'error');
              //console.log( 'La petición no se ha producido: ' + response.message); //lshdebug
      });
  };


//Funcion para ACTUALIZAR STATUS DEL LOTE (enviado por FTP o por EMAIL)
 $scope.updateLoteStatusToServer = function(idlote, status) {
  $http({
      method: 'POST',
      url: '/empresa/actualizar-status-lote-gastos',
      data: {
          _token: $scope.csfrToken,
          empresa_id: $scope.empresa_id,
          idlote: idlote,
          status: status,
      }
  }).then(function success(response) {
     //si se actualiza correctamente en servidor, actualizar en local lote y gastos
     var gastos_lote_actualizado = response.data.gastos_lote_actualizado;

      for(var i = 0; i < gastos_lote_actualizado.length; i++) {
        gastos_lote_actualizado[i].idlote = response.data.lote_actualizado.idlote;

        fecha_gasto= moment(gastos_lote_actualizado[i].fecha, "DD/MM/YYYY");
        if (fecha_gasto.isSameOrAfter(moment($scope.fecha_inicial_gastos, "DD/MM/YYYY")) && fecha_gasto.isSameOrBefore(moment($scope.fecha_final_gastos, "DD/MM/YYYY"))) { //insertamos presupuesto para mostrar si esta en el rango de fechas del historial
          $scope.gastos_historial.push(gastos_lote_actualizado[i]);
        }
      }

      $scope.showNotification('Lote ' + idlote + ' enviado correctamente a la asesoria', 'success-top');
            $scope.$parent.lote_gastos_sin_enviar = 0;

      $scope.empezarNuevoLote();

  }, function error(response) {
  }); 
 }

  //Cuando enviamos el lote en curso, empezamos uno nuevo
  $scope.empezarNuevoLote = function () {
     $scope.clear_lote();
  };

//Función para ELIMIMAR GASTO EN EL SERVIDOR   
  $scope.deleteGastoToServer = function(idlote, id_gasto_insertado, index) {
      $http({
          method: 'POST',
          url: '/empresa/eliminar-gasto',
          data: {
            _token: $scope.csfrToken,
            empresa_id: $scope.empresa_id,
            idlote: idlote,
            id_gasto_insertado: id_gasto_insertado,
          }
      }).then(function success(response) {
        $scope.gastos.splice(index, 1);
        $scope.listaImprimible.splice(index, 1);

      }, function error(response) {
          $scope.showNotification('No se ha podido eliminar el gasto', 'error');
      });  
  }

//Función para INSERTAR GASTO EN EL SERVIDOR   
  $scope.insertGastoToServer = function(idlote, gasto_nuevo, gasto_nuevo_imprimible_0) {

      $http({
          method: 'POST',
          url: '/empresa/insertar-gasto',
          data: {
              _token: $scope.csfrToken,
              empresa_id: $scope.empresa_id,
              idlote: idlote,
              gasto_nuevo: gasto_nuevo
          }
      }).then(function success(response) {    

      //---- GUARDAR LA GASTO EN LOCAL
        //Para vosualizar en tabla
        
        gasto_nuevo['id_gasto_insertado'] = response.data.id_gasto_insertado;
        $scope.$parent.gastos.push(gasto_nuevo);
        $scope.$parent.lote_gastos_sin_enviar = idlote;

        //Para Imprimir - Excel - Cambia formato según dónde lo queramos exportar (REVISAR SEPARAR IMPRIMIR DE FORMATO ERP)
        $scope.listaImprimible.push(gasto_nuevo_imprimible_0);

        //SI TODO OK
        $scope.xlsxvisible = true;
        $scope.clear_form();
        //actualizamos globlamente el número de gasto actual
        $scope.$parent.idGastoActual++;

          $scope.showNotification('El gasto se ha guardado en el servidor', 'success');
      }, function error(response) {
          $scope.showNotification('No se han podido guardar el gasto', 'error');
      });        
  }

}]);