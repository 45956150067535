
var contaApp = angular.module('contaApp',['ngSanitize', 'angular-loading-bar', 'ui.bootstrap', 'ui.date', 'smart-table',
    'ngFileUpload', 'toastr', 'ui.mask', 'isteven-multi-select', 'ngIdle', 'ngAnimate']);

contaApp.config(['$interpolateProvider', function ($interpolateProvider) {
    $interpolateProvider.startSymbol('<%');
    $interpolateProvider.endSymbol('%>');
}]);

contaApp.config(['cfpLoadingBarProvider', function (cfpLoadingBarProvider) {
    cfpLoadingBarProvider.includeBar = true;
    cfpLoadingBarProvider.latencyThreshold = 100;
    cfpLoadingBarProvider.includeSpinner = false;
}]);

contaApp.config(['$httpProvider', function ($httpProvider) {
    $httpProvider.interceptors.push(['$q', function ($q) {
        return {
            'responseError': function (response) {
                if (response.status === 401 || response.status === 403) {
                    window.location.href = '/sesion_expirada';
                } 
                return $q.reject(response);
            }
        }
    }]);
}]);

//Manejo de inactividad (para que no se caduque la sesión)
contaApp.config(['IdleProvider', 'KeepaliveProvider', function(IdleProvider, KeepaliveProvider) {
    IdleProvider.idle(60*60); // 60 minutes idle
    IdleProvider.timeout(15); // after 15 seconds idle, time the user out
}]);

contaApp.run(['$http', 'Idle', 'toastr', '$rootScope', function ($http, Idle, toastr, $rootScope) {
    if ((window.location.pathname != '/sesion_expirada') && (window.location.pathname != '/login')) {
        Idle.watch();
        $rootScope.$on('IdleStart', function() {
            toastr.warning(
                '<button class="btn btn-default">No cerrar sesión</button>',
                'Debido a la inactividad la sesión se cerrará en breves instantes', {
                    allowHtml: true,
                    timeOut: 30000,
                    progressBar: true
                }
            );
        });

        $rootScope.$on('IdleTimeout', function() {

            $http.post('/logout')
            .then(function success(response) {
                window.location.href = '/sesion_expirada';
            });
            
        });
    }
}]);


// contaApp.config(function(toastrConfig) {
//   angular.extend(toastrConfig, {
//     autoDismiss: false,
//     containerId: 'toast-container',
//     maxOpened: 0,    
//     newestOnTop: true,
//     positionClass: 'toast-top-center',
//     preventDuplicates: false,
//     preventOpenDuplicates: false,
//     target: '#page-content-wrapper'
//   });
// });

contaApp.directive('contenteditable-normal', function() {
    return {
        require: 'ngModel',
        link: function(scope, elm, attrs, ctrl) {
            // view -> model
            elm.bind('blur', function() {
                scope.$apply(function() {
                    ctrl.$setViewValue(elm.html());
                });
            });

            // model -> view
            ctrl.render = function(value) {
                elm.html(value);
            };

            elm.bind('keydown', function(event) {
                var esc = event.which == 27,
                    el = event.target;

                if (esc) {
                    ctrl.$setViewValue(elm.html());
                    el.blur();
                    event.preventDefault();
                }
            });

        }
    };
});

contaApp.directive('contenteditable', function() {
    return {
        require: 'ngModel',
        link: function(scope, elm, attrs, ctrl) {
            // view -> model
            elm.bind('blur', function() {
                scope.$apply(function() {
                    //Cuando el usuario termina de editar cambiamos el separador decimal, los espacios y el símbolo de moneda
                    ctrl.$setViewValue(elm.html().toString().trim().replace(".","").replace(",",".").replace("&nbsp;","").replace("€","").replace("%",""));
                });
            });

            // model -> view
            ctrl.render = function(value) {
                elm.html(value);
            };

            elm.bind('keydown', function(event) {
                var esc = event.which == 27,
                    el = event.target;

                //impedimios introducir puntos en los campos editables 
                if ( event.which == 190 ) {
                    return false;
                }

                if (esc) {
                    //Cuando el usuario termina de editar cambiamos el separador decimal, los espacios y el símbolo de moneda
                    ctrl.$setViewValue(elm.html().toString().trim().replace(".","").replace(",",".").replace("&nbsp;","").replace("€","").replace("%",""));
                    el.blur();
                    event.preventDefault();
                }
            });
        }
    };
});

contaApp.directive('touppercase',['$parse',function($parse) {
   return {
     require: 'ngModel',
     link: function(scope, element, attrs, modelCtrl) {
        var text2uppercase = function(inputValue) {
           if (inputValue === undefined) { inputValue = ''; }
           var text2uppercased = inputValue.toUpperCase();
           if(text2uppercased !== inputValue) {
              modelCtrl.$setViewValue(text2uppercased);
              modelCtrl.$render();
            }         
            return text2uppercased;
         };
         modelCtrl.$parsers.push(text2uppercase);
         text2uppercase($parse(attrs.ngModel)(scope));
     }
   };
}]);

contaApp.filter('myStrictFilter', ['$filter',function($filter){
    return function(input, predicate){
        return $filter('filter')(input, predicate, true);
    }
}]);

contaApp.filter('bool2icon', function() {
    return function(input) {
        return input ? '<i class="fa fa-check" aria-hidden="true"></i>' : '';
    }
});

contaApp.filter('null2zero', function() {
    return function(input) {
        return input == null ? 0 : input;
    }
});

contaApp.filter('dateFromCarbon', function() {
    return function(input) {
        return moment(input, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY');
    }
});

contaApp.filter('unique', function() {
    return function (arr, field) {
        var o = {}, i, l = arr.length, r = [];
        for(i=0; i<l;i+=1) {
            o[arr[i][field]] = arr[i];
        }
        for(i in o) {
            r.push(o[i]);
        }
        return r;
    };
  });
//Inicilizar tooltips
$(function () {
  $('[data-toggle="tooltip"]').tooltip();
});

//FUNCIONES AUXILIARES
function number_format_es_ES(num) {
    if (num != null) {
        num = parseFloat(Math.round(num * 100) / 100).toFixed(2).toString().replace(".",",");
        //Poner . de miles -> https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
        var parts = num.toString().split(",");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        return parts.join(",");
        return num;
    }
}
