contaApp.controller('EmployeesListController', ['$scope', '$http', '$filter', function($scope, $http, $filter, $visits) {

    //TABLA

    $scope.predicates = ['name', 'dni', 'ss_number', 'job', 'contract_type', 'hours', 'registerDate'];
    $scope.selectedPredicate = $scope.predicates[0];

    $scope.getters={
        registerDate: function (value) {
            return new Date(moment(value.registerDate, 'D-M-YYYY').toISOString());
        }
    };

    // FIN TABLA

    $scope.exportList2xlsx = function ($event) {
       $event.stopImmediatePropagation();
       $scope.$parent.exportList2xlsx($scope.empresaActiva.empleados);
       
    }

}]);