/**
 * Created by agf on 20/12/2016.
 */
contaApp.directive('editInPlace', function () {
    return {
        restrict: 'E',
        scope: {value: '=', callbackFn: '&'},
        template: '<span ng-click="edit($event)" ng-bind="value"></span><input ng-model="value">',
        link: function ($scope, element, attrs) {
            // Let's get a reference to the input element, as we'll want to reference it.
            var inputElement = angular.element(element.children()[1]);

            // This directive should have a set class so we can style it.
            element.addClass('edit-in-place');

            // Initially, we're not editing.
            $scope.editing = false;

            // ng-click handler to activate edit-in-place
            $scope.edit = function ($event) {
                $event.stopImmediatePropagation();
                $scope.editing = true;

                // We control display through a class on the directive itself. See the CSS.
                element.addClass('active');

                // And we must focus the element.
                // `angular.element()` provides a chainable array, like jQuery so to access a native DOM function,
                // we have to reference the first element in the array.
                inputElement[0].focus();
            };

            // When we leave the input, we're done editing.
            inputElement.prop('blur', function () {
                $scope.editing = false;
                element.removeClass('active');
            });
            element.bind('keydown', function (event) {
                var ent = event.which == 13 || event.which == 9;
                var esc = event.which == 27;
                if (ent) {
                    element.removeClass('active');
                    $scope.editing = false;
                    $scope.callbackFn();
                }
                if (esc) {
                    element.removeClass('active');
                    $scope.editing = false;
                }
            });
        }
    };
});
