contaApp.controller('ModifyCompanyController', ['$scope', '$http', '$filter','Upload', '$timeout', function($scope, $http, $filter, Upload, $timeout) {

    $scope.empresa_form = $scope.empresaActiva;

    $scope.updateCompanyProfile = function ($event) {

        //SUBIR IMAGEN
        if ($scope.empresa_form.logofile != null) {
            $scope.empresa_form.logofile.upload
             = Upload.upload({
              url: '/subir-logo',
              data: {empresa_id: $scope.empresa_id, file: $scope.empresa_form.logofile},
            });

            $scope.empresa_form.logofile.upload.then(function (response) {
                $timeout(function () {
                    $scope.empresa_form.logofile.result = response.data;
                    $scope.empresa_form.logo = response.data.nombre;
                    $scope.actualizarEmpresaServer(); 
              });
            }, function (response) {
                if (response.status > 0) {$scope.showNotification('Error al subir la imagen del logo ' + response.message, 'error');}
            }, function (evt) {
              // Math.min is to fix IE which reports 200% sometimes
              $scope.empresa_form.logofile.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
            });
        } else {
            $scope.actualizarEmpresaServer();
        }
    }

    $scope.actualizarEmpresaServer = function () {
    var empresa_modi = {
            banco: $scope.empresa_form.banco,
            cif: $scope.empresa_form.cif,
            ciudad: $scope.empresa_form.ciudad,
            cp: $scope.empresa_form.cp,
            direccion: $scope.empresa_form.direccion,
            email: $scope.empresa_form.email,
            erp: $scope.empresa_form.erp,
            iban: $scope.empresa_form.iban,
            idrep: $scope.empresa_form.idrep,
            idfactura: $scope.empresa_form.idfactura,
            logo: $scope.empresa_form.logo,
            nombre: $scope.empresa_form.nombre,
            prefijo: $scope.empresa_form.prefijo,
            recargo_pordefecto: $scope.empresa_form.recargo_pordefecto,
            retencion_pordefecto: $scope.empresa_form.retencion_pordefecto,
            sufijo: $scope.empresa_form.sufijo,
            telefono: $scope.empresa_form.telefono,
        };


        //petición POST para actualizar los datos
        $http({
        method: 'POST',
        url: '/empresa/actualizar',
        data: {
            _token: $scope.csfrToken, 
            empresa_id: $scope.empresa_id,
            empresa_modi: empresa_modi,
            file: $scope.empresa_form.logofile
        },
        responseType: 'arraybuffer' 
        }).then(function success(response) {
                $scope.showNotification('Información de la empresa actualizada', 'success-top');
                $scope.empresaActiva = empresa_modi;
                $scope.empresa_form.logofile = null;
                $scope.$emit('actualizarPrefijoSufijo');
        }, function error(response) {
          $scope.showNotification('Error de aplicación. Los datos de la empresa no han sido actualizados ' + response.message, 'error');
        });        
    }

    //-----------------------------------
    //SOLO EN LOCAL - CAMBIAR A SERVIDOR
    //-----------------------------------
    $scope.newFormaPago = function ($event) {
        $scope.empresaActiva.formapagoperso.push($scope.empresas.newForma);
        $scope.clear_formapago_form();
    }

    $scope.clear_formapago_form = function () {
        $scope.empresas.newForma = {};
    }

    //borrar en local
    $scope.removeItem = function removeItem(row) {
        var index = $scope.empresaActiva.formapagoperso.indexOf(row);
        if (index !== -1) {
            $scope.empresaActiva.formapagoperso.splice(index, 1);
        }
    };

}]);
