/**
 * Created by agf on 20/12/2016.
 */
contaApp.controller('EmpresaListController', ['$rootScope', '$scope', '$http', '$filter', 'SwitchLocationService',
    function ($rootScope, $scope, $http, $filter, SwitchLocationService) {
        var $ctrl = this;
        var currentApp = $('#wrapper').data('app-id');
        $ctrl.itemsByPage = 10;
        $ctrl.batchUsers = [];
        $ctrl.formVisible = false;
        $ctrl.batch = false;
        $ctrl.showActions = {};
        $scope.vista_activa = null;
        $scope.editModeFlag = false;
        $scope.empresasList = [];
        $scope.disponible = function () {
            return 'disponible';
        };
        $rootScope.$on('vista_activa', function (e, a) {
            $scope.vista_activa = a.vista;
        });
        $scope.userChanged = function (row) {
            $ctrl.updateField(row);
        };
        $scope.currentUserObject = {};
        $ctrl.newUserObject = {
            id: 0,
            nombre: "",
            logo: "",
            cif: "",
            direccion: "",
            cp: "",
            ciudad: "",
            telefono: "",
            email: "",
            banco: "",
            iban: "",
            iderp: "266",
            erp: "1",
            carpeta: [{name: 'Sin Seleccion', type: 'directory', selected: true}],
            avilableFolders: []
        };

        function openFormDrawer() {
            $('#form-wrapper').attr('colspan', 9);
        }

        function closeFormDrawer() {
            $('#form-wrapper').attr('colspan', 1);
            angular.copy($scope.currentUserObject, $scope.newUserObject);
        }

        $ctrl.newUser = function ($event) {
            $event.stopImmediatePropagation();
            $ctrl.formVisible = !$ctrl.formVisible;
            $ctrl.batch = false;
            angular.copy($ctrl.newUserObject, $scope.currentUserObject);
            $scope.currentUserObject.avilableFolders= angular.copy($scope.adminFolders);
            openFormDrawer();
        };

        $ctrl.saveNewUser = function ($event) {
            $event.stopImmediatePropagation();
            var tmpAdminFld = [];
            $http.post('/admin/save/empresa', {payload: $scope.currentUserObject}).then(function (res) {
                console.log(res.data.message);
                    
                switch (res.data.message) {

                    case 'insert_cif_repetido':
                        $scope.showNotification('El DNI ya existe. Por favor, inténtelo con otro DNI', 'warning');
                        break;

                    case 'insert_no_cif':
                        $scope.showNotification('Es necesario que inserte un DNI', 'warning');
                        break;

                    default:
                        $scope.currentUserObject.id = res.data.empresa_modificada[0].id;
                        angular.copy($scope.adminFolders, $scope.currentUserObject.avilableFolders);
                        angular.copy($scope.adminFolders, tmpAdminFld);
                        angular.forEach($scope.currentUserObject.carpeta, function (o, i) {
                            angular.forEach($scope.currentUserObject.avilableFolders, function (obj, ii) {
                                if (o.name == obj.name) obj.selected = true;
                            });
                        });

                        $scope.companies = res.data.empresasList;
                        var tmpObj = angular.copy($scope.currentUserObject);
                        tmpObj.avilableFolders = tmpAdminFld;
                        if ($scope.editModeFlag) {
                            $scope.editModeFlag = false;
                        }
                        $ctrl.formVisible = !$ctrl.formVisible;
                        closeFormDrawer();
                }                
                
            }, function (err) {
                console.log(err);
            });

        };

        $ctrl.editMode = function ($event, row, $index) {
            $event.stopImmediatePropagation();
            $event.preventDefault();
            $scope.editModeFlag = true;
            if ($ctrl.formVisible == false) {
                $ctrl.formVisible = true;
                openFormDrawer();
            } else {
                $ctrl.formVisible = false;
                closeFormDrawer();
            }
            angular.copy(row, $scope.currentUserObject);
            $scope.currentUserObject.avilableFolders= angular.copy($scope.adminFolders);
            angular.forEach($scope.currentUserObject.avilableFolders, function (o, i) {
                if (o.display_name === $scope.currentUserObject.carpeta[0].display_name) {
                    o.selected = true;
                }
            });
            $scope.currentUserObject.rowIndex = $index;
        };
        $ctrl.importUsers = function ($event) {
            $event.stopImmediatePropagation();
            $ctrl.formVisible = !$ctrl.formVisible;
            $ctrl.batch = true;
        };
        $ctrl.cancelUpload = function ($event) {
            $event.stopImmediatePropagation();
            $ctrl.formVisible = !$ctrl.formVisible;
            $($event.target).closest('th').attr('colspan', 1);
        };
        $ctrl.uploadUsers = function ($event) {
            $event.stopImmediatePropagation();
            $ctrl.formVisible = !$ctrl.formVisible;
            // Check for the various File API support.
            if (window.File && window.FileReader && window.FileList && window.Blob) {
                // Great success! All the File APIs are supported.
            } else {
                alert('The File APIs are not fully supported in this browser.');
            }
            var file = $('#uploadXlsFile')[0].files[0];
            var reader = new FileReader();
            reader.onload = function (event) {
                var data = event.target.result;
                var workbook = XLSX.read(data, {type: 'binary'});
                $ctrl.batchUsers = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]);
                $http({
                    method: 'POST',
                    url: '/admin/upload/empresa',
                    data: {_token: window.Laravel.csfrToken, payload: $ctrl.batchUsers}
                }).then(function success(response) {
                    angular.forEach(response.data, function (o, i) {
                        o.role_id = {id: o.role_id}
                    });
                    console.log(response.data);
                    $scope.empresasList = response.data;
                }, function error(response) {
                    console.log(response.data)
                });
            };
            reader.readAsBinaryString(file);
        };
        $ctrl.downloadUsers = function ($event) {
            $event.stopImmediatePropagation();
            var exportUsers = [];
            angular.copy($scope.companies, exportUsers);
            angular.forEach(exportUsers, function (o, i) {
                delete o.$$hashKey;
                delete o.avilableFolders;
                delete o.created_at;
                delete o.erp;
                delete o.id;
                delete o.iderp;
                delete o.idfactura;
                delete o.logo;
                delete o.recargo_pordefecto;
                delete o.remember_token;
                delete o.retencion_pordefecto;
                delete o.updated_at;
                delete o.carpeta;
            });

            exportXlsxFile(exportUsers, 'ListadoClientes');
        };
        $ctrl.updateField = function (row) {
            var newRow = angular.copy(row);
            $http.post('/admin/save/empresa', {payload: newRow}).then(function (res) {
                row = res.data;
            });
        };
        $ctrl.selectedRow = function (row) {
            $ctrl.showActions == row.nombre ? $ctrl.showActions = {} : $ctrl.showActions = row.nombre;
        };
        $ctrl.deleteUser = function ($event, row) {
            $event.stopImmediatePropagation();
            $scope.empresasList = $filter('filter')($scope.empresasList, {id: '!' + row.id});
            $http.post('/admin/delete/empresa', {payload: row}).then(function (res) {
                console.log(res);
            });

        };
        $scope.stopPropagation = function ($event) {
            $event.stopImmediatePropagation();
        };
        $scope.showInvoiceAsUser = function ($event, companyId) {
            $event.stopImmediatePropagation();
            $event.preventDefault();
            SwitchLocationService.appFormulario(companyId);
        };
        $scope.showDocsAsUser = function ($event, companyId) {
            $event.stopImmediatePropagation();
            $event.preventDefault();
            SwitchLocationService.appDocumentos(companyId);
        };
        $scope.showRowDetails = function ($event, row) {
            $event.stopImmediatePropagation();
            $event.preventDefault();
            $ctrl.selectedRow(row);
        };
        $scope.openUserListTab = function () {
            $http.get(currentApp + '/1/user_list').then(function (res) {
                $scope.userList = res.data.userList;
            });
        };
        $scope.openAsesoresListTab = function () {
            $http.get(currentApp + '/asesores_list').then(function (res) {
                $scope.asesoresList = res.data.asesoresList;
            });
        };

        $scope.downloadUsuarios = function ($event) {
            $event.stopImmediatePropagation();
            var exportUsuarios = [];
            angular.copy($scope.userList, exportUsuarios);
            angular.forEach(exportUsuarios, function (o, i) {
                delete o.$$hashKey;
                delete o.active;
                delete o.cabecera_apps;
                delete o.created_at;
                delete o.empresas;
                delete o.id;
                delete o.mailed;
                delete o.modulos;
                delete o.murano_id;
                delete o.role_id;
                delete o.roles;
                delete o.updated_at;
                delete o.selectedEmpresas;
            });

            exportXlsxFile(exportUsuarios, 'ListadoUsuarios');
        };

        $scope.downloadAsesores = function ($event) {
            $event.stopImmediatePropagation();
            var exportAsesores = [];
            angular.copy($scope.asesoresList, exportAsesores);
            angular.forEach(exportAsesores, function (o, i) {
                delete o.$$hashKey;
                delete o.active;
                delete o.cabecera_apps;
                delete o.created_at;
                delete o.empresas;
                delete o.id;
                delete o.mailed;
                delete o.modulos;
                delete o.murano_id;
                delete o.role_id;
                delete o.roles;
                delete o.updated_at;
                delete o.selectedEmpresas;
            });

            exportXlsxFile(exportAsesores, 'ListadoAsesores');
        };
    }]);