/**
 * Created by agf on 21/12/2016.
 */
contaApp.directive('breadCrumbs', [function () {
    return {
        restrict: 'E',
        scope: {
            trace: '=',
            callback: '&'
        },
        replace: true,
        template: '<ol class="breadcrumb">' +
        '<li ng-repeat="crumb in trace" ng-class="{active: $last}">' +
        '<a href="#" ng-click="callback({parentEvent: $event})" data-trace="<%$index%>" ng-hide="$last"><%crumb.display_name%></a>' +
        '<span ng-hide="!$last"><%crumb.display_name%></span></li>' +
        '</ol>',
        link: function (scope, element, attr, ctrl) {
        }
    }
}]);