contaApp.controller('GeneratedInvoicesController', ['$scope', '$http', '$filter', function($scope, $http, $filter, $visits) {
    
  $scope.listaImprimible = [];

  //LOGICA PARA MOSTRAR Y GUARDAR CLIENTES

  $scope.$on('actualizarNumeroFacturaHijo', function(event) {
      $scope.newGenInv.idfactura = $scope.idFacturaEmitidaActual;
   });

  $scope.$on('actualizarIdLoteIssActualHijo', function(event) {
      $scope.newGenInv.idLoteIssActual = $scope.idLoteIssActual;
   });

  $scope.$on('actualizarPrefijoSufijoHijo', function(event) {
      $scope.newGenInv.prefijo = $scope.empresaActiva.prefijo;
      $scope.newGenInv.sufijo = $scope.empresaActiva.sufijo;
   });


  $scope.saveClientVisible = false;
  $scope.saveChangesVisible = false;

  $scope.saveClientShow = function() {
    if ($scope.newGenInv.cif != undefined) {
        if (!cifResgister()) {
          $scope.saveClientVisible = true;
          return true;
        } else {
          $scope.saveClientVisible = false;
          return  false;
        }
      }
  };

  $scope.saveChangesShow = function() {
    if ($scope.newGenInv.cif != undefined) {
      if (cifResgister() && clientIsChanged()) {
        $scope.saveChangesVisible = true;
        return true;
      } else {
        $scope.saveChangesVisible = false;
        return  false;
      }
    }
  };

  function clientIsChanged() {
    client = getClient($scope.newGenInv.cif);
    if (client[0] != undefined) {
      return ((client[0].nombre_cliente != $scope.newGenInv.name) || (client[0].email != $scope.newGenInv.email) || (client[0].direccion != $scope.newGenInv.direccion));
    } else {
      return false;
    }
  }

  function getClient(cif) {
    return $.map($scope.clienteList, function (item) {
      if (item.cif_cliente == cif) {
          return item;
      }
      return null;
    });
  }

  function updateClient() {
    return $.map($scope.clienteList, function (item) {
      if (item.cif_cliente == $scope.newGenInv.cif) {
          
          //local
          item.nombre_cliente = $scope.newGenInv.name;
          item.email = $scope.newGenInv.email;
          item.direccion = $scope.newGenInv.direccion;

          //servidor
          $scope.updateClientToServer({nombre_cliente: $scope.newGenInv.name.toUpperCase(), cif_cliente: $scope.newGenInv.cif, email: $scope.newGenInv.email, direccion: $scope.newGenInv.direccion });
          return true;
      }
      return false;
    });
  }

  function cifResgister() {
    var cifInList = false;
    for (i in $scope.clienteList) 
    {
      if ($scope.newGenInv.cif == $scope.clienteList[i].cif_cliente ) {
        cifInList =true;
      }
    }
    return cifInList;  
  } 

  //AUTOCOMPLETAR CLIENTES/DNI
  $('#cliente_proveedor').autocomplete({
      source: function (request, response) {
          var term = request.term;
          var data = clienteAutoComplete(term, $scope);
          response(data);
      },
      minLength: 1,
      focus: function (event, ui) {
          //$(this).val(ui.item.cif_cliente + ' - ' + ui.item.nombre_cliente);
          return false;
      },
      select: function (event, ui) {
          $(this).val(ui.item.nombre_cliente);
          $scope.newGenInv.cif = ui.item.cif_cliente;
          $scope.newGenInv.name = ui.item.nombre_cliente;
          $scope.newGenInv.email = ui.item.email;
          $scope.newGenInv.direccion = ui.item.direccion;
          $scope.$digest();
          return false;
      },
      messages: {
          noResults: '',
          results: function () {
          }
      }
  }).autocomplete('instance')._renderItem = function (ul, item) {
      return $("<li>")
          .append("<span class='ac-option'>" + item.cif_cliente + " - " + item.nombre_cliente + "</span>")
          .appendTo(ul);
  };

  function clienteAutoComplete(term, $scope) {
      return $.map($scope.clienteList, function (item) {
          if (item.nombre_cliente.match(term.toUpperCase()) || item.cif_cliente.match(term)) {
              return item;
          }
          return null;
      });
  };

  //CALCULOS LIVE DEL FORMULARIO      
  $scope.fecha_hoy = function() {
    $( "#dateinput-presupuesto" ).datepicker( "setDate", moment().format('DD/MM/YYYY') );
    return moment().format('DD/MM/YYYY');
  }

  $scope.calcular_iva = function() {

    var base = $scope.calcular_base();
    if ( ($scope.newGenInv.base != undefined) || (($scope.newGenInv.base_imponible0 != undefined) || ($scope.newGenInv.base_imponible1 != undefined) || ($scope.newGenInv.base_imponible2 != undefined) || ($scope.newGenInv.base_imponible3 != undefined)) ) {
            switch ($scope.newGenInv.type_iva.id) {
                case "0": return 0;
                case "1": var total = parseFloat(base * 0.04 || 0); break;
                case "2": var total = parseFloat(base * 0.1 || 0); break;
                case "3": var total = parseFloat(base * 0.21 || 0); break;
                case "4": 
                    if ($scope.newGenInv.base_imponible1 != undefined) { var base_imponible1 = $scope.newGenInv.base_imponible1.toString().trim().replace(",","."); }
                    if ($scope.newGenInv.base_imponible2 != undefined) { var base_imponible2 = $scope.newGenInv.base_imponible2.toString().trim().replace(",","."); }
                    if ($scope.newGenInv.base_imponible3 != undefined) { var base_imponible3 = $scope.newGenInv.base_imponible3.toString().trim().replace(",","."); }
                    var total = parseFloat(base_imponible1 * 0.04 || 0) + parseFloat(base_imponible2 * 0.1 || 0) + parseFloat(base_imponible3 * 0.21 || 0);
                    break;
            } 
          return Math.round(total * 100) / 100 || 0;
    } else {
      return '';
    }
  }
  
  $scope.calcular_total = function() {
      var base = $scope.calcular_base();
      var iva =  $scope.calcular_iva();
      var recargo = $scope.calcular_recargo();
      var retenciones = $scope.calcular_retenciones();
      var anteriortotal = $scope.newGenInv.total_invoice;

      if ( base > 0 ) {
          cero_o_null = "0";
      } else {
           cero_o_null = null;
      }

      var total = base + iva + recargo - retenciones;

      //Si el total es negativo se entiende que es un abono y se pone -ABONO en sufijo
      cadena_abono = "-ABONO"
      if (total < 0) {
        $scope.newGenInv.abono = cadena_abono;
      } else {
        $scope.newGenInv.abono = "";
      }

      if ( (total < 0) && (anteriortotal >= 0) ){
        $scope.newGenInv.sufijo = $scope.newGenInv.sufijo + $scope.newGenInv.abono;
      }  

      if ( (total > 0) && (anteriortotal < 0) ){
        if ($scope.newGenInv.sufijo.substring($scope.newGenInv.sufijo.length - cadena_abono.length, $scope.newGenInv.sufijo.length) == cadena_abono){     
          $scope.newGenInv.sufijo = $scope.newGenInv.sufijo.substring(0, $scope.newGenInv.sufijo.length - cadena_abono.length);
        }
      }

      $scope.newGenInv.total_invoice =  Math.round(total * 100) / 100 || cero_o_null;
      return Math.round(total * 100) / 100 || cero_o_null;
  }

  $scope.calcular_base = function() {
    if ( ($scope.newGenInv.base != undefined) || (($scope.newGenInv.base_imponible0 != undefined) || ($scope.newGenInv.base_imponible1 != undefined) || ($scope.newGenInv.base_imponible2 != undefined) ||($scope.newGenInv.base_imponible3 != undefined)) ) {
            if ($scope.newGenInv.base != undefined) {base = $scope.newGenInv.base.toString().trim().replace(",",".");}
            switch ($scope.newGenInv.type_iva.id) {
                case "0": var total = parseFloat(base || 0); break;
                case "1": var total = parseFloat(base || 0); break;
                case "2": var total = parseFloat(base || 0); break;
                case "3": var total = parseFloat(base || 0); break;
                case "4": 
                    if ($scope.newGenInv.base_imponible0 != undefined) { var base_imponible0 = $scope.newGenInv.base_imponible0.toString().trim().replace(",","."); }
                    if ($scope.newGenInv.base_imponible1 != undefined) { var base_imponible1 = $scope.newGenInv.base_imponible1.toString().trim().replace(",","."); }
                    if ($scope.newGenInv.base_imponible2 != undefined) { var base_imponible2 = $scope.newGenInv.base_imponible2.toString().trim().replace(",","."); }
                    if ($scope.newGenInv.base_imponible3 != undefined) { var base_imponible3 = $scope.newGenInv.base_imponible3.toString().trim().replace(",","."); }
                    var total = parseFloat(base_imponible0 || 0) + parseFloat(base_imponible1 || 0) + parseFloat(base_imponible2 || 0) + parseFloat(base_imponible3 || 0); break;
            } 
          return Math.round(total * 100) / 100 || null;
    } else {
      return '';
    }
  }


  $scope.calcular_recargo = function() {
    var base = $scope.calcular_base();
    if ( ($scope.newGenInv.base != undefined) || (($scope.newGenInv.base_imponible1 != undefined) || ($scope.newGenInv.base_imponible2 != undefined) ||($scope.newGenInv.base_imponible3 != undefined)) ) {
            switch ($scope.newGenInv.type_iva.id) {
                case "0": return 0;
                case "1": var total = parseFloat(base * 0.005 || 0); break;
                case "2": var total = parseFloat(base * 0.014 || 0); break;
                case "3": var total = parseFloat(base * 0.052 || 0); break;
                case "4": 
                    if ($scope.newGenInv.base_imponible1 != undefined) { var base_imponible1 = $scope.newGenInv.base_imponible1.toString().trim().replace(",","."); }
                    if ($scope.newGenInv.base_imponible2 != undefined) { var base_imponible2 = $scope.newGenInv.base_imponible2.toString().trim().replace(",","."); }
                    if ($scope.newGenInv.base_imponible3 != undefined) { var base_imponible3 = $scope.newGenInv.base_imponible3.toString().trim().replace(",","."); }
                    var total = parseFloat(base_imponible1 * 0.005 || 0) + parseFloat(base_imponible2 * 0.014 || 0) + parseFloat(base_imponible3 * 0.052 || 0); break;
            } 
          //$scope.newGenInv.aplicaRecargo multiplica para dejar a cero si no lo aplicamos
          return Math.round(total * $scope.newGenInv.aplicaRecargo * 100) / 100 || 0;
    } else {
      return '';
    }
  }

  $scope.calcular_retenciones = function() {
      var base = $scope.calcular_base();
      total_retenciones = base * parseFloat($scope.newGenInv.retencion.trim().replace(",",".") || 0) * 0.01 ;
      $scope.newGenInv.total_retenciones =  Math.round(total_retenciones * 100) / 100 || 0;
      return $scope.newGenInv.total_retenciones;
  }

  //LIMPIAR FORMULARIO
  $scope.clear_form = function () {

    //LIMPIAR ATRIBUTOS DE FORMULARIO
    $scope.generatedInvoiceForm.$setUntouched();
    $scope.generatedInvoiceForm.$setPristine();
    $scope.generatedInvoiceForm.$submitted = false;

    $( "#dateinput-generated" ).datepicker( "setDate", moment(Date.now()).format('DD/MM/YYYY') );
    $scope.newGenInv.date =  moment().format('DD/MM/YYYY');  

    $scope.newGenInv.control = '';
    $scope.newGenInv.concepto = '';
    $scope.newGenInv.idfactura = '';
    $scope.newGenInv.name = '';
    $scope.newGenInv.cif = '';
    $scope.newGenInv.email = '';
    $scope.newGenInv.direccion = '';
    $scope.newGenInv.observaciones = '';
    $scope.newGenInv.base = '';
    $scope.newGenInv.base_imponible0 = '';
    $scope.newGenInv.base_imponible1 = '';
    $scope.newGenInv.base_imponible2 = '';
    $scope.newGenInv.base_imponible3 = '';
    $scope.newGenInv.retenciones = '';
    $scope.newGenInv.type_invoice = $scope.data.typeInvoiceOptions[0];
    $scope.newGenInv.type_iva = $scope.data.ivaOptions[3];
    $scope.saveClientVisible = false;
    $scope.saveChangesVisible = false;
  }

  $scope.clear_lote = function () {
    $scope.xlsxvisible = false;
    $scope.$parent.genInv = [];
    $scope.$parent.idLoteIssActual++;
    $scope.$emit('actualizarIdLoteIssActual');
    $scope.lote_gen_cargado_sin_enviar = false;
  }

//SUBMIT 
  $scope.generatedInvoiceFormSubmit = function($event)  {
    $event.stopPropagation();
     
    if ($scope.generatedInvoiceForm.$invalid) {
        $scope.generatedInvoiceForm.$setSubmitted();
        $scope.showAlert('El formulario contiene datos incorrectos', 'error');
    } else {
      $scope.anadirFacturaEmitida();
    }
  }
    
//AGREGRAR UNA FACTURAS AL LOTE DE FACTURAS EN SERVIDOR-LOCAL(TABLA)
  $scope.anadirFacturaEmitida = function () {
    
    //guardar cliente proveedor si es nuevo
    if ( ($scope.newGenInv.save_client) && ($scope.saveClientVisible) ){


      if ($scope.newGenInv.name == undefined) $scope.newGenInv.name = '';

      //Insertamos cliente en local
      $scope.clienteList.push({nombre_cliente: $scope.newGenInv.name.toUpperCase(), cif_cliente: $scope.newGenInv.cif, email: $scope.newGenInv.email, direccion: $scope.newGenInv.direccion });

      //Insertamos cliente en servidor
      $scope.insertClientToServer({nombre_cliente: $scope.newGenInv.name.toUpperCase(), cif_cliente: $scope.newGenInv.cif, email: $scope.newGenInv.email, direccion: $scope.newGenInv.direccion });
    }

    //Actualizamos cliente si ha cambiado datos (mismo NIF)
    if ( ($scope.newGenInv.save_changes) && ($scope.saveChangesVisible) )  {
      updateClient();
    }
     //si han introducido coma ',' para los decimales
    $scope.newGenInv.base = $scope.calcular_base();
    if ($scope.newGenInv.base_imponible0 != undefined) { $scope.newGenInv.base_imponible0 = $scope.newGenInv.base_imponible0.toString().trim().replace(",","."); }
    if ($scope.newGenInv.base_imponible1 != undefined) { $scope.newGenInv.base_imponible1 = $scope.newGenInv.base_imponible1.toString().trim().replace(",","."); }
    if ($scope.newGenInv.base_imponible2 != undefined) { $scope.newGenInv.base_imponible2 = $scope.newGenInv.base_imponible2.toString().trim().replace(",","."); }
    if ($scope.newGenInv.base_imponible3 != undefined) { $scope.newGenInv.base_imponible3 = $scope.newGenInv.base_imponible3.toString().trim().replace(",","."); }

//---- GUARDAR LA FACTURA EN SERVIDOR (Y SI SALE BIEN EN LOCAL)
    //preparamos los datos

        //Para completar las bases imponibles en base a un IVA fijo o combinado
        switch ($scope.newGenInv.type_iva.id ) {
          case '0':
              $scope.newGenInv.base_imponible0 = $scope.newGenInv.base;
              $scope.newGenInv.base_imponible1 = $scope.newGenInv.base_imponible2 =  $scope.newGenInv.base_imponible3 = 0;

          break;
          case '1':
              $scope.newGenInv.base_imponible1 = $scope.newGenInv.base;
              $scope.newGenInv.base_imponible0 = $scope.newGenInv.base_imponible2 =  $scope.newGenInv.base_imponible3 = 0;

          break;
          case '2':
              $scope.newGenInv.base_imponible2 = $scope.newGenInv.base;
              $scope.newGenInv.base_imponible0 = $scope.newGenInv.base_imponible1 =  $scope.newGenInv.base_imponible3 = 0;

          break;
          case '3':
              $scope.newGenInv.base_imponible3 = $scope.newGenInv.base;
              $scope.newGenInv.base_imponible0 = $scope.newGenInv.base_imponible1 =  $scope.newGenInv.base_imponible2 = 0;

          break;
          case '4':
            $scope.newGenInv.base =
              parseFloat($scope.newGenInv.base_imponible0 || 0)
              + parseFloat($scope.newGenInv.base_imponible1 || 0)
              + parseFloat($scope.newGenInv.base_imponible2 || 0)
              + parseFloat($scope.newGenInv.base_imponible3 || 0);
          break;
        }

    //Preparamos los datos del formulario para crear el registro de inserción
    $scope.newGenInv.total_iva = $scope.calcular_iva();
    $scope.newGenInv.total_invoice = $scope.calcular_total();
    $scope.newGenInv.total_recargo = $scope.calcular_recargo();
    $scope.newGenInv.total_retenciones = $scope.calcular_retenciones();

    //"ÑAPA" PORQUE AL RELACIÓN JQUERY ANGULAR ME CAMBIA A VECES EL VALOR MOMENT A STRING
    if (typeof $scope.newGenInv.date == "string") {
      fecha_insert =  moment($scope.newGenInv.date, 'DD/MM/YYYY');
    } else {
      fecha_insert =  $scope.newGenInv.date;
    }

    register = {
      fecha: moment(fecha_insert).format('DD/MM/YYYY'),
      fecha_date: fecha_insert,
      prefijo: $scope.newGenInv.prefijo,
      idfactura: $scope.newGenInv.idfactura,
      sufijo: $scope.newGenInv.sufijo,
      control: $scope.newGenInv.control,
      concepto: $scope.newGenInv.concepto,
      cliente_nombre: $scope.newGenInv.name,
      cliente_cif: $scope.newGenInv.cif,
      cliente_email: $scope.newGenInv.email,
      cliente_direccion: $scope.newGenInv.direccion,
      tipo_factura: $scope.newGenInv.type_invoice.name,
      forma_pago: $scope.newGenInv.forma_pago.name,
      forma_pago_select: $scope.newGenInv.forma_pago,
      plazo_pago: $scope.newGenInv.forma_pago.plazo,
      base_imponible: $scope.newGenInv.base,
      base_imponible0: $scope.newGenInv.base_imponible0 || 0,
      base_imponible1: $scope.newGenInv.base_imponible1 || 0,
      base_imponible2: $scope.newGenInv.base_imponible2 || 0,
      base_imponible3: $scope.newGenInv.base_imponible3 || 0,
      total_iva: $scope.newGenInv.total_iva,
      total_recargo: $scope.newGenInv.total_recargo,
      retenciones_factor: $scope.newGenInv.retencion.trim().replace(",","."),
      total_retenciones: $scope.newGenInv.total_retenciones,
      total_factura: $scope.newGenInv.total_invoice,
      observaciones: $scope.newGenInv.observaciones,
    }
    console.log(register);

    $scope.insertFacturaEmitidaToServer($scope.idLoteIssActual, register, register, $scope.conceptInv);
  };

//FUCIONES DE TABLA

  $scope.predicates = ['fecha', 'cliente_proveedor', 'tipo_factura', 'base_imponible', 'total_iva', 'total_factura', 'cuenta_cargo', 'cuenta_cargo'];
  $scope.selectedPredicate = $scope.predicates[0];

  $scope.getters={
    fecha: function (value) {
        return new Date(moment(value.fecha, 'D-M-YYYY').toISOString());
    }
  }
  
  //remove to the real data holder
  $scope.removeItem = function removeItem(row) {
      var index = $scope.$parent.genInv.indexOf(row);
      if (index !== -1) {
        //BORRAR EN SERVIDOR
        $scope.deleteFacturaToServer($scope.idLoteIssActual, row.id_factura_insertada, index);
      }
      //FALTA BORRAR DEL SERVIDOR
  };

  $scope.editItem = function editItem(row) {
    var index = $scope.$parent.genInv.indexOf(row);
    if (index !== -1) {
        $scope.newGenInv = row;
        $scope.$parent.genInv.splice(index, 1);
        window.scrollTo(0, 0);
    }
  };

// ACCIONES POR LOTE
  $scope.sendFTP = function ($event) {
     $event.stopImmediatePropagation();
     ejercicio = moment(Date.now()).format('MM YYYY');

      $http({
          method: 'POST',
          url: '/envios/xlsx-to-ftp',
          data: {_token: $scope.csfrToken, lista_xlsx: $scope.listaImprimible, empresa: $scope.empresaActiva.nombre, cif: $scope.empresaActiva.cif, iderp: $scope.empresaActiva.iderp, ejercicio: ejercicio},
          responseType: 'arraybuffer' 
      }).then(function success(response) {
          $scope.updateLoteStatusToServer($scope.idLoteIssActual, "ftp");

      }, function error(response) {
              $scope.showNotification('Petición fallida: ' + response.message, 'error');
      });
  };

  $scope.sendEmail = function ($event) {
     $event.stopImmediatePropagation();

    $http({
      method: 'POST',
      url: '/email/lote-emitidas-to-asesor',
      data: {
        _token: $scope.csfrToken,
        empresa_id: $scope.empresa_id,
        idlote: $scope.idLoteIssActual,
      },   
      responseType: 'arraybuffer' 
    }).then(function success(response) {
      $scope.updateLoteStatusToServer($scope.idLoteIssActual, "mail");
    }, function error(response) {
        $scope.showNotification('No se ha podido enviar el email. Por favor, vuelva a intentarlo pasados unos instantes', 'error');
    });
  };

  //generar tabla de lote de facturas en xlsx
  $scope.exportList2xlsx = function ($event) {
     $event.stopImmediatePropagation();
     var genInvExcel = [];
     for (var i=0; i<$scope.$parent.genInv.length; i++) { 
        factura = $scope.$parent.genInv[i];
        genInvExcel.push($scope.fromFormatoStandardEmitidasSPToERP(factura, 'despachos'));
     } 
     $scope.$parent.exportList2xlsx(genInvExcel);
     $scope.showNotification('Descarga de fichero .xlsx correcta', 'success');
  };

//En función del ERP generar un excel a partir de un objeto con el formato necesario
  $scope.fromFormatoStandardEmitidasSPToERP = function(factura, formato_erp) {
    if (factura.total_recargo > 0) {
      var aplicaRecargo = 1;
    } else {
      var aplicaRecargo = 0;
    }

    factor_retenciones = factura.total_retenciones/factura.base_imponible*100;

    switch (formato_erp) {
      case 'despachos':
        var factura_formateada = {
          
          Lote: factura.idlote,
          Factura: factura.prefijo + factura.idfactura + factura.sufijo ,
          Fecha: factura.fecha,
          
          'CIF/DNI': factura.proveedor_cif,
          'Proveedor': factura.proveedor_nombre,
          'Concepto': factura.concepto,          
          'Control': factura.control,                    
          '%Retención': number_format_es_ES(factura.retenciones_factor),

          'Base Imponible': number_format_es_ES(factura.base_imponible),
          'Total IVA': number_format_es_ES(factura.total_iva),
          'Total Recargo': number_format_es_ES(factura.total_recargo),
          'Cuota Retención': number_format_es_ES(factura.total_retenciones),          
          'Importe Factura': number_format_es_ES(factura.total_factura),

          'Base Imponible0': number_format_es_ES(factura.base_imponible0),

          'Base Imponible1': number_format_es_ES(factura.base_imponible1),
          'Iva1': '4',
          'Cuota Iva1': number_format_es_ES(factura.base_imponible1 * 0.04),
          '%RecEq1': '0,005',
          'Cuota Rec1': number_format_es_ES(factura.base_imponible1 * 0.005  * aplicaRecargo),

          'Base Imponible2': number_format_es_ES(factura.base_imponible2),
          'Iva2': '10',
          'Cuota Iva2': number_format_es_ES(factura.base_imponible2 * 0.1),
          'RecEq2': '0,014',
          'Cuota Rec2': number_format_es_ES(factura.base_imponible2 * 0.014  * aplicaRecargo),

          'Base Imponible3': number_format_es_ES(factura.base_imponible3),
          'Iva3': '21',
          'Cuota Iva3': number_format_es_ES(factura.base_imponible3 * 0.21),
          'RecEq3': '0,052',
          'Cuota Rec3': number_format_es_ES(factura.base_imponible3 * 0.052  * aplicaRecargo),

          'Tipo Factura': factura.tipo_factura,
          'Forma de pago': factura.forma_pago,
          'Plazo de pago': factura.plazo_pago,
          'Observaciones': factura.observaciones,
        }
        break;
    }
    return factura_formateada;
  }


  //generar tabla de lote de facturas en pdf
  $scope.pdfGeneratedInvoices = function ($event) {
    $event.stopPropagation();
          $http({
          method: 'POST',
          url: '/pdf/tabla-facturas',
          data: {_token: $scope.csfrToken, facturas: $scope.$parent.genInv, empresa: $scope.empresaActiva.nombre, lote: $scope.lote},
          responseType: 'arraybuffer' 
      }).then(function success(response) {
           var file = new Blob([response.data], {type: 'application/pdf'});
           saveAs(file, $scope.empresaActiva.nombre + '_' + moment(Date.now()).format('YYYY-MM-DD_HH-mm-ss') + '.pdf');

      }, function error(response) {
              $scope.showNotification('Petición fallida: ' + response.message, 'error');
              //console.log( 'La petición no se ha producido: ' + response.message); //lshdebug
      });
  };


//Funcion para ACTUALIZAR STATUS DEL LOTE (enviado por FTP o por EMAIL)
 $scope.updateLoteStatusToServer = function(idlote, status) {
  $http({
      method: 'POST',
      url: '/empresa/actualizar-status-lote-emitidas',
      data: {
          _token: $scope.csfrToken,
          empresa_id: $scope.empresa_id,
          fecha_inicial: $scope.fecha_inicial_recibidas,
          fecha_final:  $scope.fecha_final_recibidas,          
          idlote: idlote,
          status: status,
      }
  }).then(function success(response) {
       //si se actualiza correctamente en servidor, actualizar en local lote y facturas
       enrango_historial = false;
       var facturas = response.data.facturas_lote_actualizado;

      for(var i = 0; i < facturas.length; i++) {
        facturas[i].idlote = response.data.lote_actualizado.idlote;
        fecha_factura = moment(facturas[i].fecha, "DD/MM/YYYY");
        if (fecha_factura.isSameOrAfter(moment($scope.fecha_inicial_emitidas, "DD/MM/YYYY")) && fecha_factura.isSameOrBefore(moment($scope.fecha_final_emitidas, "DD/MM/YYYY"))) { //insertamos factura para mostrar si esta en el rango de fechas del historial
          $scope.facturas_emitidas.push(facturas[i]);
          enrango_historial = true;
        }
        consolidar_factura_historial(facturas[i], $scope.trimestralEmitidas);
      }

      if (enrango_historial) { //insertamos lote para mostrar si tiene facturas en el rango de fechas del historial
        $scope.lotes_facturas_emitidas.push(response.data.lote_actualizado);
      }

      $scope.showNotification('Lote ' + idlote + ' enviado correctamente a la asesoria', 'success-top');
      $scope.$parent.lote_gen_sin_enviar = 0;

      $scope.clear_lote();
  }, function error(response) {
      $scope.showNotification('Se ha producido un error. Por favor, vuelva a intentarlo pasados unos minutos ' + response.message, 'error');
  }); 
 }

//Función para ELIMIMAR FACTURA EN EL SERVIDOR   
  $scope.deleteFacturaToServer = function(idlote, id_factura_insertada, index) {

      $http({
          method: 'POST',
          url: '/empresa/eliminar-factura-emitida',
          data: {
            _token: $scope.csfrToken,
            empresa_id: $scope.empresa_id,
            idlote: idlote,
            id_factura_insertada: id_factura_insertada,
          }
      }).then(function success(response) {
        $scope.genInv.splice(index, 1);
        $scope.listaImprimible.splice(index, 1);
      }, function error(response) {
          $scope.showNotification('No se ha podido eliminar la factura', 'error');
      });  

  }

//Función para INSERTAR FACTURA EN EL SERVIDOR   
  $scope.insertFacturaEmitidaToServer = function(idlote, factura_nueva, factura_nueva_imprimible_0, operaciones) {

      $http({
          method: 'POST',
          url: '/empresa/insertar-factura-emitida',
          data: {
              _token: $scope.csfrToken,
              empresa_id: $scope.empresa_id,
              idlote: idlote,
              factura_nueva: factura_nueva,
              operaciones: operaciones,
          }
      }).then(function success(response) {    

      //---- GUARDAR LA FACTURA EN LOCAL
        //Para vosualizar en tabla        
        factura_nueva['id_factura_insertada'] = response.data.id_factura_insertada;
        $scope.$parent.genInv.push(factura_nueva);

        //Para Imprimir - Excel - Cambia formato según dónde lo queramos exportar (REVISAR SEPARAR IMPRIMIR DE FORMATO ERP)
        $scope.listaImprimible.push(factura_nueva_imprimible_0);

        //SI TODO OK
        $scope.xlsxvisible = true;
        $scope.clear_form();
        //actualizamos globlamente el número de factura actual
        $scope.$parent.idFacturaEmitidaActual++;
        $scope.$emit('actualizarNumeroFactura');

          $scope.showNotification('La factura ' + factura_nueva.idfactura + ' se ha guardado en el servidor', 'success');
      }, function error(response) {
          $scope.showNotification('No se han podido guardar la factura ' + factura_nueva.idfactura, 'error');
      });        
  }

}]);