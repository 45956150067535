
contaApp.controller('NoticeController', ['$scope', '$http', 'Upload', function($scope, $http, Upload) {

    $scope.exportList2xlsx = function ($event) {
       $event.stopImmediatePropagation();
       $scope.$parent.exportList2xlsx($scope.rowCollection);
       
    }

    $scope.updateProfileNotices = function ($event, file)  {
        $event.stopPropagation();
        //actualizamos la información
        $http({
            method: 'POST',
            url: 'client/update-notice',
            data: {_token: $scope.csfrToken, companyCode: $scope.selectedCompany, empreg: $scope.newEmpreg}
        }).then(function success(response) {
            if (response.data.outcome) {
                $scope.showNotification('Notificación enviada', 'success');
                $scope.xlsxvisible = true;
                $scope.$parent.notice = []; //lo inicializo temporalmente para no acumular
                register = {
                    sentReceived: 'Enviado',
                    sentDate: '16/12/2016',
                    sentBy: 'Usuario',
                    message: $scope.newNotice.message,
                    file: $scope.noticeFile.name,
                }
                console.log(register); //lshdebug
                $scope.$parent.notice.push(register);
                $scope.$parent.rowCollection.push(register);
                $scope.newNotice = {}; //borrado de datos de formulario
                $scope.noticeFile = null;
            } else {
                $scope.showNotification('No se han guardado los cambios. Se ha producido un error: ' + response.message, 'error');
                //console.log( 'Petición devuelve error: ' + response.message); //lshdebug
            }
        }, function error(response) {
                $scope.showNotification('No se han guardado los cambios. Se ha producido un error: ' + response.message, 'error');
                //console.log( 'La petición no se ha producido: ' + response.message); //lshdebug
        });
    };

    $scope.viewNotice = function (row) {
       $scope.mostrarVista('singleNoticeView');
       $scope.$parent.singleNotice = row;
       console.log($scope.$parent.singleNotice);
    };

}]);