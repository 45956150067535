/**
 * Created by agf on 02/03/2017.
 */

contaApp.directive('fileDirective', [function () {
    return {
        restrict: 'E',
        replace: true,
        template: '<div><a href="#"><i class="fa fa-btn fa-2x <%fileClass()%>" ng-bind=""></i><%file.display_name%></a></div>',
        scope: {
            file: '='
        },
        link: function (scope, element, attr, ctrl) {
            scope.fileClass = function () {
                if ((scope.file.display_name !=  null) && (scope.file.display_name !=  '')) {
                    switch (scope.file.type) {
                        case 'file':
                            var fileType = scope.file.display_name.split('.');
                            switch (fileType[fileType.length - 1]) {
                                case 'pdf':
                                    return 'fa-file-pdf-o';
                                    break;
                                case 'doc':
                                    return 'fa-file-word-o';
                                    break;
                                case 'docx':
                                    return 'fa-file-word-o';
                                    break;
                                case 'xls':
                                    return 'fa-file-excel-o';
                                    break;
                                case 'xlsx':
                                    return 'fa-file-excel-o';
                                    break;
                                case 'jpg':
                                    return 'fa-file-image-o';
                                    break;
                                case 'png':
                                    return 'fa-file-image-o';
                                    break;
                                case 'tif':
                                    return 'fa-file-image-o';
                                    break;
                                case 'ppt':
                                    return 'fa-file-powerpoint-o';
                                    break;
                                case 'pptx':
                                    return 'fa-file-powerpoint-o';
                                    break;
                                default:
                                    return 'fa-file';
                                    break;
                            }
                            break;
                        case 'directory':
                            return 'fa-folder-open';
                            break;
                        default:
                            return 'fire';
                    }
                }
            };
        }
    }
}]);