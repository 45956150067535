/**
 * Created by agf on 25/03/2017.
 */
contaApp.service('SwitchLocationService', ['$http', function ($http) {

    this.appFormulario = function(empresa_id) {
        return post('/app-formulario', {empresa_id: empresa_id, vista: "issueInvoiceView",  _token: getCsrfToken() });
    };
    this.perfilEmpresa = function(empresa_id) {
        return post('/app-formulario', {empresa_id: empresa_id, vista: "modifyCompanyView", _token: getCsrfToken() });
    };
    this.appDocumentos = function(empresa_id) {
        return post('/app-documentos', {empresa_id: empresa_id, _token: getCsrfToken() });
    };
}]);

function post(path, params, method) {
    method = method || "post"; // Set method to post by default if not specified.

    // The rest of this code assumes you are not using a library.
    // It can be made less wordy if you use one.
    var form = document.createElement("form");
    form.setAttribute("method", method);
    form.setAttribute("action", path);

    for(var key in params) {
        if(params.hasOwnProperty(key)) {
            var hiddenField = document.createElement("input");
            hiddenField.setAttribute("type", "hidden");
            hiddenField.setAttribute("name", key);
            hiddenField.setAttribute("value", params[key]);

            form.appendChild(hiddenField);
        }
    }

    document.body.appendChild(form);
    form.submit();
}

function getCsrfToken() {
    var metas = document.getElementsByTagName('meta');

    for (var i=0; i<metas.length; i++) {
        if (metas[i].getAttribute("name") == "csrf-token") {
            return metas[i].getAttribute("content");
        }
    }
    return "";
}