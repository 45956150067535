contaApp.controller('SingleRecordGeneratedInvoicesController', ['$scope', '$http', '$filter', function($scope, $http, $filter, $visits) {


    $scope.viewRecordList = function () {
        $scope.mostrarVista('recordGeneratedInvoicesView');
    };

    
  $scope.pdfIssueInvoiceSingle = function (row) {
          $http({
          method: 'POST',
          url: '/pdf/imprimir-factura-historial',
          data: {
            _token: $scope.csfrToken,
            empresa_id: $scope.empresa_id,
            factura_emitida_id: row.id,
          },
          responseType: 'arraybuffer' 
      }).then(function success(response) {
           var file = new Blob([response.data], {type: 'application/pdf'});
           saveAs(file, 'factura_' + $scope.empresaActiva.nombre + '_' + moment(Date.now()).format('YYYY-MM-DD_HH-mm-ss') + '.pdf');

      }, function error(response) {
              $scope.showNotification('Petición fallida: ' + response.message, 'error');
              //console.log( 'La petición no se ha producido: ' + response.message); //lshdebug
      });
  };


    $scope.sendFTP = function ($event) {
       $event.stopImmediatePropagation();
       $scope.showNotification('Fichero xlsx enviado por FTP (80.38.217.58)', 'success');
    };

    $scope.sendEmail = function ($event) {
       $event.stopImmediatePropagation();
       $scope.showNotification('Fichero xlsx enviado a facturas@lsh-asesor.es', 'success');
    };

  //generar tabla de lote de facturas en xlsx
  $scope.exportList2xlsx = function ($event) {
     $event.stopImmediatePropagation();
     var listadoExcel = [];
     for (var i=0; i<$scope.facturas_emitidas_recibidas_porlote.length; i++) { 
        factura = $scope.facturas_emitidas_recibidas_porlote[i];
        listadoExcel.push($scope.fromFormatoStandardRecibidasToERP(factura, 'despachos'));
     } 
     $scope.$parent.exportList2xlsx(listadoExcel);
     $scope.showNotification('Descarga de fichero .xlsx correcta', 'success');
  };

//En función del ERP generar un excel a partir de un objeto con el formato necesario
  $scope.fromFormatoStandardRecibidasToERP = function(factura, formato_erp) {
    if (factura.total_recargo > 0) {
      var aplicaRecargo = 1;
    } else {
      var aplicaRecargo = 0;
    }

    factor_retenciones = factura.total_retenciones/factura.base_imponible*100;


    switch (formato_erp) {
      case 'despachos':

      if ($scope.vista_srcgi == 'proveedor') {
        var factura_formateada = {

          Lote: factura.idlote,
          Factura: factura.idfactura,
          Fecha: factura.fecha,
          'CIF/DNI': factura.proveedor_cif,
          'Proveedor': factura.proveedor_nombre,
          'Concepto': factura.concepto,                    
          '%Retención': number_format_es_ES(factura.retenciones_factor),

          'Base Imponible': number_format_es_ES(factura.base_imponible),
          'Total IVA': number_format_es_ES(factura.total_iva),
          'Total Recargo': number_format_es_ES(factura.total_recargo),
          'Cuota Retención': number_format_es_ES(factura.total_retenciones),          
          'Importe Factura': number_format_es_ES(factura.total_factura),

          'Base Imponible0': number_format_es_ES(factura.base_imponible0),

          'Base Imponible1': number_format_es_ES(factura.base_imponible1),
          'Iva1': '4',
          'Cuota Iva1': number_format_es_ES(factura.base_imponible1 * 0.04),
          '%RecEq1': '0,005',
          'Cuota Rec1': number_format_es_ES(factura.base_imponible1 * 0.005  * aplicaRecargo),

          'Base Imponible2': number_format_es_ES(factura.base_imponible2),
          'Iva2': '10',
          'Cuota Iva2': number_format_es_ES(factura.base_imponible2 * 0.1),
          'RecEq2': '0,014',
          'Cuota Rec2': number_format_es_ES(factura.base_imponible2 * 0.014  * aplicaRecargo),

          'Base Imponible3': number_format_es_ES(factura.base_imponible3),
          'Iva3': '21',
          'Cuota Iva3': number_format_es_ES(factura.base_imponible3 * 0.21),
          'RecEq3': '0,052',
          'Cuota Rec3': number_format_es_ES(factura.base_imponible3 * 0.052  * aplicaRecargo),

          'Tipo Factura': factura.tipo_factura,
          'Observaciones': factura.observaciones,
        }
      } else {
        var factura_formateada = {

          Lote: factura.idlote,          
          Factura: factura.prefijo + factura.idfactura + factura.sufijo,
          Fecha: factura.fecha,
          'CIF/DNI': factura.cliente_cif,
          'Cliente': factura.cliente_nombre,
          'Concepto': factura.concepto,                    
          '%Retención': number_format_es_ES(factura.retenciones_factor),

          'Base Imponible': number_format_es_ES(factura.base_imponible),
          'Total IVA': number_format_es_ES(factura.total_iva),
          'Total Recargo': number_format_es_ES(factura.total_recargo),
          'Cuota Retención': number_format_es_ES(factura.total_retenciones),          
          'Importe Factura': number_format_es_ES(factura.total_factura),

          'Base Imponible0': number_format_es_ES(factura.base_imponible0),

          'Base Imponible1': number_format_es_ES(factura.base_imponible1),
          'Iva1': '4',
          'Cuota Iva1': number_format_es_ES(factura.base_imponible1 * 0.04),
          '%RecEq1': '0,005',
          'Cuota Rec1': number_format_es_ES(factura.base_imponible1 * 0.005  * aplicaRecargo),

          'Base Imponible2': number_format_es_ES(factura.base_imponible2),
          'Iva2': '10',
          'Cuota Iva2': number_format_es_ES(factura.base_imponible2 * 0.1),
          'RecEq2': '0,014',
          'Cuota Rec2': number_format_es_ES(factura.base_imponible2 * 0.014  * aplicaRecargo),

          'Base Imponible3': number_format_es_ES(factura.base_imponible3),
          'Iva3': '21',
          'Cuota Iva3': number_format_es_ES(factura.base_imponible3 * 0.21),
          'RecEq3': '0,052',
          'Cuota Rec3': number_format_es_ES(factura.base_imponible3 * 0.052  * aplicaRecargo),

          'Tipo Factura': factura.tipo_factura,
          'Observaciones': factura.observaciones,         
        }        
      }
      break;
    }
    return factura_formateada;
  }


    $scope.exportList2pdf = function ($event) {
       $event.stopImmediatePropagation();
       $scope.$parent.exportList2xlsx($scope.facturas_emitidas_recibidas_porlote);
       $scope.showNotification('Descarga de fichero .pdf correcta', 'success');
    };

    //generar tabla de lote de facturas en pdf
    $scope.pdfGeneratedInvoices = function ($event) {
      $event.stopPropagation();
            $http({
            method: 'POST',
            url: '/pdf/tabla-facturas',
            data: {
              _token: $scope.csfrToken,
              empresa_id: $scope.empresa_id,
              facturas: $scope.facturas_emitidas_recibidas_porlote,
              lote: $scope.facturas_emitidas_recibidas_porlote[0].lote_facturas_emitidas_id,
              tipo: $scope.vista_srcgi,
            },            
            responseType: 'arraybuffer' 
        }).then(function success(response) {
             var file = new Blob([response.data], {type: 'application/pdf'});
             saveAs(file, $scope.empresaActiva.nombre + '_' + moment(Date.now()).format('YYYY-MM-DD_HH-mm-ss') + '.pdf');
             $scope.showNotification('Descarga de fichero .pdf correcta', 'success');
        }, function error(response) {
                $scope.showNotification('Petición fallida: ' + response.message, 'error');
        });
    };


    $scope.getters={
        fecha: function (value) {
            return new Date(moment(value.fecha, 'D-M-YYYY').toISOString());
        }
    }


 }]);
