
contaApp.controller('HistorialGastosController', ['$scope', '$http', '$filter', function($scope, $http, $filter, $visits) {

  //$scope.$parent.presupuestos = $scope.$parent.presupuestos.concat($scope.presupuestos_default);

  $scope.removeItemGasto = function removeItem(row) {

      $http({
          method: 'POST',
          url: '/empresa/eliminar-gasto',
          data: {
            _token: $scope.csfrToken,
            empresa_id: $scope.empresa_id,
            idlote: row.idlote,
            id_gasto: row.id,
          }
      }).then(function success(response) {
        var index = $scope.gastos_historial.indexOf(row);
        if (index !== -1) {
            $scope.gastos_historial.splice(index, 1);
        }

        $scope.showNotification('Gasto eliminado', 'success');

      }, function error(response) {
          $scope.showNotification('No se ha podido eliminar el gasto', 'error');
      });  
  };

  

  $scope.calcular_total_gasto_tabla = function() {
    var total = 0;
    for (i in $scope.gastos_historial) 
    {
      total += parseFloat($scope.gastos_historial[i].total_gasto || 0);
    }
    return total;
  };


//SUBMIT
  $scope.rangoFechasGastosFormSubmit = function($event)  {
    $event.stopPropagation();

    if ($scope.rangoGastos.inicio > $scope.rangoGastos.final ) {
      $scope.rangoFechasGastosForm.$setSubmitted();
      $scope.showAlert('La fecha de inicio debe ser anterior a la fecha de fin', 'error');
    } else {
      $scope.getGastosFromServer($scope.empresa_id,moment( $scope.rangoGastos.inicio).format('DD/MM/YYYY'), moment( $scope.rangoGastos.final).format('DD/MM/YYYY'));
      $scope.clear_form();
    }
  }

  $scope.clear_form = function () {
      //LIMPIAR ATRIBUTOS DE FORMULARIO
      $scope.rangoFechasGastosForm.$setUntouched();
      $scope.rangoFechasGastosForm.$setPristine();
      $scope.rangoFechasGastosForm.$submitted = false;
  }

  $scope.getters={
        fecha: function (value) {
            return new Date(moment(value.fecha, 'D-M-YYYY').toISOString());
        }
    }

 //generar tabla de lote de presupuestos en xlsx
  $scope.exportList2xlsx = function ($event) {
     $event.stopImmediatePropagation();
     var gastosExcel = [];
     for (var i=0; i<$scope.gastos_historial.length; i++) { 
        gasto = $scope.gastos_historial[i];
        gastosExcel.push($scope.fromFormatoStandardGastosToERP(gasto, 'despachos'));
     } 
     $scope.$parent.exportList2xlsx(gastosExcel);
     $scope.showNotification('Descarga de fichero .xlsx correcta', 'success');
  };

//En función del ERP generar un excel a partir de un objeto con el formato necesario
  $scope.fromFormatoStandardGastosToERP = function(gasto, formato_erp) {
    if (gasto.total_recargo > 0) {
      var aplicaRecargo = 1;
    } else {
      var aplicaRecargo = 0;
    }

    factor_retenciones = gasto.total_retenciones/gasto.base_imponible*100;

    switch (formato_erp) {
      case 'despachos':
        var gasto_formateado = {

          Lote: gasto.idlote,
          Gasto: gasto.idgasto,
          Fecha: gasto.fecha,
          
          'Cliente': gasto.proveedor_nombre,
          'CIF/DNI': gasto.proveedor_cif,
          'Concepto': gasto.concepto,          
          'Control': gasto.control,                    
          '%Retención': number_format_es_ES(gasto.retenciones_factor),

          'Base Imponible': number_format_es_ES(gasto.base_imponible),
          'Total IVA': number_format_es_ES(gasto.total_iva),
          'Total Recargo': number_format_es_ES(gasto.total_recargo),
          'Cuota Retención': number_format_es_ES(gasto.total_retenciones),          
          'Importe Factura': number_format_es_ES(gasto.total_gasto),

          'Base Imponible0': number_format_es_ES(gasto.base_imponible0),

          'Base Imponible1': number_format_es_ES(gasto.base_imponible1),
          'Iva1': '4',
          'Cuota Iva1': number_format_es_ES(gasto.base_imponible1 * 0.04),
          '%RecEq1': '0,005',
          'Cuota Rec1': number_format_es_ES(gasto.base_imponible1 * 0.005  * aplicaRecargo),

          'Base Imponible2': number_format_es_ES(gasto.base_imponible2),
          'Iva2': '10',
          'Cuota Iva2': number_format_es_ES(gasto.base_imponible2 * 0.1),
          'RecEq2': '0,014',
          'Cuota Rec2': number_format_es_ES(gasto.base_imponible2 * 0.014  * aplicaRecargo),

          'Base Imponible3': number_format_es_ES(gasto.base_imponible3),
          'Iva3': '21',
          'Cuota Iva3': number_format_es_ES(gasto.base_imponible3 * 0.21),
          'RecEq3': '0,052',
          'Cuota Rec3': number_format_es_ES(gasto.base_imponible3 * 0.052  * aplicaRecargo),

          'Observaciones': gasto.observaciones,

        }
        break;
    }
    return gasto_formateado;
  }


 }]);
