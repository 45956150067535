/**
 * Created by agf on 20/12/2016.
 */
contaApp.controller('ViewsPanelController', ['$scope', '$http', function ($scope, $http) {
    var $ctrl = this;
    $ctrl.selection = [];
    $scope.currentSelection = {
        actions: [],
        controllers: []
    };
    $scope.$on('#actionsPanel', function ($event, $scope) {
        $http.get('/get-routes?view=' + window.Laravel.viewId).then(function (res) {
            $scope.currentActions = res.data;
        });
    });
    $ctrl.emit = function ($event, name) {
        $event.stopImmediatePropagation();
        $scope.$broadcast($event.target.dataset.rolename);
        $ctrl.selection = name;
        $http.get('/admin/get/actions/' + $event.target.dataset.rolename).then(function (res) {
            angular.forEach(res.data, function (o, i) {
                return $scope.currentSelection.actions.push(o.action_id);
            });

        });
    };
    $ctrl.selectController = function ($event) {
        $event.stopImmediatePropagation();
        $scope.currentSelection.controllers[$event.target.dataset.controller] = !$scope.currentSelection.controllers[$event.target.dataset.controller];
        if ($scope.currentSelection.controllers[$event.target.dataset.controller]) {
            angular.forEach($scope.currentActions[$event.target.dataset.controller], function (o, i) {
                return $scope.currentSelection.actions.push(o.id);
            });
        } else {
            angular.forEach($scope.currentActions[$event.target.dataset.controller], function (o, i) {
                return $scope.currentSelection.actions.remove(o.id);
            });
        }
    };
    $scope.$watchCollection('currentSelection.actions', function (n, o) {
        if (n != o) {
            $scope.currentSelection.selection = $ctrl.selection;
            $http.post('/admin/update/permissions', {payload: $scope.currentSelection}).then(function (res) {
                console.log(res);
            })
        }

    });
}]);