//Para evitar que el datepicker se abra por defecto
$.datepicker.setDefaults({
  showOn: "",
});

$('#btn-datepicker').click(function() {
    $('#dateinput').datepicker();
    $('#dateinput').datepicker('show');
});

$('#btn-datepicker-generated').click(function() {
    $('#dateinput-generated').datepicker();
    $('#dateinput-generated').datepicker('show');
});

$('#btn-datepicker-receive').click(function() {
    $('#dateinput-receive').datepicker();
    $('#dateinput-receive').datepicker('show');
});

$('#btn-datepicker-gastos').click(function() {
    $('#dateinput-gastos').datepicker();
    $('#dateinput-gastos').datepicker('show');
});

$('#btn-datepicker-issue').click(function() {
    $('#dateinput-issue').datepicker();
    $('#dateinput-issue').datepicker('show');
});

$('#btn-datepicker-empreg').click(function() {
    $('#dateinput-empreg').datepicker();
    $('#dateinput-empreg').datepicker('show');
});

$('#btn-datepicker-unempreg').click(function() {
    $('#dateinput-unempreg').datepicker();
    $('#dateinput-unempreg').datepicker('show');
});

$('#btn-datepicker-presupuesto').click(function() {
    $('#dateinput-presupuesto').datepicker();
    $('#dateinput-presupuesto').datepicker('show');
});

$('#btn-datepicker-editissue').click(function() {
    $('#dateinput-editissue').datepicker();
    $('#dateinput-editissue').datepicker('show');
});

$('#btn-datepicker-rango-inicio').click(function() {
    $('#dateinput-rango-inicio').datepicker();
    $('#dateinput-rango-inicio').datepicker('show');
});

$('#btn-datepicker-rango-final').click(function() {
    $('#dateinput-rango-final').datepicker();
    $('#dateinput-rango-final').datepicker('show');
});

$('#btn-datepicker-rango-recibidas-inicio').click(function() {
    $('#dateinput-rango-recibidas-inicio').datepicker();
    $('#dateinput-rango-recibidas-inicio').datepicker('show');
});

$('#btn-datepicker-rango-recibidas-final').click(function() {
    $('#dateinput-rango-recibidas-final').datepicker();
    $('#dateinput-rango-recibidas-final').datepicker('show');
});

$('#btn-datepicker-rango-presupuestos-inicio').click(function() {
    $('#dateinput-rango-presupuestos-inicio').datepicker();
    $('#dateinput-rango-presupuestos-inicio').datepicker('show');
});

$('#btn-datepicker-rango-presupuestos-final').click(function() {
    $('#dateinput-rango-presupuestos-final').datepicker();
    $('#dateinput-rango-presupuestos-final').datepicker('show');
});

$('#btn-datepicker-rango-gastos-inicio').click(function() {
    $('#dateinput-rango-gastos-inicio').datepicker();
    $('#dateinput-rango-gastos-inicio').datepicker('show');
});

$('#btn-datepicker-rango-gastos-final').click(function() {
    $('#dateinput-rango-gastos-final').datepicker();
    $('#dateinput-rango-gastos-final').datepicker('show');
});