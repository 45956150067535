/**
 * Created by agf on 06/03/2017.
 */
contaApp.controller('LeftMenuController', ['$rootScope', '$scope', function ($rootScope, $scope) {

    //función para mostrar panel en vista
    $scope.mostrarVista = function (vista) {
        $scope.vista_activa = vista;
        $rootScope.$emit('vista_activa', {vista: $scope.vista_activa});
    };
//función para active en menu si la vista esta activa (o la de uno de sus hijos)
    $scope.claseEntradaMenu = function (vistas) {
        for (i in vistas) {
            if ($scope.vista_activa == vistas[i]) {
                return 'active';
            }
        }
        return '';
    };

//función para active en menu si la vista esta activa
    $scope.disponible = function () {
        return 'disponible';
    };

    $scope.appFormulario = function(empresa_id) {
        post('/app-formulario', {empresa_id: empresa_id, vista: "issueInvoiceView",  _token: getCsrfToken() });
    };
    
  //Para enlazaar con documentos
  $scope.appDocumentos = function(empresa_id) {
      post('/app-documentos', {empresa_id: empresa_id, _token: getCsrfToken() });
  };
}]);

function post(path, params, method) {
    method = method || "post"; // Set method to post by default if not specified.

    // The rest of this code assumes you are not using a library.
    // It can be made less wordy if you use one.
    var form = document.createElement("form");
    form.setAttribute("method", method);
    form.setAttribute("action", path);

    for(var key in params) {
        if(params.hasOwnProperty(key)) {
            var hiddenField = document.createElement("input");
            hiddenField.setAttribute("type", "hidden");
            hiddenField.setAttribute("name", key);
            hiddenField.setAttribute("value", params[key]);

            form.appendChild(hiddenField);
         }
    }

    document.body.appendChild(form);
    form.submit();
}

function getCsrfToken() { 
   var metas = document.getElementsByTagName('meta'); 

   for (var i=0; i<metas.length; i++) { 
      if (metas[i].getAttribute("name") == "csrf-token") { 
         return metas[i].getAttribute("content"); 
      } 
   } 
    return "";
} 

