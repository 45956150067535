contaApp.controller('adminController', ['$scope', '$http', '$filter', function($scope, $http, $filter, $visits) {
    var controller = $('#adminController');

    //empresa por defecto
    $scope.selectedCompany = "Auris"; 
    $scope.nombreEmpresa = "Auris S.L.";

    //ultima factura
    $scope.idUltimaFactura = "1495";
    $scope.idFacturaActual = parseInt($scope.idUltimaFactura) + 1;

    //próximo lote
    $scope.lote = "1124";
    $scope.loteAltas = "401";
    $scope.loteBajas = "104";

    $scope.calcular_idfactura = function () {
      return $scope.idFacturaActual;
    }

    //opciones de datepicker
    $scope.dateOptions = {
        changeYear: false,
        changeMonth: false,
    };
    

    $scope.empreg = [];
    $scope.empUnreg = [];
    $scope.genInv = [];
    $scope.genInvArr = [];
    $scope.recInv = [];
    $scope.issInv = [];
    $scope.employeesList = [];
    $scope.notices = [];
    $scope.singleNotice = [];

    $scope.xlsxvisible=false;

    $(function () {
        $('[data-toggle="tooltip"]').tooltip()
    })

    $scope.rowCollection = [
        {sentReceived: 'Enviado', sentDate: '12/11/2016', sentBy: 'Asesoria', readDate: '13/11/2016', readBy: 'Usuario', message: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.'},
        {sentReceived: 'Recibido', sentDate: '14/11/2016', sentBy: 'Emilio Díaz', readDate: '', readBy: '', message: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.'},

    ];

  //DATOS DUMMY (VENDRAN DE BASE DATOS)
  $scope.data = {
    typeInvoiceOptions: [
      {id: 'Operaciones interiores', name: 'Operaciones interiores'},
      {id: 'Adquisiciones Intracom (Bienes)', name: 'Adquisiciones Intracom (Bienes)'},
      {id: 'Importaciones', name: 'Importaciones'},
      {id: 'Operaciones interiores (Bien inversión)', name: 'Operaciones interiores (Bien inversión)'},
      {id: 'Certificaciones', name: 'Certificaciones'},
    ],
    ivaOptions: [
      {id: '0', name: 'Exenta de IVA'},
      {id: '1', name: 'IVA superreducido 4%'},
      {id: '2', name: 'IVA reducido 10%'},
      {id: '3', name: 'IVA general 21%'},
      {id: '4', name: 'Combinado'},
      {id: '5', name: 'IVA superreducido 5%'},
    ],
    accountOptions: [
      {id: '212152 337624 9478384859', name: '212152 337624 9478384859'},
      {id: '653456 217812 32436784567', name: '653456 217812 32436784567'},
    ],
    retencionOptions: [
      {id: 'Profesionales', name: 'Profesionales'},
      {id: 'Alquiler de local', name: 'Alquiler de local'},
      {id: 'No fiscales', name: 'No fiscales'},
      {id: 'Agricultores', name: 'Agricultores'},
      {id: 'Módulos', name: 'Módulos'},
      {id: 'Alquiler de negocio', name: 'Alquiler de negocio'},
    ]
  };

  $scope.clienteList = [
    {nombre_cliente: "SEGOVIA EDITORES", cif_cliente: "P1685472A", email: "pcs@jalon.es", direccion: "Calle Cromo 21, 2ºD, 28045, Madrid, España"},
    {nombre_cliente: "PEDRO LUIS ENCINA DELGADO", cif_cliente: "G11852686", email: "encidel@rokcket.net", direccion: "Calle Logos 16, 28035, Madrid, España"},
    {nombre_cliente: "LAVISA S.L.", cif_cliente: "F3941671D", email: "info@lavisa.org", direccion: "Calle Rouco 226, 11011, Cádiz, España"},
    {nombre_cliente: "PEDRO RUIZ PEREZ", cif_cliente: "R5602214H", email: "prr@telefonica.net", direccion: "Paseo Gloria 16, 28035, Madrid, España"},
    {nombre_cliente: "LUIS EMILIO CASTILLA GONZALEZ", cif_cliente: "F8971134D", email: "luisemi@confortmail.net", direccion: "Calle Logos 16, 18035, Granada, España"},
    {nombre_cliente: "MEMOREX S.L,", cif_cliente: "E26763474", email: "cuentas@memorex.com", direccion: "Calle Logos 16, 28035, Madrid, España"},
    {nombre_cliente: "CAROLINA MARIA ETXEBARRI", cif_cliente: "V7928273G", email: "caro@truckpower.com", direccion: "Calle Logos 16, 28035, Madrid, España"},
    {nombre_cliente: "RIZOS PELUQUEROS", cif_cliente: "B9069404T", email: "info@rizospelu.org", direccion: "Calle Logos 16, 28035, Madrid, España"},
    {nombre_cliente: "WELLINGTON SPORT", cif_cliente: "W5143847A", email: "adm@wellingtonsport.org", direccion: "Calle Logos 16, 28035, Madrid, España"},
  ];

    $scope.empresas = [];

    //DATOS PRUEBA
    $scope.empresas.push({ 
        nombre: 'Auris S.L.',
        logo: 'images/logo1.png',
        cif: 'U6036670E',
        direccion: 'Calle Alejandro Magno, 43',
        cp: '28045',
        ciudad: 'Madrid',
        telefono: '91 434 32 12',
        banco: 'Banco Sabadell',
        iban: 'ES50 6187 2193 8837 0012 6740',
        correo: 'facturas@serverasesoria.es',
        servidor: '80.38.217.58' ,
        ejercicios: [{anyo: '2016', prefijo: 'A', numero:'1400', sufijo: '2016'}, {anyo: '2017', prefijo: 'A', numero:'1500', sufijo: '2017'}],
        empleados: [
                {name: 'Emilio Gómez', dni: '23507257S', ss_number: '23/1234567/50', job: 'Secretario',  contract_type: 'Indefinido',  hours: '40', registerDate: '12/07/2014'},
                {name: 'Josefina Casas', dni: '93681389C', ss_number: '28/4321356/20', job: 'Administrativa',  contract_type: 'Parcial',  hours: '20', registerDate: '22/11/2015'},
                {name: 'Luis Pérez', dni: '07448651D', ss_number: '22/2488721/70', job: 'Soldador',  contract_type: 'Indefinido',  hours: '30', registerDate: '02/01/2011'},
        ]
    });
    $scope.empresas.push({ 
        nombre: 'FrigoCar',
        logo: 'images/logo2.png',
        cif: 'P3949131A',
        direccion: 'Calle Robledal, 23',
        cp: '28012',
        ciudad: 'Madrid',
        telefono: '91 875 21 00',
        banco: 'Bankia',
        iban: 'ES64 6363 6380 2603 3529 2768',
        correo: 'facturas@serverasesoria.es',
        servidor: '80.38.217.58' ,
        ejercicios: [{anyo: '2016', prefijo: 'F', numero:'1400', sufijo: '2016'}, {anyo: '2017', prefijo: 'F', numero:'1500', sufijo: '2017'}],
        empleados: [
                {name: 'Andrés García', dni: '10707430X', ss_number: '23/1234567/50', job: 'Secretario',  contract_type: 'Indefinido',  hours: '40', registerDate: '12/10/2014'},
                {name: 'Raquel Polo', dni: '35255769N', ss_number: '28/4321356/20', job: 'Administrativa',  contract_type: 'Parcial',  hours: '20', registerDate: '22/11/2015'},
                {name: 'Nuria Beltran', dni: '02040387B', ss_number: '22/2488721/70', job: 'Soldador',  contract_type: 'Indefinido',  hours: '30', registerDate: '02/01/2011'},
            ]
    });
    $scope.empresas.push({ 
        nombre: 'OBISA S.L.',
        logo: 'images/logo3.png',
        cif: 'V8148462H',
        direccion: 'Calle Julian Camarillo, 1',
        cp: '28006',
        ciudad: 'Madrid',
        telefono: '91 434 32 12',
        banco: 'Banco Santander',
        iban: 'ES18 2704 9015 2156 1146 0285',
        correo: 'facturas@serverasesoria.es',
        servidor: '80.38.217.58' ,
        ejercicios: [{anyo: '2016', prefijo: 'O', numero:'1400', sufijo: '2016'}, {anyo: '2017', prefijo: 'O', numero:'1500', sufijo: '2017'}],
        empleados: [
                {name: 'Elena Cañizo', dni: '92073312B', ss_number: '23/1234567/50', job: 'Secretario',  contract_type: 'Indefinido',  hours: '40', registerDate: '12/10/2014'},
                {name: 'Ángel Reyes', dni: '51307223L', ss_number: '28/4321356/20', job: 'Administrativa',  contract_type: 'Parcial',  hours: '20', registerDate: '22/11/2015'},
                {name: 'Manuel Pedroche', dni: '78719997J', ss_number: '22/2488721/70', job: 'Soldador',  contract_type: 'Indefinido',  hours: '30', registerDate: '02/01/2011'},
        ]
    });

    //por defecto
    $scope.ide = 0;
    $scope.empresaActiva = $scope.empresas[$scope.ide];


    //inicialización por defecto (Mejora de futuro -> sacar DOM del controlador)
    setViewVisible('#adminMainPanel', '#adminDashboardView');
    //$('#issueInvoiceLink').addClass('active');

    //función para mostrar panel en vista
    $scope.showView = function ($event) {
        setViewVisible('#adminMainPanel', $($event.target).data('view'));
        setActiveListItem($event.target);
    };

    //función para cargar datos de la empresa (mantiene el panel activo)
    $scope.loadCompany = function ($event) {
        setActiveListItem($event.target);
        company = $($event.target).data('company');
        $scope.empresaActiva = $scope.empresas[company];
        $scope.ide = company;
    };

    $scope.selectCompany = function ($event) {
        companyId = $($event.target).data('company');
        $scope.setActiveCompany(companyId);
        setViewVisible('#clientMainPanel', '#generatedInvoiceView');
        $('#generatedInvoicesLink').addClass('active');

        $scope.empresaActiva = $scope.empresas[companyId];
        $scope.ide = companyId;
    }

    $scope.setActiveCompany = function (companyId) {
        section = $("#companies-list");
        var i = 0;
        $.each($(section).find('li'), function (i, o) {
            if ( i == companyId ) {
                $(o).addClass('active');
            } else {
                $(o).removeClass('active');
            }
            i++;
        });
    }

    $scope.showCompanyProfile = function ($event) {
        $scope.selectCompany($event);
        setViewVisible('#clientMainPanel', '#modifyCompanyView');
        $('#generatedInvoicesLink').removeClass('active');
    }


    //función para publicar mensajes en panel
    $scope.showNotification = function (text, type) {

        //escribimos mensaje de error
        $scope.alertContent = text;
        //damos formato en función del tipo de alerta
        switch (type) {
        case 'success':
            $('#alert-area').removeClass('alert-danger');
            $('#alert-area').addClass('alert-success');
            $('#alert-icon').addClass('fa-check');
            $('#alert-area').removeClass('hidden');
            setTimeout(function () {
               $('#alert-area').addClass('hidden');
            }, 3000);
            break;
        case 'error':
            $('#alert-area').addClass('alert-danger');
            $('#alert-icon').addClass('fa-times-circle');
            $('#alert-area').removeClass('hidden');
            break;
        }

        window.scrollTo(0, 0);
    };
    
    //funcion para cerrar Alert Area por botón
    $scope.closeAlertArea = function () {
        //limpiamos el area de alertas
        $('#alert-area').removeClass('alert-success').removeClass('alert-danger');
        $('#alert-icon').removeClass('fa-check').removeClass('fa-times-circle');
        //ocultamos area
        $('#alert-area').addClass('hidden');
    };
    
    $scope.exportList2xlsx = function (list) {
        if (list.length > 0) {
            exportXlsxFile( list, $scope.empresaActiva.nombre + '_' + moment(Date.now()).format('YYYY-MM-DD_HH-mm-ss') );
        }
    };

}]);

//FUNCIONES AUXILIARES
function setViewVisible(containerSelector, activePanel) {
    //oculta paneles
    $.each($(containerSelector).find('div.view'), function (i, o) {
        $(o).addClass('animated fadeOut');
        $(o).addClass('hidden');
    });

    //muestra panel seleccionado
    $(activePanel).removeClass('hidden');
    //$(activePanel).removeClass('fadeOut');
}

function setActiveListItem(targetElement) {
    section = $(targetElement).parent().parent();
    $.each($(section).find('li'), function (i, o) {
        $(o).removeClass('active');
    });
    $(targetElement).parent().addClass('active');
    if ($(window).width() <= 768) {
        $("#wrapper").toggleClass("toggled");
        $("#menu-toggle2").toggleClass("collapsed");
    }
}



