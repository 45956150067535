contaApp.controller('EmployeeRegisterController', ['$scope', '$http', '$filter', function($scope, $http, $filter, $visits) {
    $scope.xlsxvisible = false;
    $scope.updateProfileEmployeeRegister = function ($event) {
        $event.stopPropagation();
        //console.log($scope.newEmpreg); //lshdebug
        $http({
            method: 'POST',
            url: 'client/update-notice',
            data: {_token: $scope.csfrToken, companyCode: $scope.selectedCompany, empreg: $scope.newEmpreg}
        }).then(function success(response) {
            if (response.data.outcome) {
                $scope.showNotification('Se han guardado los cambios', 'success');
                $scope.xlsxvisible = true;
                register = {
                    fecha: moment($scope.newEmpreg.date).format('DD/MM/YYYY'),
                    dni: $scope.newEmpreg.dni,
                    numero_ss: $scope.newEmpreg.ss_number,
                    nombre: $scope.newEmpreg.name,
                    puesto:$scope.newEmpreg.job,
                    tipo_contrato:$scope.newEmpreg.type_contract,
                    horas:$scope.newEmpreg.hours_workday,
                    salario:$scope.newEmpreg.salario,
                }
                $scope.$parent.empreg.push(register);
                console.log($scope.$parent.empreg);
                $scope.newEmpreg = {}; //borrado de datos de formulario
            } else {
                $scope.showNotification('No se han guardado los cambios. Se ha producido un error: ' + response.message, 'error');
                //console.log( 'Petición devuelve error: ' + response.message); //lshdebug
            }
        }, function error(response) {
                $scope.showNotification('No se han guardado los cambios. Se ha producido un error: ' + response.message, 'error');
                //console.log( 'La petición no se ha producido: ' + response.message); //lshdebug
        });
    };

    $scope.updateLote = function () {
    }

    //TABLA

    $scope.predicates = ['fecha', 'dni', 'tipo_factura', 'numero_ss', 'nombre', 'puesto', 'horas'];
    $scope.selectedPredicate = $scope.predicates[0];

    $scope.getters={
        fecha: function (value) {
            return new Date(moment(value.fecha, 'D-M-YYYY').toISOString());
        }
    };
    
    //remove to the real data holder
    $scope.removeItem = function removeItem(row) {
        var index = $scope.$parent.empreg.indexOf(row);
        if (index !== -1) {
            $scope.$parent.empreg.splice(index, 1);
        }
    };

    $scope.editItem = function editItem(row) {
        var index = $scope.$parent.empreg.indexOf(row);
        if (index !== -1) {
            $scope.newEmpreg = row;
            $scope.$parent.empreg.splice(index, 1);
            window.scrollTo(0, 0);
        }
    };
    
  //LIMPIAR FORMULARIO
  $scope.clear_form = function () {
    $scope.newEmpreg = {}; //borrado de datos de formulario
  }

  $scope.clear_lote = function () {
    $scope.xlsxvisible = false;
    $scope.$parent.empreg = [];
    $scope.$parent.loteAltas++;
  }
    // FIN TABLA
// ACCIONES POR REMESA
  $scope.sendFTP = function ($event) {
     $event.stopImmediatePropagation();
     $scope.updateLote();
     $scope.showNotification('Fichero xlsx enviado por FTP (80.38.217.58). Se ha generado el lote ' + $scope.loteAltas, 'success');
     $scope.clear_lote();
  };

  $scope.sendEmail = function ($event) {
    $event.stopImmediatePropagation();
    $scope.updateLote();
    $scope.clear_lote(); 
    $scope.showNotification('Fichero xlsx enviado a facturas@lsh-asesor.es. Se ha generado el lote ' + $scope.loteAltas, 'success');
  };
    $scope.exportList2xlsx = function ($event) {
       //$event.stopImmediatePropagation();
       $scope.$parent.exportList2xlsx($scope.$parent.empreg);
    };
}]);