/**
 * Created by agf on 20/12/2016.
 */
contaApp.controller('DocsController', ['$scope', '$http', 'RemoteFilesService',
    function ($scope, $http, RemoteFilesService) {
        var $ctrl = this;
        $ctrl.counter = 0;
        $ctrl.empresaActiva = {};
        $ctrl.rootFolder = {};
        $scope.parentFolders = [];
        $scope.currentLocation = [];
        $scope.searchResults = [];
        $scope.selectedPanel = '';
        $ctrl.itemsByPage = 30;
        $ctrl.showActions = {};
        $ctrl.resultados_string = "Resultados de la búsqueda";
        var currentApp = $('#wrapper').data('app-id');
        angular.element(document.querySelector('#menu-toggle2')).addClass('collapsed');
        $scope.parentContent = function (collection, baseLocation) {
            angular.forEach(Object.keys(collection), function (o, i) {
                $ctrl.rootFolder = {name: o, type: collection[o].type};
                $scope.parentFolders.push($ctrl.rootFolder)
            });
            $ctrl.rootFolder = {name: baseLocation, type: 'directory'};
            $scope.currentLocation.push({display_name: baseLocation, type: 'directory'});
            RemoteFilesService.loadFolderContent($ctrl.empresaActiva.id, $ctrl.empresaActiva.carpeta.display_name).then(function (res) {
                $scope.searchResults = res;
            });

            RemoteFilesService.getIndexedFiles($ctrl.empresaActiva.id, $ctrl.empresaActiva.carpeta.display_name).then(function (res) {
                $scope.searchBank = res;
            });
        };
        $ctrl.showPanel = function ($event) {
            $event.stopImmediatePropagation();
            $ctrl.counter += 1;
            $scope.selectedPanel = $($event.target).data('panel');
            $scope.selectedFolder = $($event.target).data('folder');
            $scope.$broadcast($scope.selectedPanel, $scope);
        };
        $scope.selectFolder = function ($event) {
            $scope.searchKey = null;
            $scope.selectedPanel = $($event.target).data('folder');
            if ($scope.currentLocation.length > 1) {
                $scope.currentLocation.length = 1;
            }
            if ($scope.currentLocation.length == 1) {
                $scope.currentLocation.push({display_name: $($event.target).data('folder')});
            }
            $scope.selectedPath = $scope.currentLocation.map(function (e) {
                return e.display_name;
            }).join('/');
            console.log($scope.currentLocation);
            RemoteFilesService.loadFolderContent($ctrl.empresaActiva.id, $scope.selectedPath).then(function (res) {
                $scope.searchResults = res;
            });

        };
        $ctrl.selectedRow = function (row) {
            $ctrl.showActions == row.display_name ? $ctrl.showActions = {} : $ctrl.showActions = row.display_name;
        };
        $ctrl.open = function ($event, row) {
            $event.stopImmediatePropagation();
            $event.preventDefault();
            switch (row.type) {
                case 'directory':
                    $scope.currentLocation.push({display_name: row.display_name});
                    RemoteFilesService.loadFolderContent($ctrl.empresaActiva.id, $scope.currentLocation.map(function (e) {
                        return e.display_name;
                    }).join('/')).then(function (res) {
                        $scope.searchResults = res;
                    });
                    console.log(row.display_name);
                    break;
                case 'file':
                    var currLoc = [];
                    angular.extend(currLoc, $scope.currentLocation);
                    currLoc.push({display_name: row.display_name});
                    RemoteFilesService.downloadFile($ctrl.empresaActiva.id, currLoc.map(function (e) {
                        return e.display_name;
                    }).join('/'));
                    break;
            }
        };
        $ctrl.openSearch = function ($event, row) {
            $event.stopImmediatePropagation();
            RemoteFilesService.downloadFile($ctrl.empresaActiva.id, row.path);
        };
        $ctrl.crumbClick = function ($event) {
            var trace = $($event.target).data('trace');
            if (trace == 0) $scope.selectedPanel = null;
            var count = $scope.currentLocation.length - (parseInt($($event.target).data('trace')));
            $scope.currentLocation.splice($($event.target).data('trace') + 1, count);
            $scope.selectedPath = $scope.currentLocation.map(function (e) {
                return e.display_name;
            }).join('/');
            RemoteFilesService.loadFolderContent($ctrl.empresaActiva.id, $scope.selectedPath).then(function (res) {
                $scope.searchResults = res;
            });
        };
        //n -> cadena introducida en el input
        //o -> cadena anterior a introducir algo en el input
        $scope.$watch('searchKey', function (n, o) {
            $scope.searchResults = [];
            if (n != o) {
                if ((typeof n != 'undefined') && (n!='')) {

                    angular.forEach($scope.searchBank, function (valor, clave) {
                        var re = new RegExp(n, 'gi');
                        res = re.test(valor.display_name);
                        if (res) {
                            $scope.searchResults.push(valor);
                        }
                    });
                }
                if(n=='') {
                    RemoteFilesService.loadFolderContent($ctrl.empresaActiva.id, $scope.currentLocation.map(function (e) {
                        return e.display_name;
                    }).join('/')).then(function (res) {
                        $scope.searchResults = res;
                    });
                }
            }
        });

        $scope.$watch('selectedPanel', function (n, o) {
            if (typeof $scope.currentLocation != 'undefined') {
                if ($scope.currentLocation.length == 1) $scope.selectedPanel = null;
            }
        });
    }]);


