contaApp.controller('IssueInvoiceController', ['$scope', '$http', '$filter', function($scope, $http, $filter, $visits) {


  $scope.data = {
  typeInvoiceOptions: [
    {id: 'Operaciones interiores', name: 'Operaciones interiores'},
    {id: 'Adquisiciones Intracom (Bienes)', name: 'Adquisiciones Intracom (Bienes)'},
    {id: 'Importaciones', name: 'Importaciones'},
    {id: 'Operaciones interiores (Bien inversión)', name: 'Operaciones interiores (Bien inversión)'},
    {id: 'Certificaciones', name: 'Certificaciones'},
  ],
  ivaOptions: [
    {id: '0', name: 'Exento de IVA', showname: '0%', factor: '0', factor_recargo: '0'},
    {id: '1', name: 'IVA 4%', showname: '4%', factor: '0.04', factor_recargo: '0.005'},
    {id: '2', name: 'IVA 10%', showname: '10%', factor: '0.10', factor_recargo: '0.014'},
    {id: '3', name: 'IVA 21%', showname: '21%', factor: '0.21', factor_recargo: '0.052'},
    {id: '5', name: 'IVA 5%', showname: '5%', factor: '0.05', factor_recargo: '0.005'},
  ],
  formasPago: [
    {id: '0', name: 'Transferencia bancaria a 30 días', showname: 'TB30', plazo: '30'},
    {id: '1', name: 'Transferencia bancaria a 60 días', showname: 'TB60', plazo: '60'},
    {id: '2', name: 'Recibo a 30 días', showname: 'R30', plazo: '30'},
    {id: '3', name: 'Recibo a 60 días', showname: 'R60', plazo: '60'},
    {id: '4', name: 'Al contado', showname: 'CO', plazo: '30'},
  ]
 };

  $scope.datosEmisor = [
    {nombre: "Auris S.L.", NIF: "48182134K", Domicilio: "Calle Oropel 19, 28045 - Madrid"},
  ]


  $scope.abono = "";

//INICIALIZACIÓN DE CONTROLADOR
  $scope.saveClientVisible = false;
  $scope.saveChangesVisible = false;

  $scope.saveClientShow = function() {
    if ($scope.newIssInv.cif != undefined) {
        if (!cifResgister()) {
          $scope.saveClientVisible = true;
          return true;
        } else {
          $scope.saveClientVisible = false;
          return  false;
        }
      }
  };

  $scope.saveChangesShow = function() {
    if ($scope.newIssInv.cif != undefined) {
      if (cifResgister() && clientIsChanged()) {
        $scope.saveChangesVisible = true;
        return true;
      } else {
        $scope.saveChangesVisible = false;
        return false;
      }
    }
  };

//LISTA AUTOCOMPLETE DE CLIENTES

  function cifResgister() {
    var cifInList = false;
    for (i in $scope.clienteList) 
    {
      if ($scope.newIssInv.cif == $scope.clienteList[i].cif_cliente ) {
        cifInList = true;
      }
    }
    return cifInList;  
  } 
  
   // Search employee by name
    $('#cliente_proveedor-issue').autocomplete( {
        source: function (request, response) {
            var term = request.term;
            var data = clienteAutoComplete(term, $scope);
            response(data);
        },
        minLength: 1,
        focus: function (event, ui) {
            //$(this).val(ui.item.cif_cliente + ' - ' + ui.item.nombre_cliente);
            return false;
        },
        select: function (event, ui) {
            //$(this).val(ui.item.nombre_cliente);
            $scope.newIssInv.cif = ui.item.cif_cliente;
            $scope.newIssInv.name = ui.item.nombre_cliente;
            $scope.newIssInv.email = ui.item.email;
            $scope.newIssInv.direccion = ui.item.direccion;
            $scope.$digest();
            return false;
        },
        messages: {
            noResults: '',
            results: function () {
            }
        }
    }).autocomplete('instance')._renderItem = function (ul, item) {
        return $("<li>")
            .append("<span class='ac-option'>" + item.cif_cliente + " - " + item.nombre_cliente + "</span>")
            .appendTo(ul); //ponemos style='color:#555555 !important' para evitar que se ponga en blanco en iOS
    };

    function clienteAutoComplete(term, $scope) {
        return $.map($scope.clienteList, function (item) {
            if (item.nombre_cliente.match(term.toUpperCase()) || item.cif_cliente.match(term)) {
                return item;
            }
            return null;
        });
    };

//
  $scope.$on('cargarPresupuestoEnFacturaHijo', function(event, datos_presuespuesto) {
    $scope.rellenarFormulario(datos_presuespuesto);
    console.log(datos_presuespuesto);
  });

  $scope.$on('actualizarNumeroFacturaHijo', function(event) {
      $scope.newIssInv.idfactura = $scope.idFacturaEmitidaActual;
   });

  $scope.$on('actualizarIdLoteIssActualHijo', function(event) {
      $scope.newIssInv.idLoteIssActual = $scope.idFacturaEmitidaActual;
   });

  $scope.$on('actualizarPrefijoSufijoHijo', function(event) {
      $scope.newIssInv.prefijo = $scope.empresaActiva.prefijo;
      $scope.newIssInv.sufijo = $scope.empresaActiva.sufijo;
   });



  $scope.rellenarFormulario = function(datos_presuespuesto) {
console.log(datos_presuespuesto);
    //si incluyes la fecha directamente en $scope.newIssInv.fecha da problemas de formato.
    $( "#dateinput-issue" ).datepicker( "setDate", moment(Date.now()).format('DD/MM/YYYY') );
    $scope.newIssInv.date =  moment().format('DD/MM/YYYY');
    $scope.newIssInv.control = datos_presuespuesto.control; 
    $scope.newIssInv.idfactura = $scope.idFacturaEmitidaActual;
    $scope.newIssInv.name = datos_presuespuesto.cliente_nombre;
    $scope.newIssInv.cif = datos_presuespuesto.cliente_cif;
    $scope.newIssInv.email = datos_presuespuesto.cliente_email;
    $scope.newIssInv.direccion = datos_presuespuesto.cliente_direccion;
    $scope.type_invoice = datos_presuespuesto.tipo_presupuesto_select;
    $scope.newIssInv.forma_pago = get_forma_pago_select(datos_presuespuesto.forma_pago);
    $scope.newIssInv.observaciones = datos_presuespuesto.observaciones;
        //Rellena $scope.conceptInv
    $scope.operacionesFromServer(datos_presuespuesto.id);

  }


  get_forma_pago_select = function (forma_pago_name) {

      var arr = $scope.data.formasPago; 
      var result  = arr.filter(function(o){return o.name == forma_pago_name;} );
      return result[0]; 

  }

  $scope.operacionesFromServer = function (presupuesto_id) {
          $http({
          method: 'POST',
          url: '/empresa/operaciones-presupuesto',
          data: {
            _token: $scope.csfrToken,
            empresa_id: $scope.empresa_id,
            presupuesto_id: presupuesto_id,
          }
      }).then(function success(response) {
        $scope.conceptInv = response.data.operaciones;

        for (i in $scope.conceptInv) {

            delete $scope.conceptInv[i].presupuesto_id;

        };
           
      }, function error(response) {
              $scope.showNotification('No se han podido cargar las operaciones', 'error');
              //console.log( 'La petición no se ha producido: ' + response.message); //lshdebug
      });
  };

//buscar operación por código
    $scope.saveCodVisible = false;

    $('#codigo_operacion').focusout(function () {
      if ($scope.newConceptInv.codigo != undefined) {
          $scope.saveCodVisible = !codResgister();
          $scope.$digest();
        }
    }); 

    $('#descripcion_operacion').focusout(function () {
      if ($scope.newConceptInv.codigo != undefined) {
          $scope.saveCodVisible = !codResgister();
          $scope.$digest();
        }
    });  

    function codResgister() {
      var codInList = false;
      for (i in $scope.codigoOperacionList) 
      {
        if ($scope.newConceptInv.codigo == $scope.codigoOperacionList[i].codigo_operacion ) {
          codInList = true;
        }
      }
      return codInList;  
    } 

    $('#codigo_operacion').autocomplete({
        source: function (request, response) {
            var term = request.term;
            var data = operacionAutoComplete(term, $scope);
            response(data);
        },
        minLength: 1,
        focus: function (event, ui) {
            //$(this).val(ui.item.codigo_operacion + ' - ' + ui.item.descripcion_operacion);
            return false;
        },
        select: function (event, ui) {
            //$(this).val(ui.item.nombre_cliente);
            $scope.newConceptInv.codigo = ui.item.codigo_operacion;
            $scope.newConceptInv.descripcion = ui.item.descripcion_operacion;
            $scope.newConceptInv.importe = ui.item.importe.toString().trim().replace(".",",");
            $scope.$digest();
            return false;
        },
        messages: {
            noResults: '',
            results: function () {
            }
        }
    }).autocomplete('instance')._renderItem = function (ul, item) {
        return $("<li>")
            .append("<span class='ac-option'>" + item.codigo_operacion + " - " + item.descripcion_operacion + "</span>")
            .appendTo(ul);
    };

    function operacionAutoComplete(term, $scope) {
        return $.map($scope.codigoOperacionList, function (item) {
            if (item.codigo_operacion.match(term) || item.descripcion_operacion.match(term.toUpperCase())) {
                return item;
            }
            return null;
        });
    }; 

  function clientIsChanged() {
    client = getClient($scope.newIssInv.cif);
    if (client[0] != undefined) {
      return ((client[0].nombre_cliente != $scope.newIssInv.name) || (client[0].email != $scope.newIssInv.email) || (client[0].direccion != $scope.newIssInv.direccion));
    } else {
      return false;
    }
  }

  function getClient(cif) {
    return $.map($scope.clienteList, function (item) {
      if (item.cif_cliente == cif) {
          return item;
      }
      return null;
    });
  }

  function updateClient() {
    return $.map($scope.clienteList, function (item) {
      if (item.cif_cliente == $scope.newIssInv.cif) {
          
          //local
          item.nombre_cliente = $scope.newIssInv.name;
          item.email = $scope.newIssInv.email;
          item.direccion = $scope.newIssInv.direccion;

          //servidor
          $scope.updateClientToServer({nombre_cliente: $scope.newIssInv.name.toUpperCase(), cif_cliente: $scope.newIssInv.cif, email: $scope.newIssInv.email, direccion: $scope.newIssInv.direccion });
          return true;
      }
      return false;
    });
  }

//calcular valores en formulario  

  $scope.calcular_base = function() {
    numConceptos = $scope.conceptInv.length;
    var base_acumulado = 0;
    var importe, descuento, cantidad;

    if ( numConceptos > 0 ) {
      for (i = 0; i < numConceptos; i++) {
        importe = parseFloat($scope.conceptInv[i].importe || 0);
        descuento =  parseFloat($scope.conceptInv[i].descuento * 0.01 || 0);
        cantidad =  parseFloat($scope.conceptInv[i].cantidad || 0);
        base_acumulado += parseFloat(importe * cantidad * (1-descuento) || 0);
      }
      $scope.newIssInv.base = Math.round(base_acumulado * 100) / 100 || null;
      return Math.round(base_acumulado * 100) / 100 || null;
    } else {
      return '';
    }
  }

  $scope.fecha_hoy = function() {
    $( "#dateinput-issue" ).datepicker( "setDate", moment().format('DD/MM/YYYY') );
    return moment().format('DD/MM/YYYY');
  }


  $scope.calcular_iva = function() {
    numConceptos = $scope.conceptInv.length;
    var iva_acumulado = 0;
    var importe, descuento, cantidad;

    if ( numConceptos > 0 ) {
      for (i = 0; i < numConceptos; i++) {
        importe = parseFloat($scope.conceptInv[i].importe || 0);
        descuento =  parseFloat($scope.conceptInv[i].descuento.toString().trim().replace(",",".") * 0.01 || 0);
        cantidad =  parseFloat($scope.conceptInv[i].cantidad || 0);
        iva_acumulado += parseFloat(importe * cantidad * (1-descuento) * $scope.conceptInv[i].iva_concepto_factor || 0);
      }
      $scope.newIssInv.total_iva =  Math.round(iva_acumulado * 100) / 100 || 0;
      return Math.round(iva_acumulado * 100) / 100 || 0;
    } else {
      return '';
    }
  }

  $scope.calcular_base_iva = function(tipo_iva) {
    numConceptos = $scope.conceptInv.length;
    var base_acumulada = 0;
    var importe, descuento, cantidad;

    if ( numConceptos > 0 ) {
      for (i = 0; i < numConceptos; i++) {
        if ($scope.conceptInv[i].iva_concepto == tipo_iva) {
          importe = parseFloat($scope.conceptInv[i].importe || 0);
          descuento =  parseFloat($scope.conceptInv[i].descuento * 0.01 || 0);
          cantidad =  parseFloat($scope.conceptInv[i].cantidad || 0);
          base_acumulada += parseFloat(importe * cantidad * (1-descuento)  || 0);
        }
      }
      return Math.round(base_acumulada * 100) / 100 || null;
    } else {
      return 0;
    }
  }

  $scope.calcular_recargo = function() {
    numConceptos = $scope.conceptInv.length;
    var recargo_acumulado = 0;
    var importe, descuento, cantidad;

    if ( ( numConceptos > 0 ) && ($scope.newIssInv.aplicaRecargo) )  {
      for (i = 0; i < numConceptos; i++) {
        importe = parseFloat($scope.conceptInv[i].importe || 0);
        descuento =  parseFloat($scope.conceptInv[i].descuento * 0.01 || 0);
        cantidad =  parseFloat($scope.conceptInv[i].cantidad || 0);
        recargo_acumulado += parseFloat(importe * cantidad * (1-descuento) * $scope.conceptInv[i].iva_concepto_factor_recargo || 0);
      }
      $scope.newIssInv.total_recargo =  Math.round(recargo_acumulado * 100) / 100 || null;
      //$scope.newIssInv.aplicaRecargo multiplica para dejar a cero si no lo aplicamos
      return Math.round(recargo_acumulado  * $scope.newIssInv.aplicaRecargo * 100) / 100 || null;
    } else {
      return '';
    }
  }

  $scope.calcular_retenciones = function() {
      var base = $scope.calcular_base();
      total_retenciones = base * parseFloat($scope.newIssInv.retencion.trim().replace(",",".")  || 0)  * 0.01 ;
      if ( base != 0 ) {
          cero_o_null = "0";
      } else {
           cero_o_null = null;
      }
      $scope.newIssInv.total_retenciones =  Math.round(total_retenciones * 100) / 100 || cero_o_null;
      return Math.round(total_retenciones * 100) / 100 || cero_o_null;
  }

  //calcular total de operación en formulario
  $scope.calcular_total_operacion = function() {
    if ( $scope.newConceptInv.importe != null) {
      var importe =  parseFloat($scope.newConceptInv.importe.toString().trim().replace(",",".") || 0);
    }
    if ( $scope.newConceptInv.cantidad != null) {
      var cantidad  =  parseFloat($scope.newConceptInv.cantidad.toString().trim().replace(",",".") || 0);
    }
    var descuento = parseFloat($scope.newConceptInv.descuento.toString().trim().replace(",",".") * 0.01 || 0);

    var iva = parseFloat($scope.newConceptInv.iva_concepto.factor || 0);
    var aplicaRecargo = $scope.newIssInv.aplicaRecargo ? 1 : 0;
    var recargo = parseFloat($scope.newConceptInv.iva_concepto.factor_recargo || 0) * aplicaRecargo;
    $scope.newConceptInv.total_operacion = Math.round(parseFloat(importe * cantidad * (1 - descuento) * (1 + iva + recargo) || 0) * 100) / 100 || null;
    return Math.round(parseFloat(importe * cantidad * (1 - descuento) * (1 + iva + recargo) || 0) * 100) / 100 || null;
  }

    $scope.calcular_total = function() {
      var base = $scope.calcular_base();
      var iva =  $scope.calcular_iva();
      var recargo = $scope.calcular_recargo();
      var retenciones = $scope.calcular_retenciones();
      var anteriortotal = $scope.newIssInv.total_invoice;

      if ( base > 0 ) {
          cero_o_null = "0";
      } else {
           cero_o_null = null;
      }

      var total = base + iva + recargo - retenciones;
      $scope.newIssInv.total_invoice =  Math.round(total * 100) / 100 || cero_o_null;
      
      //Si el total es negativo se entiende que es un abono y se pone -ABONO en sufijo
      cadena_abono = "-ABONO"
      if (total < 0) {
        $scope.newIssInv.abono = cadena_abono;
      } else {
        $scope.newIssInv.abono = "";
      }

      if ( (total < 0) && (anteriortotal >= 0) ){
        $scope.newIssInv.sufijo = $scope.newIssInv.sufijo + $scope.newIssInv.abono;
      }  

      if ( (total > 0) && (anteriortotal < 0) ){
        if ($scope.newIssInv.sufijo.substring($scope.newIssInv.sufijo.length - cadena_abono.length, $scope.newIssInv.sufijo.length) == cadena_abono){     
          $scope.newIssInv.sufijo = $scope.newIssInv.sufijo.substring(0, $scope.newIssInv.sufijo.length - cadena_abono.length);
        }
      }

      return Math.round(total * 100) / 100 || cero_o_null;
  }
    
  $scope.pdfIssueInvoiceSingle = function (row) {
      console.log('row');
      console.log(row);
      console.log('empresaActiva');
      console.log($scope.empresaActiva); //debuglsh
          $http({
          method: 'POST',
          url: '/pdf/imprimir-factura',
          data: {_token: $scope.csfrToken, datos_factura: row, datos_emisor: $scope.empresaActiva},
          responseType: 'arraybuffer' 
      }).then(function success(response) {
           var file = new Blob([response.data], {type: 'application/pdf'});
           saveAs(file, 'factura_' + $scope.empresaActiva.nombre + '_' + moment(Date.now()).format('YYYY-MM-DD_HH-mm-ss') + '.pdf');

      }, function error(response) {
            $scope.showNotification('Petición fallida: ' + response.message, 'error');
            //console.log( 'La petición no se ha producido: ' + response.message); //lshdebug
      });
  };


  $scope.pdfIssueInvoiceAll = function ($event, numFacturas) {
    $event.stopPropagation();
      for (i = 0; i < numFacturas; i++) {
       $scope.pdfIssueInvoiceSingle($scope.issInv[i]);
      }
  };

    $scope.conceptInv = [];

    $scope.newInvoiceConcept = function () {
      //guardar código de operación si esta visible y marcado para guardar
      if (($scope.saveCodVisible) & ($scope.newConceptInv.save_cod)) {
        if ($scope.newConceptInv.descripcion == undefined) $scope.newConceptInv.descripcion = '';
        operacion_nueva = {codigo_operacion: $scope.newConceptInv.codigo, descripcion_operacion: $scope.newConceptInv.descripcion.toUpperCase(), importe: $scope.newConceptInv.importe }
        $scope.codigoOperacionList.push(operacion_nueva);
        $scope.insertOperacionToServer(operacion_nueva);
      }

      //si han introducido , para los decimales
      $scope.newConceptInv.importe = $scope.newConceptInv.importe.toString().trim().replace(",",".");

      //guardar operación
      $scope.conceptInv.push({codigo: $scope.newConceptInv.codigo, 
        descripcion: $scope.newConceptInv.descripcion, 
        cantidad: $scope.newConceptInv.cantidad, 
        descuento: $scope.newConceptInv.descuento.toString().trim().replace(",","."), 
        importe: $scope.newConceptInv.importe, 
        iva_concepto: $scope.newConceptInv.iva_concepto.id, 
        iva_concepto_showname: $scope.newConceptInv.iva_concepto.showname, 
        iva_concepto_factor: $scope.newConceptInv.iva_concepto.factor,
        iva_concepto_factor_recargo: $scope.newConceptInv.iva_concepto.factor_recargo,
        total_operacion: $scope.newConceptInv.total_operacion });
        $scope.clear_invoiceConceptForm();
    }

//Función para ELIMIMAR FACTURA EN EL SERVIDOR   
  $scope.deleteFacturaToServer = function(idlote, id_factura_insertada, index) {

      $http({
          method: 'POST',
          url: '/empresa/eliminar-factura-emitida',
          data: {
            _token: $scope.csfrToken,
            empresa_id: $scope.empresa_id,
            idlote: idlote,
            id_factura_insertada: id_factura_insertada,
          }
      }).then(function success(response) {
            $scope.issInv.splice(index, 1);
            $scope.issInvExcel.splice(index, 1);
      }, function error(response) {
          $scope.showNotification('No se ha podido eliminar la factura', 'error');
      });  

  }
    
//Función para INSERTAR FACTURA EN EL SERVIDOR   
  $scope.insertFacturaEmitidaToServer = function(idlote, factura_nueva, factura_nueva_imprimible_0, operaciones) {

      $http({
          method: 'POST',
          url: '/empresa/insertar-factura-emitida',
          data: {
              _token: $scope.csfrToken,
              empresa_id: $scope.empresa_id,
              idlote: idlote,
              factura_nueva: factura_nueva,
              operaciones: operaciones,
          }
      }).then(function success(response) {

      //---- GUARDAR LA FACTURA EN LOCAL
        //Para vosualizar en tabla
        
        factura_nueva['id_factura_insertada'] = response.data.id_factura_insertada;
        $scope.$parent.issInv.push(factura_nueva);
        $scope.$parent.lote_iss_sin_enviar = idlote;


        //Para Imprimir - Excel - Cambia formato según dónde lo queramos exportar (REVISAR SEPARAR IMPRIMIR DE FORMATO ERP)
        $scope.$parent.issInvExcel.push(factura_nueva_imprimible_0);

        //SI TODO OK
        $scope.xlsxvisible = true;
        $scope.clear_form();
        //actualizamos globlamente el número de factura actual
        $scope.$parent.idFacturaEmitidaActual++;
        $scope.$emit('actualizarNumeroFactura');

          $scope.showNotification('La factura ' + factura_nueva.idfactura + ' se ha guardado en el servidor', 'success-top');
      }, function error(response) {
          $scope.showNotification('No se han podido guardar la factura ' + factura_nueva.idfactura, 'error');
      });        
  }

//Función que gestiona INSERTAR FACTURA 
//Función de actualización de datos cuando insertamos una factura válida por formulario
  $scope.insertGenerateInvoice = function () {
  //---- ACTUALIZAR/INSERTAR CLIENTE SI PROCEDE 
  //---- El usuario esta usando un cliente nuevo/modificado y ha marcado la opción de actualizar)

    //guardar cliente proveedor si es nuevo (distinto NIF)
    if ( ($scope.newIssInv.save_client) && ($scope.saveClientVisible) ){
      if ($scope.newIssInv.name == undefined) $scope.newIssInv.name = '';

      //Insertamos cliente en local
      $scope.clienteList.push({nombre_cliente: $scope.newIssInv.name.toUpperCase(), cif_cliente: $scope.newIssInv.cif, email: $scope.newIssInv.email, direccion: $scope.newIssInv.direccion });
      
      //Insertamos cliente en servidor
      $scope.insertClientToServer({nombre_cliente: $scope.newIssInv.name.toUpperCase(), cif_cliente: $scope.newIssInv.cif, email: $scope.newIssInv.email, direccion: $scope.newIssInv.direccion });
    }

    //Actualizamos cliente si ha cambiado datos (mismo NIF)
    if ( ($scope.newIssInv.save_changes) && ($scope.saveChangesVisible) )  {
      updateClient();
    }

  //---- PREPARAR INFORMACIÓN PARA GUARDAR
    var aplicaRecargo = $scope.newIssInv.aplicaRecargo ? 1 : 0;
    $scope.base_imponible0 = $scope.calcular_base_iva(0);
    $scope.base_imponible1 = $scope.calcular_base_iva(1);
    $scope.base_imponible2 = $scope.calcular_base_iva(2);
    $scope.base_imponible3 = $scope.calcular_base_iva(3);

    //REGISTRO PARA TABLA LOCAL
    //paso base_imponible1..10 pero de momento recalculo en servidor.
    //NOTA REFACTORIZACIÓN -- ELIMINAR INFO REDUNDANTE


    //"ÑAPA" PORQUE AL RELACIÓN JQUERY ANGULAR ME CAMBIA A VECES EL VALOR MOMENT A STRING
    if (typeof $scope.newIssInv.date == "string") {
      fecha_insert =  moment($scope.newIssInv.date, 'DD/MM/YYYY');
    } else {
      fecha_insert =  $scope.newIssInv.date;
    }

    var factura_nueva = {
      fecha: moment(fecha_insert).format('DD/MM/YYYY'),
      fecha_date: fecha_insert,
      prefijo: $scope.newIssInv.prefijo,
      idfactura: $scope.newIssInv.idfactura,
      sufijo: $scope.newIssInv.sufijo,
      control: $scope.newIssInv.control,
      concepto: $scope.newIssInv.concepto,
      cliente_nombre: $scope.newIssInv.name,
      cliente_cif: $scope.newIssInv.cif,
      cliente_email: $scope.newIssInv.email,
      cliente_direccion: $scope.newIssInv.direccion,
      tipo_factura: $scope.newIssInv.type_invoice.name,
      tipo_factura_select: $scope.newIssInv.type_invoice,
      forma_pago: $scope.newIssInv.forma_pago.name,
      forma_pago_select: $scope.newIssInv.forma_pago,
      plazo_pago: $scope.newIssInv.forma_pago.plazo,
      base_imponible: $scope.newIssInv.base,
      base_imponible0: $scope.base_imponible0,
      base_imponible1: $scope.base_imponible1,
      base_imponible2: $scope.base_imponible2,
      base_imponible3: $scope.base_imponible3,
      total_iva: $scope.newIssInv.total_iva,
      total_recargo: $scope.newIssInv.total_recargo,
      retenciones_factor: $scope.newIssInv.retencion.trim().replace(",","."),
      total_retenciones: $scope.newIssInv.total_retenciones,
      total_factura: $scope.newIssInv.total_invoice,
      conceptos: $scope.conceptInv,
      observaciones: $scope.newIssInv.observaciones,
    }

    var factura_nueva_imprimible_0 = $scope.fromFormatoStandardEmitidasToERP(factura_nueva, 'despachos');
    
    consola('factura_nueva', factura_nueva);    
    consola('factura_nueva_imprimible_0', factura_nueva_imprimible_0); 

    // //PARA SAGE DESPACHOS
    // var factura_nueva_imprimible_0 = {
    //   Serie: moment().format('YY'),
    //   Factura: $scope.newIssInv.prefijo + $scope.newIssInv.idfactura + $scope.newIssInv.sufijo,
    //   Fecha: moment(fecha_insert).format('DD/MM/YYYY'),
    //   'CIF/DNI': $scope.newIssInv.cif,
    //   'Cliente': $scope.newIssInv.name,
    //   'Concepto Ingreso': $scope.newIssInv.concepto,
    //   'Importe Factura': $scope.newIssInv.total_invoice,
    //   'Base Imponible1': $scope.base_imponible3,
    //   '%Iva1': '21',
    //   'Cuota Iva1': $scope.base_imponible3 * 0.21,
    //   '%RecEq1': 0.052 * aplicaRecargo * 100,
    //   'Cuota Rec1': $scope.base_imponible3 * 0.052  * aplicaRecargo,
    //   'Base ret': $scope.newIssInv.base,
    //   '%Retención': $scope.newIssInv.retencion.trim().replace(",","."),
    //   'Cuota Retención': $scope.newIssInv.total_retenciones,
    //   'Base Imponible2': $scope.base_imponible2,
    //   '%Iva2': '10',
    //   'Cuota Iva2': $scope.base_imponible2 * 0.1,
    //   '%RecEq2': 0.014 * aplicaRecargo * 100,
    //   'Cuota Rec2': $scope.base_imponible2 * 0.014  * aplicaRecargo,
    //   'Base Imponible3': $scope.base_imponible1,
    //   '%Iva3': '4',
    //   'Cuota Iva3': $scope.base_imponible1 * 0.04,
    //   '%RecEq3': 0.005 * aplicaRecargo * 100,
    //   'Cuota Rec3': $scope.base_imponible1 * 0.005  * aplicaRecargo,
    //   'Periodo': moment().format('MM'),
    // }


    //El periodo puede que haya que calcularlo por consulta para cada empresa

    // select numeroperiodo,ejercicio from Periodos 
    // where codigoempresa = 1338 and datepart(month,fechainicio) = datepart(month,getdate())
    // and datepart(year,fechainicio) = datepart(year,getdate()) and numeroperiodo between 1 and 12

    //PARA EHSOFT
    var factura_nueva_imprimible = {
        'FECHA OPERACIÓN': moment().format('DD/MM/YYYY'),
        'FECHA EXPEDICION': moment(fecha_insert).format('DD/MM/YYYY'),
        'BASE': $scope.base_imponible3,
        '%': '21%',
        'CUOTA': $scope.base_imponible3 * 0.21,
        'TOTAL FACTURA': $scope.newIssInv.total_invoice,
        'NIF': $scope.newIssInv.cif,
    }

  //---- GUARDAR LA FACTURA EN SERVIDOR (Y SI SALE BIEN EN LOCAL)
    $scope.insertFacturaEmitidaToServer($scope.idLoteIssActual, factura_nueva, factura_nueva_imprimible_0, $scope.conceptInv);
  };

//Funcion para ACTUALIZAR STATUS DEL LOTE (enviado por FTP o por EMAIL)
 $scope.updateLoteStatusToServer = function(idlote, status) {
  $http({
      method: 'POST',
      url: '/empresa/actualizar-status-lote-emitidas',
      data: {
          _token: $scope.csfrToken,
          empresa_id: $scope.empresa_id,
          fecha_inicial: $scope.fecha_inicial_emitidas,
          fecha_final:  $scope.fecha_final_emitidas,
          idlote: idlote,
          status: status,
      }
  }).then(function success(response) {
     //si se actualiza correctamente en servidor, actualizar en local lote y facturas
    //borramos el lote que hubiera previamente con esa numeración    
     enrango_historial = false;
     var facturas = response.data.facturas_lote_actualizado;

      for(var i = 0; i < facturas.length; i++) {
        facturas[i].idlote = response.data.lote_actualizado.idlote;
        
        fecha_factura = moment(facturas[i].fecha, "DD/MM/YYYY");
        if (fecha_factura.isSameOrAfter(moment($scope.fecha_inicial_emitidas, "DD/MM/YYYY")) && fecha_factura.isSameOrBefore(moment($scope.fecha_final_emitidas, "DD/MM/YYYY"))) { //insertamos factura para mostrar si esta en el rango de fechas del historial
          $scope.facturas_emitidas.push(facturas[i]);
          enrango_historial = true;
        }
        consolidar_factura_historial(facturas[i], $scope.trimestralEmitidas);
      }

      if (enrango_historial) { //insertamos lote para mostrar si tiene facturas en el rango de fechas del historial
        $scope.lotes_facturas_emitidas.push(response.data.lote_actualizado);

      }

      $scope.showNotification('Lote ' + idlote + ' enviado correctamente a la asesoria', 'success-top');
      $scope.$parent.lote_iss_sin_enviar = 0;
      $scope.empezarNuevoLote();
         
  }, function error(response) {
      $scope.showNotification('No se ha podido enviar el lote ' + idlote, 'success-top');

  }); 
 }

//ACTUALIZAR LOTE
    
    $scope.updateLote = function () {
    }
    //TABLA

    $scope.predicates = ['fecha', 'cliente_proveedor', 'tipo_factura', 'base_imponible', 'total_iva', 'total_factura', 'cuenta_cargo', 'cuenta_cargo'];
    $scope.selectedPredicate = $scope.predicates[0];

    $scope.getters={
        fecha: function (value) {
            return new Date(moment(value.fecha, 'D-M-YYYY').toISOString());
        }
    }
    
    //remove to the real data holder
    $scope.removeItem = function removeItem(row) {
        var index = $scope.conceptInv.indexOf(row);
        if (index !== -1) {
            $scope.conceptInv.splice(index, 1);
        }
    };

    $scope.removeItemFactura = function removeItem(row) {
        var index = $scope.issInv.indexOf(row);
        if (index !== -1) {
            //BORRAR EN SERVIDOR Y SI OK BORRAR EN LOCAL
            $scope.deleteFacturaToServer($scope.idLoteIssActual, row.id_factura_insertada, index);
        }
    };

    //total por operación en la tabla (para que se actualice al cambiar la tabla)
    $scope.calcularOperacion = function calcularOperacion(row) {
      var importe =  parseFloat(row.importe || 0);
      var cantidad  =  parseFloat(row.cantidad || 0);
      if (row.descuento == null) {row.descuento = ""};
      var descuento = parseFloat(row.descuento.toString().trim().replace(",",".") * 0.01 || 0);
      var iva = parseFloat(row.iva_concepto_factor || 0);
      var aplicaRecargo = $scope.newIssInv.aplicaRecargo ? 1 : 0;
      var recargo = parseFloat($scope.newConceptInv.iva_concepto.factor_recargo || 0) * aplicaRecargo;
      row.total_operacion = Math.round(parseFloat(importe * cantidad * (1 - descuento) * (1 + iva + recargo) || 0) * 100) / 100 || null;
      return Math.round(parseFloat(importe * cantidad * (1 - descuento) * (1 + iva + recargo)  || 0) * 100) / 100 || null;
    }

    // FIN TABLA
    $scope.sendFTP = function ($event) {
       $event.stopImmediatePropagation();
       ejercicio = moment(Date.now()).format('MM YYYY');

        $http({
            method: 'POST',
            url: '/envios/xlsx-to-ftp',
            data: {_token: $scope.csfrToken, lista_xlsx: $scope.issInvExcel, empresa: $scope.empresaActiva.nombre, cif: $scope.empresaActiva.cif, iderp: $scope.empresaActiva.iderp, ejercicio: ejercicio},
            responseType: 'arraybuffer' 
        }).then(function success(response) {
            $scope.updateLoteStatusToServer($scope.idLoteIssActual, "ftp");

        }, function error(response) {
                $scope.showNotification('Petición fallida: ' + response.message, 'error');
        });
    };

    $scope.sendEmail = function ($event) {
       $event.stopImmediatePropagation();

      $http({
        method: 'POST',
        url: '/email/lote-emitidas-to-asesor',
        data: {
          _token: $scope.csfrToken,
          empresa_id: $scope.empresa_id,
          idlote: $scope.idLoteIssActual,
        },   
        responseType: 'arraybuffer' 
      }).then(function success(response) {
        $scope.updateLoteStatusToServer($scope.idLoteIssActual, "mail");
      }, function error(response) {
          $scope.showNotification('No se ha podido enviar el email. Por favor, vuelva a intentarlo pasados unos instantes', 'error');
      });
    };

    //Cuando enviamos el lote en curso, empezamos uno nuevo
    $scope.empezarNuevoLote = function () {
       $scope.updateLote();
       $scope.clear_lote();
    };
           
    $scope.sendEmailClient = function (row) {
      console.log(row);
      var index = $scope.issInv.indexOf(row);
      if ((index !== -1) && (row.cliente_email != undefined)) {

        $http({
          method: 'POST',
          url: '/email/factura-to-cliente',
          data: {
            _token: $scope.csfrToken,
            empresa_id: $scope.empresa_id,
            factura_id: row.id_factura_insertada,
            cliente_email: row.cliente_email,
            cliente_nombre: row.cliente_nombre,
          },   
          responseType: 'arraybuffer' 
        }).then(function success(response) {
          $scope.showNotification('Factura enviada a cliente ' + $scope.issInv[index].cliente_nombre + ' (' + $scope.issInv[index].cliente_email + ')', 'success');
        }, function error(response) {
            $scope.showNotification('No se ha podido enviar el email. Por favor, vuelva a intentarlo pasados unos instantes', 'error');
        });
      } 
      else {
        $scope.showNotification('El cliente no tiene una cuenta de correo asociada', 'error');
      }    
    };


  $scope.exportList2xlsx = function ($event) {
     $event.stopImmediatePropagation();
     var issInvExcel = [];
     for (var i=0; i<$scope.$parent.issInv.length; i++) { 
        factura = $scope.$parent.issInv[i];
        issInvExcel.push($scope.fromFormatoStandardRecibidasToERP(factura, 'despachos'));
     } 
     $scope.$parent.exportList2xlsx(issInvExcel);
     $scope.showNotification('Descarga de fichero .xlsx correcta', 'success');
  };

//En función del ERP generar un excel a partir de un objeto con el formato necesario
  $scope.fromFormatoStandardRecibidasToERP = function(factura, formato_erp) {
    if (factura.total_recargo > 0) {
      var aplicaRecargo = 1;
    } else {
      var aplicaRecargo = 0;
    }
console.log(factura);
    factor_retenciones = factura.total_retenciones/factura.base_imponible*100;

    switch (formato_erp) {
      
      case 'despachos':
        var factura_formateada = {
          
          Lote: factura.idlote,
          Factura: factura.prefijo + factura.idfactura + factura.sufijo ,
          Fecha: factura.fecha,
          
          'Cliente': factura.cliente_nombre,
          'CIF/DNI': factura.cliente_cif,
          'Concepto': factura.concepto,          
          'Control': factura.control,                    
          '%Retención': number_format_es_ES(factura.retenciones_factor),

          'Base Imponible': number_format_es_ES(factura.base_imponible),
          'Total IVA': number_format_es_ES(factura.total_iva),
          'Total Recargo': number_format_es_ES(factura.total_recargo),
          'Cuota Retención': number_format_es_ES(factura.total_retenciones),          
          'Importe Factura': number_format_es_ES(factura.total_factura),

          'Base Imponible0': number_format_es_ES(factura.base_imponible0),

          'Base Imponible1': number_format_es_ES(factura.base_imponible1),
          'Iva1': '4',
          'Cuota Iva1': number_format_es_ES(factura.base_imponible1 * 0.04),
          '%RecEq1': '0,005',
          'Cuota Rec1': number_format_es_ES(factura.base_imponible1 * 0.005  * aplicaRecargo),

          'Base Imponible2': number_format_es_ES(factura.base_imponible2),
          'Iva2': '10',
          'Cuota Iva2': number_format_es_ES(factura.base_imponible2 * 0.1),
          'RecEq2': '0,014',
          'Cuota Rec2': number_format_es_ES(factura.base_imponible2 * 0.014  * aplicaRecargo),

          'Base Imponible3': number_format_es_ES(factura.base_imponible3),
          'Iva3': '21',
          'Cuota Iva3': number_format_es_ES(factura.base_imponible3 * 0.21),
          'RecEq3': '0,052',
          'Cuota Rec3': number_format_es_ES(factura.base_imponible3 * 0.052  * aplicaRecargo),

          'Tipo Factura': factura.tipo_factura,
          'Forma de pago': factura.forma_pago,
          'Plazo de pago': factura.plazo_pago,
          'Observaciones': factura.observaciones,

        }
        break;
    }
    return factura_formateada;
  }


    $scope.exportList2pdf = function ($event) {
       $event.stopImmediatePropagation();
       $scope.$parent.exportList2xlsx($scope.$parent.issInv);
       $scope.showNotification('Descarga de fichero .pdf correcta', 'success');
    };

    //generar tabla de lote de facturas en pdf
    $scope.pdfGeneratedInvoices = function ($event) {
      $event.stopPropagation();
            $http({
            method: 'POST',
            url: '/pdf/tabla-facturas',
            data: {
              _token: $scope.csfrToken,
              empresa_id: $scope.empresa_id,
              facturas: $scope.$parent.issInv,
              lote: $scope.idLoteIssActual,
            },   
            responseType: 'arraybuffer' 
        }).then(function success(response) {
             var file = new Blob([response.data], {type: 'application/pdf'});
             saveAs(file, $scope.empresaActiva.nombre + '_' + moment(Date.now()).format('YYYY-MM-DD_HH-mm-ss') + '.pdf');
             $scope.showNotification('Descarga de fichero .pdf correcta', 'success');
        }, function error(response) {
                $scope.showNotification('Petición fallida: ' + response.message, 'error');
                //console.log( 'La petición no se ha producido: ' + response.message); //lshdebug
        });
    };

    $scope.clear_form = function () {
      
      $scope.clear_invoiceConceptPanel();
      
      //LIMPIAR ATRIBUTOS DE FORMULARIO
      $scope.issueInvoiceForm.$setUntouched();
      $scope.issueInvoiceForm.$setPristine();
      $scope.issueInvoiceForm.$submitted = false;

      $( "#dateinput-issue" ).datepicker( "setDate", moment().format('DD/MM/YYYY'));
      $scope.newIssInv.date =  moment().format('DD/MM/YYYY');
      //$scope.newIssInv.date =  $( "#dateinput-issue" ).datepicker( "getDate");   
      //console.log( $( "#dateinput-issue" ).datepicker( "getDate") );
      //$scope.newIssInv.date = moment("11/11/2017", "DD/MM/YYYY");

      $scope.newIssInv.concepto = '';
      $scope.newIssInv.control = '';

      $scope.newIssInv.prefijo = $scope.empresaActiva.prefijo;
      $scope.newIssInv.sufijo = $scope.empresaActiva.sufijo;

      $scope.newIssInv.name = '';
      $scope.newIssInv.cif = '';
      $scope.newIssInv.email = '';
      $scope.newIssInv.direccion = '';
      $scope.newIssInv.observaciones = '';

      $scope.newIssInv.type_invoice = $scope.data.typeInvoiceOptions[0];
      $scope.newIssInv.forma_pago = $scope.data.formasPago[0];
      $scope.saveClientVisible = false;
      $scope.saveChangesVisible = false;
      $scope.newIssInv.retencion = $scope.empresaActiva.retencion_pordefecto;
    }

    $scope.clear_invoiceConceptForm = function () {

      //LIMPIAR ATRIBUTOS DE FORMULARIO
      $scope.operacionesForm.$setUntouched();
      $scope.operacionesForm.$setPristine();
      $scope.operacionesForm.$submitted = false;
      
      $scope.newConceptInv.codigo = '';
      $scope.newConceptInv.cantidad = '';
      $scope.newConceptInv.descuento = '';
      $scope.newConceptInv.descripcion = '';
      $scope.newConceptInv.importe = '';
      $scope.newConceptInv.cantidad = 1;
      $scope.newConceptInv.descuento = 0;
      $scope.saveCodVisible = false;
      $scope.operacionesForm.$submitted = false;
    }

    $scope.clear_invoiceConceptPanel = function () {
      $scope.clear_invoiceConceptForm();
      $scope.conceptInv = []; 
    }

  $scope.clear_lote = function () {
    $scope.xlsxvisible = false;
    $scope.clear_form();
    $scope.$parent.issInv = [];
    $scope.$parent.issInvExcel = [];
    $scope.$parent.idLoteIssActual++;
    $scope.$emit('actualizarIdLoteIssActual');
    $scope.lote_iss_cargado_sin_enviar = false;
  }

//SUBMIT
  $scope.operacionesFormSubmit = function($event)  {
    $event.stopPropagation();
    if ($scope.operacionesForm.$invalid) {
      $scope.operacionesForm.$setSubmitted();

    } else {
      $scope.newInvoiceConcept();
    }
  }

  $scope.issueInvoiceFormSubmit = function($event)  {
    $event.stopPropagation();

    if (($scope.issueInvoiceForm.$invalid) || ($scope.conceptInv.length == 0)) {
      $scope.issueInvoiceForm.$setSubmitted();
      if ($scope.issueInvoiceForm.$invalid) {
        $scope.showAlert('El formulario contiene datos incorrectos', 'error');
      } else {
        $scope.showAlert('Es necesario incluir al menos una operación en la factura', 'error');
      }
      
    } else {
      $scope.insertGenerateInvoice();
    }
  }


  //PARA DEBUG
  function vigila(variable) {
    //VIGILAR VARIABLES
    $scope.$watch(variable, function (variable) {console.log("Cambia " + variable + ": "); console.log(variable)});
  }

}]);


//PARA DEBUG
function sleep(miliseconds) {
   var currentTime = new Date().getTime();
   console.log('sleep... (' + miliseconds +')'); 
   while (currentTime + miliseconds >= new Date().getTime()) {
   }
}
