/**
 * Created by agf on 23/11/2016.
 */

contaApp.directive('stSelectDistinct', ['$filter', function ($filter) {
    return {
        restrict: 'E',
        require: '^stTable',
        replace: true,
        scope: {
            collection: '=',
            predicate: '@',
            predicateExpression: '=',
            defaultValue: '@',
            filterCollection: '=',
            label: '@',
            trackBy: '@'
        },
        template: '<select class="form-control" data-ng-model="selectedOption" data-ng-change="optionChanged(selectedOption)" ng-options="opt for opt in distinctItems track by opt"></select>',
        link: function (scope, element, attr, table) {
            var getPredicate = function () {
                var predicate = scope.predicate;
                if (!predicate && scope.predicateExpression) {
                    predicate = scope.predicateExpression;
                }
                return predicate;
            };
            scope.$watch('collection', function (newValue) {
                var predicate = getPredicate();
                if (newValue) {
                    var temp = [];
                    scope.distinctItems = ['Todos'];

                    angular.forEach(scope.collection, function (item) {
                        var obj = {}, value;
                        if (scope.filterCollection && scope.label && scope.trackBy) {
                            value = $filter('filter')(scope.filterCollection, obj[scope.trackBy] = item[predicate], true)[0][scope.label];
                        } else {
                            value = item[predicate];
                        }
                        if (value && value.trim().length > 0 && temp.indexOf(value) === -1) {
                            temp.push(value);
                        }
                    });
                    temp.sort();

                    scope.distinctItems = scope.distinctItems.concat(temp);
                    switch (scope.defaultValue) {
                        case 'last':
                            scope.selectedOption = scope.distinctItems[scope.distinctItems.length - 1];
                            break;
                        case 'undefined':
                            scope.selectedOption = scope.distinctItems[0];
                            break;
                        default:
                            if (scope.distinctItems.indexOf(scope.defaultValue) > -1) {
                                scope.selectedOption = scope.defaultValue;
                            } else {
                                scope.selectedOption = 'Todos';
                            }
                            break;
                    }
                    scope.optionChanged(scope.selectedOption);
                }
            }, true);

            scope.optionChanged = function (selectedOption) {
                var predicate = getPredicate();

                var query = {};

                query.distinct = selectedOption;

                if (query.distinct === 'Todos') {
                    query.distinct = '';
                } else {
                    if (scope.filterCollection && scope.label && scope.trackBy) {
                        var obj = {};
                        query.distinct = $filter('filter')(scope.filterCollection, obj[scope.label] = selectedOption)[0][scope.trackBy];
                    }
                }
                table.search(query.distinct, predicate);
            };
        }
    }
}]);

contaApp.directive('stSummary', function () {
    return {
        restrict: 'E',
        require: '^stTable',
        template: '<div><%title%>:&nbsp;<%size%></div>',
        scope: {title: '@'},
        link: function (scope, element, attr, table) {
            scope.$watch(table.getFilteredCollection, function (val) {
                scope.size = (val || []).length;
            })
        }
    }
});

contaApp.directive('stTotal', function () {
    return {
        restrict: 'E',
        require: '^stTable',
        template: '<div><%title%><%size |number:2%></div>',
        scope: {title: '@', predicate: '@'},
        link: function (scope, element, attr, table) {
            scope.$watch(table.getFilteredCollection, function (val) {
                scope.size = 0;
                for (var j = 0; j < (val || []).length; j++) scope.size += val[j][scope.predicate];
            });
        }
    }
});

contaApp.directive('stSelectRowCustom', function () {
    return {
        restrict: 'A',
        require: '^stTable',
        scope: {
            row: '=stSelectRowCustom',
            callback: '&stSelected'
        },
        link: function (scope, element, attr, ctrl) {
            var mode = attr.stSelectMode || 'single';
            element.bind('click', function ($event) {
                scope.$apply(function () {
                    ctrl.select(scope.row, mode, $event.shiftKey);
                    scope.callback();
                });
            });
            scope.$watch('row.isSelected', function (newValue) {
                if (newValue === true) {
                    element.addClass('st-selected');
                } else {
                    element.removeClass('st-selected');
                }
            });
        }
    };
});