contaApp.controller('HistorialPresupuestosController', ['$scope', '$http', '$filter', function($scope, $http, $filter, $visits) {

  //$scope.$parent.presupuestos = $scope.$parent.presupuestos.concat($scope.presupuestos_default);

  $scope.removeItemPresupuesto = function removeItem(row) {

      $http({
          method: 'POST',
          url: '/empresa/eliminar-presupuesto',
          data: {
            _token: $scope.csfrToken,
            empresa_id: $scope.empresa_id,
            idlote: row.idlote,
            id_presupuesto: row.id,
          }
      }).then(function success(response) {
        var index = $scope.presupuestos_historial.indexOf(row);
        if (index !== -1) {
            $scope.presupuestos_historial.splice(index, 1);
        }

        $scope.showNotification('Presupuesto eliminado', 'success');

      }, function error(response) {
          $scope.showNotification('No se ha podido eliminar el presupuesto', 'error');
      });  
  };

  $scope.aceptarPresupuesto = function (row) {
      $scope.actualizarEstado(row, "Aceptado");
  }

  $scope.rechazarPresupuesto = function (row) {
      $scope.actualizarEstado(row, "Rechazado");
  }

  $scope.actualizarEstado = function(row, estado) {

      $http({
          method: 'POST',
          url: '/empresa/actualizar-estado-presupuesto',
          data: {
            _token: $scope.csfrToken,
            empresa_id: $scope.empresa_id,
            estado: estado,
            id_presupuesto: row.id,
          }
      }).then(function success(response) {
        var index = $scope.presupuestos_historial.indexOf(row);
        if (index !== -1) {
            $scope.presupuestos_historial[index].estado = estado;
        }

        $scope.showNotification('Presupuesto actualizado', 'success');

      }, function error(response) {
          $scope.showNotification('No se ha podido actualizar el presupuesto', 'error');
      });
  }

  $scope.facturaDePresupuesto = function (row) {
      //PARA VERSIONES MÁS COMPLEJAS
        //COMPROBAR SI HAY UN PRESUPUESTO EN CURSO (O LOTE) Y PREGUNTAR AL USUARIO
        //Actualmente tiene una factura en curso que no ha guardado. Si continua los datos del perdaran introducidos se perderán.
        //Actualmente esta editando el lote de facturas 1121. Botones: [Añadir a lote 1121] [Añadir a nuevo lote]
        //$scope.newIssInv.email =  "prueba@prueba.com";
        $scope.$emit('cargarPresupuestoEnFactura', row);
        $scope.mostrarVista('issueInvoiceView');
  }  

  $scope.presupuestoDePresupuesto = function (row) {
      //PARA VERSIONES MÁS COMPLEJAS
        //COMPROBAR SI HAY UN PRESUPUESTO EN CURSO (O LOTE) Y PREGUNTAR AL USUARIO
        //Actualmente tiene una factura en curso que no ha guardado. Si continua los datos del perdaran introducidos se perderán.
        //Actualmente esta editando el lote de facturas 1121. Botones: [Añadir a lote 1121] [Añadir a nuevo lote]
        //$scope.newIssInv.email =  "prueba@prueba.com";
        $scope.$emit('cargarPresupuestoEnPresupuesto', row);
        $scope.mostrarVista('generarPresupuestoView');
  }  

  $scope.pdfPresupuestoSingle = function (row) {
          $http({
          method: 'POST',
          url: '/pdf/imprimir-presupuesto-historial',
          data: {
            _token: $scope.csfrToken,
            empresa_id: $scope.empresa_id,
            presupuesto_id: row.id,
          },
          responseType: 'arraybuffer' 
      }).then(function success(response) {
           var file = new Blob([response.data], {type: 'application/pdf'});
           saveAs(file, 'presupuesto_' + row.idpresupuesto + '_' + $scope.empresaActiva.nombre + '_' + moment(Date.now()).format('YYYY-MM-DD_HH-mm-ss') + '.pdf');

      }, function error(response) {
              $scope.showNotification('Petición fallida: ' + response.message, 'error');
              //console.log( 'La petición no se ha producido: ' + response.message); //lshdebug
      });
  };
/*
      $scope.sendEmailClient = function (row) {
      var index = $scope.presupuestos_historial.indexOf(row);
      if ((index !== -1) && ($scope.presupuestos_historial[index].cliente_email != undefined)) {
          $scope.showNotification('Presupuesto enviada a cliente ' + $scope.presupuestos_historial[index].cliente_nombre + ' (' + $scope.presupuestos_historial[index].cliente_email + ')', 'success');
        } 
      else {
        $scope.showNotification('El cliente no tiene una cuenta de correo asociada', 'error');
      }    
    };
*/
           
    $scope.sendEmailClient = function (row) {
      var index = $scope.presupuestos_historial.indexOf(row);
      if ((index !== -1) && ($scope.presupuestos_historial[index].cliente_email != undefined)) {

        $http({
          method: 'POST',
          url: '/email/presupuesto-to-cliente',
          data: {
            _token: $scope.csfrToken,
            empresa_id: $scope.empresa_id,
            presupuesto_id: row.id,
            cliente_email: row.cliente_email,
            cliente_nombre: row.cliente_nombre,
          },   
          responseType: 'arraybuffer' 
        }).then(function success(response) {
          $scope.showNotification('Presupuesto enviado a cliente ' + $scope.presupuestos_historial[index].cliente_nombre + ' (' + $scope.presupuestos_historial[index].cliente_email + ')', 'success');
        }, function error(response) {
            $scope.showNotification('No se ha podido enviar el email. Por favor, vuelva a intentarlo pasados unos instantes', 'error');
        });
      } 
      else {
        $scope.showNotification('El cliente no tiene una cuenta de correo asociada', 'error');
      }    
    };


    //-------

      //generar tabla de lote de presupuestos en xlsx
      $scope.exportList2xlsx = function ($event) {
         $event.stopImmediatePropagation();
         var genPreExcel = [];
         for (var i=0; i<$scope.$parent.presupuestos_historial.length; i++) { 
            presupuesto = $scope.$parent.presupuestos_historial[i];
            genPreExcel.push($scope.fromFormatoStandardPresupuestosToERP(presupuesto, 'despachos'));
         } 
         $scope.$parent.exportList2xlsx(genPreExcel);
         $scope.showNotification('Descarga de fichero .xlsx correcta', 'success');
      };

    //En función del ERP generar un excel a partir de un objeto con el formato necesario
      $scope.fromFormatoStandardPresupuestosToERP = function(presupuesto, formato_erp) {
        if (presupuesto.total_recargo > 0) {
          var aplicaRecargo = 1;
        } else {
          var aplicaRecargo = 0;
        }

        factor_retenciones = presupuesto.total_retenciones/presupuesto.base_imponible*100;

        switch (formato_erp) {
          case 'despachos':
            var presupuesto_formateado = {

              Lote: presupuesto.idlote,
              Presupuesto: presupuesto.idpresupuesto,
              Fecha: presupuesto.fecha,
              
              'Cliente': presupuesto.cliente_nombre,
              'CIF/DNI': presupuesto.cliente_cif,
              'Concepto': presupuesto.concepto,          
              'Control': presupuesto.control,                    
              '%Retención': number_format_es_ES(presupuesto.retenciones_factor),

              'Base Imponible': number_format_es_ES(presupuesto.base_imponible),
              'Total IVA': number_format_es_ES(presupuesto.total_iva),
              'Total Recargo': number_format_es_ES(presupuesto.total_recargo),
              'Cuota Retención': number_format_es_ES(presupuesto.total_retenciones),          
              'Importe Factura': number_format_es_ES(presupuesto.total_presupuesto),

              'Base Imponible0': number_format_es_ES(presupuesto.base_imponible0),

              'Base Imponible1': number_format_es_ES(presupuesto.base_imponible1),
              'Iva1': '4',
              'Cuota Iva1': number_format_es_ES(presupuesto.base_imponible1 * 0.04),
              '%RecEq1': '0,005',
              'Cuota Rec1': number_format_es_ES(presupuesto.base_imponible1 * 0.005  * aplicaRecargo),

              'Base Imponible2': number_format_es_ES(presupuesto.base_imponible2),
              'Iva2': '10',
              'Cuota Iva2': number_format_es_ES(presupuesto.base_imponible2 * 0.1),
              'RecEq2': '0,014',
              'Cuota Rec2': number_format_es_ES(presupuesto.base_imponible2 * 0.014  * aplicaRecargo),

              'Base Imponible3': number_format_es_ES(presupuesto.base_imponible3),
              'Iva3': '21',
              'Cuota Iva3': number_format_es_ES(presupuesto.base_imponible3 * 0.21),
              'RecEq3': '0,052',
              'Cuota Rec3': number_format_es_ES(presupuesto.base_imponible3 * 0.052  * aplicaRecargo),

              'Observaciones': presupuesto.observaciones,

            }
            break;
        }
        return presupuesto_formateado;
      }

    //-------    

//SUBMIT
  $scope.rangoFechasPresupuestosFormSubmit = function($event)  {
    $event.stopPropagation();

    if ($scope.rangoPresupuestos.inicio > $scope.rangoPresupuestos.final ) {
      $scope.rangoFechasPresupuestosForm.$setSubmitted();
      $scope.showAlert('La fecha de inicio debe ser anterior a la fecha de fin', 'error');
    } else {
      $scope.getPresupuestosFromServer($scope.empresa_id,moment( $scope.rangoPresupuestos.inicio).format('DD/MM/YYYY'), moment( $scope.rangoPresupuestos.final).format('DD/MM/YYYY'));
      $scope.clear_form();
    }
  }

  $scope.clear_form = function () {
      //LIMPIAR ATRIBUTOS DE FORMULARIO
      $scope.rangoFechasPresupuestosForm.$setUntouched();
      $scope.rangoFechasPresupuestosForm.$setPristine();
      $scope.rangoFechasPresupuestosForm.$submitted = false;
  }

 }]);
