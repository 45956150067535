contaApp.controller('clientController', ['$scope', 'toastr', '$http', '$filter', function($scope, toastr, $http, $filter, $visits) {
    
    var controller = $('#clientController');

    $scope.init = function() {

        //Los historiales muestran registros entre el día actual y dias_rango anteriores
        //Este parámetro podemos hacerlo configurable
        $scope.dias_rango = 90;
        $scope.fecha_inicial_emitidas = $scope.fecha_inicial_recibidas = $scope.fecha_inicial_presupuestos = $scope.fecha_inicial_gastos = $scope.fecha_inicial();
        $scope.fecha_final_emitidas = $scope.fecha_final_recibidas = $scope.fecha_final_presupuestos = $scope.fecha_final_gastos = $scope.fecha_final();

        //Peticiones de información al servidor
        $scope.getClientListFromServer($scope.empresa_id);
        $scope.getOperacionesListFromServer($scope.empresa_id);
        $scope.getProveedoresListFromServer($scope.empresa_id);
        $scope.getTrimestralesFromServer($scope.empresa_id);

    //Facturas emitidas
        //Calcumos IDs
        if ($scope.lote_iss == null) {$scope.lote_iss = 0};
        $scope.idLoteIssActual = parseInt($scope.lote_iss);
        if (($scope.lote_iss_sin_enviar == 0) && ($scope.lote_gen_sin_enviar == 0)) {$scope.idLoteIssActual++;}


        //Carga el lote de BBDD si esta por completar
        //ISS
        if ($scope.lote_iss_sin_enviar != 0) {
            $scope.getLoteIssSinEnviarFromServer($scope.empresa_id, $scope.idLoteIssActual, $scope.lote_iss_sin_enviar);
        } else {
            $scope.lote_iss_cargado_sin_enviar = false;
        }

        //GEN
        if ($scope.lote_gen_sin_enviar != 0) {
            $scope.getLoteGenSinEnviarFromServer($scope.empresa_id, $scope.idLoteIssActual, $scope.lote_gen_sin_enviar);
        } else {
            $scope.lote_gen_cargado_sin_enviar = false;
        }

        if ($scope.id_factura_emitida == null) {$scope.id_factura_emitida = 0};
        $scope.idFacturaEmitidaActual = parseInt($scope.id_factura_emitida) + 1;

        //Descargamos facturas emitidas ENVIADAS POR EMAIL O FTP para el historial de facturas
        if ($scope.idFacturaEmitidaActual > 1) { 
          $scope.getFacturasEmitidasFromServer($scope.empresa_id, $scope.fecha_inicial(), $scope.fecha_final());
        } else {
          $scope.facturas_emitidas = [];
          $scope.lotes_facturas_emitidas = [];
        }


    //Presupuestos
        // if ($scope.id_presupuesto == null) {$scope.id_presupuesto = 0}; //Para idPresupuestoActual numerico
        // $scope.idPresupuestoActual = parseInt($scope.id_presupuesto) + 1; //Para idPresupuestoActual numerico

        if ($scope.lote_presupuesto == null) {$scope.lote_presupuesto = 0};
        $scope.idLotePresupuestoActual = parseInt($scope.lote_presupuesto);
        if ($scope.lote_presupuestos_sin_enviar == 0)  {$scope.idLotePresupuestoActual++;}

        if ($scope.lote_presupuestos_sin_enviar != 0) {
            $scope.getLotePresupuestosSinEnviarFromServer($scope.empresa_id, $scope.idLotePresupuestoActual, $scope.lote_presupuestos_sin_enviar);
        } else {
            $scope.lote_presupuestos_cargado_sin_enviar = false;
        }

        //Descargamos presupuestos ENVIADOS POR EMAIL O FTP para el historial de facturas
        if ($scope.cargar_historial_presupuestos) { 
          $scope.getPresupuestosFromServer($scope.empresa_id, $scope.fecha_inicial(), $scope.fecha_final());
        } else {
          $scope.presupuestos_historial = [];
        }


    //Facturas recibidas
        if ($scope.lote_rec == null) {$scope.lote_rec = 0};
        $scope.idLoteRecActual = parseInt($scope.lote_rec);
        if ($scope.lote_rec_sin_enviar == 0)  {$scope.idLoteRecActual++;}


        // if ($scope.id_factura_recibida == null) {$scope.id_factura_recibida = 0};
        // $scope.idFacturaRecibidaActual = parseInt($scope.id_factura_recibida) + 1;

        if ($scope.lote_rec_sin_enviar != 0) {
            $scope.getLoteRecSinEnviarFromServer($scope.empresa_id, $scope.idLoteRecActual, $scope.lote_rec_sin_enviar);
        } else {
            $scope.lote_rec_cargado_sin_enviar = false;
        }

        //Descargamos facturas recibidas ENVIADAS POR EMAIL O FTP para el historial de facturas
        if ($scope.cargar_historial_recibidas) { 
          $scope.getFacturasRecibidasFromServer($scope.empresa_id, $scope.fecha_inicial(), $scope.fecha_final());
        } else {
          $scope.facturas_recibidas = [];
          $scope.lotes_facturas_recibidas = [];
        }

    //Gastos
        if ($scope.lote_gastos == null) {$scope.lote_gastos = 0};
        $scope.idLoteGastosActual = parseInt($scope.lote_gastos);
        if ($scope.lote_gastos_sin_enviar == 0)  {$scope.idLoteGastosActual++;}


        // if ($scope.id_gasto == null) {$scope.id_gasto = 0};
        // $scope.idGastoActual = parseInt($scope.id_gasto) + 1;

        if ($scope.lote_gastos_sin_enviar != 0) {
            $scope.getLoteGastosSinEnviarFromServer($scope.empresa_id, $scope.idLoteGastosActual, $scope.lote_gastos_sin_enviar);
        } else {
            $scope.lote_gastos_cargado_sin_enviar = false;
        }
        //Descargamos facturas gastos ENVIADOS POR EMAIL O FTP para el historial de facturas
        if ($scope.cargar_historial_gastos) { 
          $scope.getGastosFromServer($scope.empresa_id, $scope.fecha_inicial(), $scope.fecha_final());
        } else {
          $scope.gastos_historial = [];
        }

        //próximo lote
        //$scope.lote = "04";
        $scope.loteAltas = "401";
        $scope.loteBajas = "104";

        //por defecto
        $scope.ide = 0;
        //$scope.empresaActiva = $scope.empresas[$scope.ide];
        $scope.empresaActiva = $scope.empresa_activa;

        //opciones de datepicker
        $scope.dateOptions = {
            changeYear: false,
            changeMonth: false,
        };

        $scope.ver_todas_facturas_emitidas = true;
        $scope.ver_todas_facturas_recibidas = true;
        $scope.empreg = [];
        $scope.empUnreg = [];

        $scope.genInv = [];
        $scope.genInvExcel = [];

        $scope.genInvArr = [];
        $scope.recInv = [];
        $scope.gastos = [];
        
        //Facturas emitidas
        $scope.issInv = [];
        $scope.issInvExcel = [];
        
        $scope.genPre = [];
        $scope.employeesList = [];
        $scope.notices = [];
        $scope.singleNotice = [];
        $scope.facturas_emitidas_porlote = [];
        $scope.facturas_recibidas_porlote = [];
        $scope.facturas_emitidas_recibidas_porlote = [];
        $scope.xlsxvisible=false;

        //mensajes de validación
        $scope.defaultMsg = {
            required: "Campo obligatorio",
            email: "Formato de email incorrecto"
        };
        
        $scope.rowCollection = [
            {sentReceived: 'Enviado', sentDate: '12/11/2016', sentBy: 'Asesoria', readDate: '13/11/2016', readBy: 'Usuario', message: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.'},
            {sentReceived: 'Recibido', sentDate: '14/11/2016', sentBy: 'Emilio Díaz', readDate: '', readBy: '', message: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.'},
        ];

        //DATOS VISTAS
        $scope.data = {
            typeInvoiceOptions: [
              {id: 'Operaciones interiores', name: 'Operaciones interiores'},
              {id: 'Adquisiciones Intracom (Bienes)', name: 'Adquisiciones Intracom (Bienes)'},
              {id: 'Importaciones', name: 'Importaciones'},
              {id: 'Operaciones interiores (Bien inversión)', name: 'Operaciones interiores (Bien inversión)'},
              {id: 'Certificaciones', name: 'Certificaciones'},
            ],
            ivaOptions: [
              {id: '0', name: 'Exenta de IVA'},
              {id: '1', name: 'IVA superreducido 4%'},
              {id: '2', name: 'IVA reducido 10%'},
              {id: '3', name: 'IVA general 21%'},
              {id: '4', name: 'Combinado'},
              {id: '5', name: 'IVA superreducido 5%'},
            ],
            accountOptions: [
              {id: '212152 337624 9478384859', name: '212152 337624 9478384859'},
              {id: '653456 217812 32436784567', name: '653456 217812 32436784567'},
            ],
            retencionOptions: [
              {id: 'Profesionales', name: 'Profesionales'},
              {id: 'Alquiler de local', name: 'Alquiler de local'},
              {id: 'No fiscales', name: 'No fiscales'},
              {id: 'Agricultores', name: 'Agricultores'},
              {id: 'Módulos', name: 'Módulos'},
              {id: 'Alquiler de negocio', name: 'Alquiler de negocio'},
            ],
            formasPago: [
              {id: '0', name: 'Transferencia bancaria a 30 días', showname: 'TB30', plazo: '30'},
              {id: '1', name: 'Transferencia bancaria a 60 días', showname: 'TB60', plazo: '60'},
              {id: '2', name: 'Recibo a 30 días', showname: 'R30', plazo: '30'},
              {id: '3', name: 'Recibo a 60 días', showname: 'R60', plazo: '60'},
            ]
        };


            //DUMMY DATA
            $scope.empresaActiva.empleados = [
                {name: 'Elena Cañizo', dni: '92073312B', ss_number: '23/1234567/50', job: 'Secretario',  contract_type: 'Indefinido',  hours: '40', registerDate: '12/10/2014'},
                {name: 'Ángel Reyes', dni: '51307223L', ss_number: '28/4321356/20', job: 'Administrativa',  contract_type: 'Parcial',  hours: '20', registerDate: '22/11/2015'},
                {name: 'Manuel Pedroche', dni: '78719997J', ss_number: '22/2488721/70', job: 'Soldador',  contract_type: 'Indefinido',  hours: '30', registerDate: '02/01/2011'},
                {name: 'Andrés García', dni: '10707430X', ss_number: '23/1234567/50', job: 'Secretario',  contract_type: 'Indefinido',  hours: '40', registerDate: '12/10/2014'},
                {name: 'Raquel Polo', dni: '35255769N', ss_number: '28/4321356/20', job: 'Administrativa',  contract_type: 'Parcial',  hours: '20', registerDate: '22/11/2015'},
                {name: 'Nuria Beltran', dni: '02040387B', ss_number: '22/2488721/70', job: 'Soldador',  contract_type: 'Indefinido',  hours: '30', registerDate: '02/01/2011'},
                {name: 'Emilio Gómez', dni: '23507257S', ss_number: '23/1234567/50', job: 'Secretario',  contract_type: 'Indefinido',  hours: '40', registerDate: '12/07/2014'},
                {name: 'Josefina Casas', dni: '93681389C', ss_number: '28/4321356/20', job: 'Administrativa',  contract_type: 'Parcial',  hours: '20', registerDate: '22/11/2015'},
                {name: 'Luis Pérez', dni: '07448651D', ss_number: '22/2488721/70', job: 'Soldador',  contract_type: 'Indefinido',  hours: '30', registerDate: '02/01/2011'},
            ];
    }



//--- PETICIONES A SERVIDOR COMUNES A TODOS LAS VISTAS HIJAS DE CLIENT

    $scope.getOperacionesListFromServer = function(empresa_id) {
        $http({
        method: 'POST',
        url: '/empresa/operaciones',
        data: {
            _token: $scope.csfrToken, 
            empresa_id: empresa_id,
        }
      }).then(function success(response) {
          $scope.codigoOperacionList =  response.data.operaciones;
      }, function error(response) {
          $scope.showNotification('No se han podido cargar las operaciones de la base da datos ' + response.message, 'error');
      });        
    }


    //SUCCESS -> Carga lotes en $scope.lotes_facturas_emitidas y facturas en $scope.facturas_emitidas
    //ERROR -> Muestra notificación de error
    $scope.getFacturasEmitidasFromServer = function(empresa_id, fecha_inicial, fecha_final) {
        $http({
        method: 'POST',
        url: '/empresa/facturas_emitidas',
        data: {
            _token: $scope.csfrToken, 
            fecha_inicial: fecha_inicial,
            fecha_final: fecha_final,
            empresa_id: empresa_id,
            lote_iss_sin_enviar: $scope.lote_iss_sin_enviar,
            lote_iss_gen_enviar: $scope.lote_iss_gen_enviar,
        }
      }).then(function success(response) {
          $scope.lotes_facturas_emitidas =  response.data.lotes_facturas_emitidas;
          $scope.facturas_emitidas =  response.data.facturas_emitidas;
          $scope.fecha_inicial_emitidas = fecha_inicial;
          $scope.fecha_final_emitidas = fecha_final;
      }, function error(response) {
          $scope.showNotification('No se han podido cargar las facturas emitidas de la base da datos ' + response.message, 'error');
      });        
    }

    //SUCCESS -> Carga lotes en $scope.lotes_facturas_recibidas y facturas en $scope.facturas_recibidas
    //ERROR -> Muestra notificación de error
    $scope.getFacturasRecibidasFromServer = function(empresa_id, fecha_inicial, fecha_final) {
        $http({
        method: 'POST',
        url: '/empresa/facturas_recibidas',
        data: {
            _token: $scope.csfrToken, 
            fecha_inicial: fecha_inicial,
            fecha_final: fecha_final,
            empresa_id: empresa_id,
            lote_rec_sin_enviar: $scope.lote_rec_sin_enviar,
        }
      }).then(function success(response) {
          $scope.lotes_facturas_recibidas =  response.data.lotes_facturas_recibidas;
          $scope.facturas_recibidas =  response.data.facturas_recibidas;
          $scope.fecha_inicial_recibidas = fecha_inicial;
          $scope.fecha_final_recibidas = fecha_final;
      }, function error(response) {
          $scope.showNotification('No se han podido cargar las facturas recibidas de la base da datos ' + response.message, 'error');
      });        
    }


    //SUCCESS -> Carga presupuestos en $scope.presupuestos_historial
    //ERROR -> Muestra notificación de error
    $scope.getPresupuestosFromServer = function(empresa_id, fecha_inicial, fecha_final) {
        $http({
        method: 'POST',
        url: '/empresa/presupuestos',
        data: {
            _token: $scope.csfrToken, 
            fecha_inicial: fecha_inicial,
            fecha_final: fecha_final,
            empresa_id: empresa_id,
            lote_presupuestos_sin_enviar: $scope.lote_presupuestos_sin_enviar,
        }
      }).then(function success(response) {
          $scope.presupuestos_historial =  response.data.presupuestos_historial;
          $scope.fecha_inicial_presupuestos = fecha_inicial;
          $scope.fecha_final_presupuestos = fecha_final;
      }, function error(response) {
          $scope.showNotification('No se han podido cargar los presupuestos de la base da datos ' + response.message, 'error');
      });        
    }

    //SUCCESS -> Carga presupuestos en $scope.presupuestos_historial
    //ERROR -> Muestra notificación de error
    $scope.getGastosFromServer = function(empresa_id, fecha_inicial, fecha_final) {
        $http({
        method: 'POST',
        url: '/empresa/gastos',
        data: {
            _token: $scope.csfrToken, 
            fecha_inicial: fecha_inicial,
            fecha_final: fecha_final,
            empresa_id: empresa_id,
            lote_gastos_sin_enviar: $scope.lote_gastos_sin_enviar,
        }
      }).then(function success(response) {
          $scope.gastos_historial =  response.data.gastos_historial;
          $scope.fecha_inicial_gastos = fecha_inicial;
          $scope.fecha_final_gastos = fecha_final;
      }, function error(response) {
          $scope.showNotification('No se han podido cargar los gastos de la base da datos ' + response.message, 'error');
      });        
    }
    //SUCCESS -> Lista de clientes por empresa en $scope.clienteList
    //ERROR -> Muestra notificación de error
    $scope.getClientListFromServer = function(empresa_id) {
        $http({
        method: 'POST',
        url: '/empresa/clientes',
        data: {
            _token: $scope.csfrToken, 
            empresa_id: empresa_id,
        }
      }).then(function success(response) {
          $scope.clienteList =  response.data.clientes;
      }, function error(response) {
          $scope.showNotification('No se han podido cargar los clientes de la base da datos ' + response.message, 'error');
      });        
    }

    //SUCCESS -> Lista de clientes por empresa en $scope.clienteList
    //ERROR -> Muestra notificación de error
    $scope.getProveedoresListFromServer = function(empresa_id) {
        $http({
        method: 'POST',
        url: '/empresa/proveedores',
        data: {
            _token: $scope.csfrToken, 
            empresa_id: empresa_id,
        }
      }).then(function success(response) {
          $scope.proveedorList =  response.data.proveedores;
      }, function error(response) {
          $scope.showNotification('No se han podido cargar los proveedores de la base da datos ' + response.message, 'error');
      });        
    }

    //idlote es el id del lote para la empresa
    //lote_id es el id clave de la tabla en BBDD
    $scope.getLoteIssSinEnviarFromServer = function(empresa_id, idlote, lote_id) {
        $http({
        method: 'POST',
        url: '/empresa/emitidas-sin-enviar',
        data: {
            _token: $scope.csfrToken, 
            empresa_id: empresa_id,
            lote_id: lote_id,
        }
      }).then(function success(response) {

          $scope.lote_iss_cargado_sin_enviar = true;

          $scope.issInv = response.data.emitidas_sin_enviar;

          for (var i=0; i<$scope.issInv.length; i++) { 
            factura = $scope.issInv[i];
            consola( 'factura',  factura)
            $scope.issInvExcel.push($scope.fromFormatoStandardEmitidasToERP(factura, 'despachos'));
          } 

          $scope.showNotification('Pendiente de envio el lote ' + idlote +' de facturas emitidas', 'info');
      }, function error(response) {
          $scope.showNotification('Lote de facturas emitidas no recuperado ' + response.message, 'error');
      });        
    }

    $scope.getLoteGenSinEnviarFromServer = function(empresa_id, idlote, lote_id) {
        $http({
        method: 'POST',
        url: '/empresa/emitidas-sin-enviar',
        data: {
            _token: $scope.csfrToken, 
            empresa_id: empresa_id,
            lote_id: lote_id,
        }
      }).then(function success(response) {

          $scope.lote_gen_cargado_sin_enviar = true; 

          $scope.genInv = response.data.emitidas_sin_enviar;
          $scope.showNotification('Pendiente de envio el lote ' + idlote +' de facturas emitidas (sin operaciones)', 'info');
      }, function error(response) {
          $scope.showNotification('Lote de facturas emitidas no recuperado ' + response.message, 'error');
      });        
    }

    $scope.getLotePresupuestosSinEnviarFromServer = function(empresa_id,  idlote, lote_id) {
        $http({
        method: 'POST',
        url: '/empresa/presupuestos-sin-enviar',
        data: {
            _token: $scope.csfrToken, 
            empresa_id: empresa_id,
            lote_id: lote_id,
        }
      }).then(function success(response) {

          $scope.lote_presupuestos_cargado_sin_enviar = true;

          $scope.genPre = response.data.presupuestos_sin_enviar;
          $scope.showNotification('Pendiente de envio el lote ' + idlote +' de presupuestos', 'info');
      }, function error(response) {
          $scope.showNotification('Lote de presupuestos no recuperado ' + response.message, 'error');
      });        
    }

    $scope.getLoteRecSinEnviarFromServer = function(empresa_id,  idlote, lote_id) {
        $http({
        method: 'POST',
        url: '/empresa/recibidas-sin-enviar',
        data: {
            _token: $scope.csfrToken, 
            empresa_id: empresa_id,
            lote_id: lote_id,
        }
      }).then(function success(response) {

          $scope.lote_rec_cargado_sin_enviar = true;

          $scope.recInv = response.data.recibidas_sin_enviar;
          $scope.showNotification('Pendiente de envio el lote ' + idlote +' de facturas de proveedores', 'info');
      }, function error(response) {
          $scope.showNotification('Lote de facturas recibidas no recuperado ' + response.message, 'error');
      });        
    }

    $scope.getLoteGastosSinEnviarFromServer = function(empresa_id, idlote, lote_id) {
        $http({
        method: 'POST',
        url: '/empresa/gastos-sin-enviar',
        data: {
            _token: $scope.csfrToken, 
            empresa_id: empresa_id,
            lote_id: lote_id,
        }
      }).then(function success(response) {

          $scope.lote_gastos_cargado_sin_enviar = true;

          $scope.gastos = response.data.gastos_sin_enviar;
          $scope.showNotification('Pendiente de envio el lote ' + idlote +' de gastos', 'info');
      }, function error(response) {
          $scope.showNotification('Lote de gastos no recuperado ' + response.message, 'error');
      });        
    }


    //SUCCESS -> Lista de clientes por empresa en $scope.clienteList
    //ERROR -> Muestra notificación de error
    $scope.getTrimestralesFromServer = function(empresa_id) {
        $http({
        method: 'POST',
        url: '/empresa/trimestrales',
        data: {
            _token: $scope.csfrToken, 
            empresa_id: empresa_id,
        }
      }).then(function success(response) {
          $scope.trimestralRecibidas = response.data.trimestrales_recibidas;
          $scope.trimestralEmitidas =  response.data.trimestrales_emitidas;
          //{periodo: "2016 - 4T", base: "15678", iva: "2947.46", total: "18625.46"}
      }, function error(response) {
          $scope.showNotification('No se han podido obtener los trimestres de la base da datos ' + response.message, 'error');
      });        
    }


    $scope.insertClientToServer = function(cliente_nuevo) {
    //nuevo cliente: {nombre_cliente, cif_cliente, email, direccion}
        $http({
            method: 'POST',
            url: '/empresa/insertar-cliente',
            data: {
                _token: $scope.csfrToken,
                empresa_id: $scope.empresa_id,
                cliente_nuevo: cliente_nuevo,
            }
        }).then(function success(response) {
            $scope.showNotification(cliente_nuevo.nombre_cliente + ' guardado en su lista de clientes', 'success');
        }, function error(response) {
            $scope.showNotification('No se han podido guardar los datos del cliente ' + cliente_nuevo.nombre_cliente, 'error');
        });        
    }

    $scope.insertProveedorToServer = function(proveedor_nuevo) {
    //nuevo proveedor: {nombre_proveedor, cif_proveedor, email, direccion}
        $http({
            method: 'POST',
            url: '/empresa/insertar-proveedor',
            data: {
                _token: $scope.csfrToken,
                empresa_id: $scope.empresa_id,
                proveedor_nuevo: proveedor_nuevo,
            }
        }).then(function success(response) {
            $scope.showNotification(proveedor_nuevo.nombre_proveedor + ' guardado en su lista de proveedores', 'success');
        }, function error(response) {
            $scope.showNotification('No se han podido guardar los datos del proveedor ' + proveedor_nuevo.nombre_proveedor, 'error');
        });        
    }

    $scope.insertOperacionToServer = function(operacion_nueva) {
    //nuevo cliente: {nombre_cliente, cif_cliente, email, direccion}
        $http({
            method: 'POST',
            url: '/empresa/insertar-operacion',
            data: {
                _token: $scope.csfrToken,
                empresa_id: $scope.empresa_id,
                operacion_nueva: operacion_nueva,
            }
        }).then(function success(response) {
            $scope.showNotification('La operación ' + operacion_nueva.codigo_operacion + ' se ha guardado en su lista de operaciones', 'success');
        }, function error(response) {
            $scope.showNotification('No se han podido guardar la operación ' + operacion_nueva.codigo_operacion, 'error');
        });        
    }

    $scope.updateClientToServer = function(cliente_actualizado) {
    //cliente actualizado: {nombre_cliente, cif_cliente, email, direccion}
        $http({
            method: 'POST',
            url: '/empresa/actualizar-cliente',
            data: {
                _token: $scope.csfrToken,
                empresa_id: $scope.empresa_id,
                cliente_actualizado: cliente_actualizado,
            }
        }).then(function success(response) {
            $scope.showNotification(cliente_actualizado.nombre_cliente + ' modificado en su lista de clientes', 'success');
        }, function error(response) {
            $scope.showNotification('No se han podido modificar los datos del cliente ' + cliente_actualizado.nombre_cliente, 'error');
        });        
    }

    $scope.updateProveedorToServer = function(proveedor_actualizado) {
    //cliente actualizado: {nombre_cliente, cif_cliente, email, direccion}
        $http({
            method: 'POST',
            url: '/empresa/actualizar-proveedor',
            data: {
                _token: $scope.csfrToken,
                empresa_id: $scope.empresa_id,
                proveedor_actualizado: proveedor_actualizado,
            }
        }).then(function success(response) {
            $scope.showNotification(proveedor_actualizado.nombre_proveedor + ' modificado en su lista de proveedores', 'success');
        }, function error(response) {
            $scope.showNotification('No se han podido modificar los datos del proveedor ' + proveedor_actualizado.nombre_proveedor, 'error');
        });        
    }

//--- ESCUCHA Y PROPAGACIÓN DE EVENTOS
    $scope.$on('cargarPresupuestoEnFactura', function(event, args) {
      //event.stopPropagation();
      $scope.$broadcast('cargarPresupuestoEnFacturaHijo', args);
    });

    $scope.$on('cargarPresupuestoEnPresupuesto', function(event, args) {
      //event.stopPropagation();
      $scope.$broadcast('cargarPresupuestoEnPresupuestoHijo', args);
    });

    $scope.$on('actualizarNumeroFactura', function(event, args) {
      //event.stopPropagation();
      $scope.$broadcast('actualizarNumeroFacturaHijo', args);
    });

    $scope.$on('actualizarIdLoteIssActual', function(event, args) {
      //event.stopPropagation();
      $scope.$broadcast('actualizarIdLoteIssActualHijo', args);
    });

    $scope.$on('actualizarPrefijoSufijo', function(event, args) {
      $scope.$broadcast('actualizarPrefijoSufijoHijo', args);
    });


//--- GESTIÓN DE VISTAS

    //función para mostrar panel en vista
    $scope.mostrarVista = function (vista) {
        $scope.vista_activa = vista;
    };

    //función para mostrar panel en vista en una tab determinada
    $scope.mostrarVistaTab = function (vista, tab) {
        $scope.vista_activa = vista;
        $('#' + vista +' a[href="#'+tab+'"]').tab('show')
    };

    //función para active en menu si la vista esta activa (o la de uno de sus hijos)
    $scope.claseEntradaMenu = function (vistas) {
        for (i in vistas) {
            if ($scope.vista_activa == vistas[i] ) {
                return 'active';
            }
        }
        return '';
    };

    //función para active en menu si la vista esta activa
    $scope.disponible = function () {
        return 'disponible';
    };

    //función para publicar mensajes en panel
    $scope.showNotification = function (text, type) {

        //damos formato en función del tipo de alerta
        switch (type) {
        case 'success':
            toastr.success(text);
            break;
        case 'success-top':
            toastr.success(text);
            window.scrollTo(0, 0);
            break;
        case 'info':
            toastr.info(text);
            break;
        case 'error':
            toastr.error(text, 
            {
                closeButton: true
            });
            break;
        }
    };


    //función para publicar mensajes en panel
    $scope.showAlert = function (text, type) {

        //damos formato en función del tipo de alerta
        switch (type) {
        case 'success':
            break;
        case 'error':
            toastr.error(text, 
            {
                closeButton: true
            });
            break;
        }

        window.scrollTo(0, 0);
    };


    $scope.calcular_idfactura = function () {
      return $scope.idFacturaEmitidaActual;
    }
    
    //funcion para cerrar Alert Area por botón
    $scope.closeAlertArea = function () {
        //limpiamos el area de alertas
        $('#alert-area').removeClass('alert-success').removeClass('alert-danger');
        $('#alert-icon').removeClass('fa-check').removeClass('fa-times-circle');
        //ocultamos area
        $('#alert-area').addClass('hidden');
    };
    

//--- FUNCIONES COMUNES PARA TODAS LAS VISTAS HIJAS DEL SCOPE CLIENT

    $scope.exportList2xlsx = function (list) {
        if (list.length > 0) {
            exportXlsxFile( list, $scope.empresaActiva.nombre + '_' + moment(Date.now()).format('YYYY-MM-DD_HH-mm-ss') );
        }
    };

    $scope.exportList2xlsx_blob = function (list) {
        if (list.length > 0) {
            return exportXlsxBlob( list, $scope.empresaActiva.nombre + '_' + moment(Date.now()).format('YYYY-MM-DD_HH-mm-ss') );
        }
    };


    $scope.appFormulario = function(empresa_id) {
        post('/app-formulario', {empresa_id: empresa_id, vista: "issueInvoiceView",  _token: getCsrfToken() });
    }
    
  //Para enlazaar con documentos
  $scope.appDocumentos = function(empresa_id) {
      post('/app-documentos', {empresa_id: empresa_id, _token: getCsrfToken() });
  }

//rangos de fechas
  $scope.fecha_inicial = function() {
    return moment().subtract($scope.dias_rango, "days").format('DD/MM/YYYY');
  }

  $scope.fecha_final = function() {
    return moment().format('DD/MM/YYYY');
  }

  //"ÑAPA" PORQUE AL RELACIÓN JQUERY ANGULAR ME CAMBIA A VECES EL VALOR MOMENT A STRING
  $scope.convertToMoment = function (fecha) {
    consola("fecha", fecha);
    if (typeof fecha == "string") {
      consola("fecha out", moment(fecha, 'DD/MM/YYYY'));
      return moment(fecha, 'DD/MM/YYYY');
    } else {
      consola("fecha out", fecha);
      fecha_insert =  fecha;
    }
  }

$scope.fromFormatoStandardEmitidasToERP = function(factura, formato_erp) {
    
    if (factura.total_recargo > 0) {
      var aplicaRecargo = 1;
    } else {
      var aplicaRecargo = 0;
    }

    factor_retenciones = factura.total_retenciones/factura.base_imponible*100;

    switch (formato_erp) {
      case 'despachos':
        var factura_formateada = {
          Serie: moment(factura.fecha,'DD/MM/YYYY').format('YY'),
          Lote: factura.idlote,
          Factura: factura.prefijo + factura.idfactura + factura.sufijo,
          Fecha: factura.fecha,
          'CIF/DNI': factura.cliente_cif,
          'Cliente': factura.cliente_nombre,
          'Concepto Ingreso': factura.concepto,
          'Importe Factura': factura.total_factura,
          'Base Imponible1': factura.base_imponible3,
          '%Iva1': '21',
          'Cuota Iva1': factura.base_imponible3 * 0.21,
          '%RecEq1': 0.052 * aplicaRecargo * 100,
          'Cuota Rec1': factura.base_imponible3 * 0.052  * aplicaRecargo,
          'Base ret': factura.base_imponible,
          '%Retención': factor_retenciones.toString(),
          'Cuota Retención': factura.total_retenciones,
          'Base Imponible2': factura.base_imponible2,
          '%Iva2': '10',
          'Cuota Iva2': factura.base_imponible2 * 0.1,
          '%RecEq2': 0.014 * aplicaRecargo * 100,
          'Cuota Rec2': factura.base_imponible2 * 0.014  * aplicaRecargo,
          'Base Imponible3': factura.base_imponible1,
          '%Iva3': '4',
          'Cuota Iva3': factura.base_imponible1 * 0.04,
          '%RecEq3': 0.005 * aplicaRecargo * 100,
          'Cuota Rec3': factura.base_imponible1 * 0.005  * aplicaRecargo,
          'Periodo': moment(factura.fecha,'DD/MM/YYYY').format('MM'),
        }
        break;
    }
    return factura_formateada;
}

  $scope.init();

}]);

//--- FUNCIONES AUXILIARES PARA DEPURACIÓN
  consola = function(texto, var1) {
    console.log("--------" + texto);
    console.log(var1);
    console.log("--------");
  }

//Entrada
//facturas[i]
//$scope.trimestralEmitidas
consolidar_factura_historial = function(factura, historial_por_trimestres){
      //meter en historico  //{periodo: "2016 - 4T", base: "15678", iva: "2947.46", total: "18625.46"}
    var fecha = moment(factura.fecha, 'DD/MM/YYYY');
    var trimestre = Math.floor(fecha.format('M') / 4)+ 1;
    var periodo = fecha.format('YYYY') + ' - ' + trimestre + 'T';
    var consolidado = false;
    var base = parseFloat(factura.base_imponible || 0);
    var iva = parseFloat(factura.total_iva || 0);
    var total =  parseFloat(factura.total_factura || 0);
    for (var j = 0; j < historial_por_trimestres.length; j++) {
                  consola(periodo, historial_por_trimestres[j].periodo);

      if  (historial_por_trimestres[j].periodo == periodo) {
        consolidado = true;
        historial_por_trimestres[j].base += base;
        historial_por_trimestres[j].iva += iva;
        historial_por_trimestres[j].total += total;
      }
    }
    if (!consolidado) {
      historial_por_trimestres.push({'periodo': periodo, 'base': base, 'iva': iva, 'total': total});
    }
    
}


function post(path, params, method) {
    method = method || "post"; // Set method to post by default if not specified.

    // The rest of this code assumes you are not using a library.
    // It can be made less wordy if you use one.
    var form = document.createElement("form");
    form.setAttribute("method", method);
    form.setAttribute("action", path);

    for(var key in params) {
        if(params.hasOwnProperty(key)) {
            var hiddenField = document.createElement("input");
            hiddenField.setAttribute("type", "hidden");
            hiddenField.setAttribute("name", key);
            hiddenField.setAttribute("value", params[key]);

            form.appendChild(hiddenField);
         }
    }

    document.body.appendChild(form);
    form.submit();
}
function getCsrfToken() { 
   var metas = document.getElementsByTagName('meta'); 

   for (var i=0; i<metas.length; i++) { 
      if (metas[i].getAttribute("name") == "csrf-token") { 
         return metas[i].getAttribute("content"); 
      } 
   } 
    return "";
} 

