/**
 * Created by agf on 25/03/2017.
 */
contaApp.service('RemoteFilesService', ['$http', function ($http) {
    this.getIndexedFiles = function (idEmpresaActiva, selectedPath) {
        return $http.post('/easydocs/list-all', {
            folder: selectedPath,
            empresa_id: idEmpresaActiva
        }).then(function (res) {
            return res.data;
        });
    };
    this.loadFolderContent = function (idEmpresaActiva, selectedPath) {
        return $http.post('/easydocs/list', {folder: selectedPath, empresa_id: idEmpresaActiva}).then(function (res) {
            var results = [];
            angular.forEach(res.data, function (v, k) {
                console.log(k);
                if (/\:/.test(v.time)) v.time = moment().format('YYYY') + ' ' + v.time;
                results.push({
                    display_name: k,
                    type: v.type,
                    size: v.size,
                    date: v.day + '/' + v.month + '/' + v.time
                })
            });
            return results;
        });
    };
    this.downloadFile = function (idEmpresaActiva, selectedPath) {
        return $http.post('/easydocs/get', {
            file: selectedPath,
            empresa_id: idEmpresaActiva
        }, {responseType: 'arraybuffer'}).then(function (res) {
            //console.log("AQUI");
            console.log(res);
            console.log(res.headers('Content-Type'));
            var name = selectedPath.split('/');
            var type = res.headers('Content-Type');
            //saveAs(new Blob([res.data], {type: "application/octet-stream"}), name[name.length - 1])
            saveAs(new Blob([res.data], {type: type}), name[name.length - 1])
        });
    };
    this.listFoldersAdmin = function () {
        return $http.post('/easydocs/list', {folder: '.'}).then(function (res) {
            var results = [];
            angular.forEach(res.data, function (v, k) {
                console.log(k);
                if (v.type != 'file') results.push({
                    display_name: k,
                    type: v.type,
                    size: v.size,
                    date: v.day + '/' + v.month + '/' + v.time
                });
            });
            return results;
        });
    }
}]);