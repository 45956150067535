contaApp.controller('GenerarPresupuestoController', ['$scope', '$http', '$filter', function($scope, $http, $filter, $visits) {

  $scope.listaImprimible = [];

  $scope.data = {
  typePresupuestoOptions: [
    {id: 'Operaciones interiores', name: 'Operaciones interiores'},
    {id: 'Adquisiciones Intracom (Bienes)', name: 'Adquisiciones Intracom (Bienes)'},
    {id: 'Importaciones', name: 'Importaciones'},
    {id: 'Operaciones interiores (Bien inversión)', name: 'Operaciones interiores (Bien inversión)'},
    {id: 'Certificaciones', name: 'Certificaciones'},
  ],
  ivaOptions: [
    {id: '0', name: 'Exento de IVA', showname: '0%', factor: '0'},
    {id: '1', name: 'IVA 4%', showname: '4%', factor: '0.04'},
    {id: '2', name: 'IVA 10%', showname: '10%', factor: '0.10'},
    {id: '3', name: 'IVA 21%', showname: '21%', factor: '0.21'},
    {id: '5', name: 'IVA 5%', showname: '5%', factor: '0.05'},
  ],
  formasPago: [
    {id: '0', name: 'Transferencia bancaria a 30 días', showname: 'TB30', plazo: '30'},
    {id: '1', name: 'Transferencia bancaria a 60 días', showname: 'TB60', plazo: '60'},
    {id: '2', name: 'Recibo a 30 días', showname: 'R30', plazo: '30'},
    {id: '3', name: 'Recibo a 60 días', showname: 'R60', plazo: '60'},
    {id: '4', name: 'Al contado', showname: 'CO', plazo: '30'},
  ],
  vencimientoPresupuesto: [
    {id: '0', name: '7 días', showname: '7', plazo: '7'},
    {id: '1', name: '15 días', showname: '15', plazo: '15'},
    {id: '2', name: '30 días', showname: '30', plazo: '30'},
    {id: '3', name: '60 días', showname: '60', plazo: '60'},
  ]
 };

  $scope.saveClientVisible = false;
  $scope.saveChangesVisible = false;

  $scope.saveClientShow = function() {
    if ($scope.newGenPre.cif != undefined) {
        if (!cifResgister()) {
          $scope.saveClientVisible = true;
          return true;
        } else {
          $scope.saveClientVisible = false;
          return  false;
        }
      }
  };

  $scope.saveChangesShow = function() {
    if ($scope.newGenPre.cif != undefined) {
      if (cifResgister() && clientIsChanged()) {
        $scope.saveChangesVisible = true;
        return true;
      } else {
        $scope.saveChangesVisible = false;
        return  false;
      }
    }
  };

  function cifResgister() {
    var cifInList = false;
    for (i in $scope.clienteList) 
    {
      if ($scope.newGenPre.cif == $scope.clienteList[i].cif_cliente ) {
        cifInList = true;
      }
    }
    return cifInList;  
  } 
  
   // Search employee by name
    $('#cliente_proveedor-presupuesto').autocomplete({
        source: function (request, response) {
            var term = request.term;
            var data = clienteAutoComplete(term, $scope);
            response(data);
        },
        minLength: 1,
        focus: function (event, ui) {
            //$(this).val(ui.item.cif_cliente + ' - ' + ui.item.nombre_cliente);
            return false;
        },
        select: function (event, ui) {
            $(this).val(ui.item.nombre_cliente);
            $scope.newGenPre.cif = ui.item.cif_cliente;
            $scope.newGenPre.name = ui.item.nombre_cliente;
            $scope.newGenPre.email = ui.item.email;
            $scope.newGenPre.direccion = ui.item.direccion;
            $scope.$digest();
            return false;
        },
        messages: {
            noResults: '',
            results: function () {
            }
        }
    }).autocomplete('instance')._renderItem = function (ul, item) {
        return $("<li>")
          .append("<span class='ac-option'>" + item.cif_cliente + " - " + item.nombre_cliente + "</span>")
          .appendTo(ul);
    };

    function clienteAutoComplete(term, $scope) {
        return $.map($scope.clienteList, function (item) {
            if (item.nombre_cliente.match(term.toUpperCase()) || item.cif_cliente.match(term)) {
                return item;
            }
            return null;
        });
    };

//buscar operación por código
    $scope.saveCodVisible = false;

    $('#codigo_operacion-presupuesto').focusout(function () {
      if ($scope.newConceptInv.codigo != undefined) {
          $scope.saveCodVisible = !codResgister();
          $scope.$digest();
        }
    }); 

    $('#descripcion_operacion-presupuesto').focusout(function () {
      if ($scope.newConceptInv.codigo != undefined) {
          $scope.saveCodVisible = !codResgister();
          $scope.$digest();
        }
    });  

    function codResgister() {
      var codInList = false;
      for (i in $scope.codigoOperacionList) 
      {
        if ($scope.newConceptInv.codigo == $scope.codigoOperacionList[i].codigo_operacion ) {
          codInList = true;
        }
      }
      return codInList;  
    } 

    $('#codigo_operacion-presupuesto').autocomplete({
        source: function (request, response) {
            var term = request.term;
            var data = operacionAutoComplete(term, $scope);
            response(data);
        },
        minLength: 1,
        focus: function (event, ui) {
            //$(this).val(ui.item.codigo_operacion + ' - ' + ui.item.descripcion_operacion);
            return false;
        },
        select: function (event, ui) {
            $(this).val(ui.item.nombre_cliente);
            $scope.newConceptInv.codigo = ui.item.codigo_operacion;
            $scope.newConceptInv.descripcion = ui.item.descripcion_operacion;
            $scope.newConceptInv.importe = ui.item.importe.toString().trim().replace(".",",");
            $scope.$digest();
            return false;
        },
        messages: {
            noResults: '',
            results: function () {
            }
        }
    }).autocomplete('instance')._renderItem = function (ul, item) {
        return $("<li>")
            .append("<span class='ac-option'>" + item.codigo_operacion + " - " + item.descripcion_operacion + "</span>")
            .appendTo(ul);
    };

    function operacionAutoComplete(term, $scope) {
        return $.map($scope.codigoOperacionList, function (item) {
            if (item.codigo_operacion.match(term) || item.descripcion_operacion.match(term.toUpperCase())) {
                return item;
            }
            return null;
        });
    };

//guardar cambios en usuario 

  function clientIsChanged() {
    client = getClient($scope.newGenPre.cif);
    if (client[0] != undefined) {
      return ((client[0].nombre_cliente != $scope.newGenPre.name) || (client[0].email != $scope.newGenPre.email) || (client[0].direccion != $scope.newGenPre.direccion));
    } else {
      return false;
    }
  }

  function getClient(cif) {
    return $.map($scope.clienteList, function (item) {
      if (item.cif_cliente == cif) {
          return item;
      }
      return null;
    });
  }

  function updateClient() {
    return $.map($scope.clienteList, function (item) {
      if (item.cif_cliente == $scope.newGenPre.cif) {

          //local
          item.nombre_cliente = $scope.newGenPre.name;
          item.email = $scope.newGenPre.email;
          item.direccion = $scope.newGenPre.direccion;

          //servidor
          $scope.updateClientToServer({nombre_cliente: $scope.newGenPre.name.toUpperCase(), cif_cliente: $scope.newGenPre.cif, email: $scope.newGenPre.email, direccion: $scope.newGenPre.direccion });
          return true;
      }
      return false;
    });
  }

//calcular valores en formulario  

  
  $scope.fecha_hoy = function() {
    $( "#dateinput-presupesto" ).datepicker( "setDate", moment().format('DD/MM/YYYY') );
    return moment().format('DD/MM/YYYY');
  }

  $scope.calcular_base = function() {
    numConceptos = $scope.conceptInv.length;
    var base_acumulado = 0;
    var importe, descuento, cantidad;

    if ( numConceptos > 0 ) {
      for (i = 0; i < numConceptos; i++) {
        importe = parseFloat($scope.conceptInv[i].importe || 0);
        descuento =  parseFloat($scope.conceptInv[i].descuento * 0.01 || 0);
        cantidad =  parseFloat($scope.conceptInv[i].cantidad || 0);
        base_acumulado += parseFloat(importe * cantidad * (1-descuento) || 0);
      }
      $scope.newGenPre.base = Math.round(base_acumulado * 100) / 100 || null;
      return Math.round(base_acumulado * 100) / 100 || null;
    } else {
      return '';
    }
  }

  $scope.calcular_iva = function() {
    numConceptos = $scope.conceptInv.length;
    var iva_acumulado = 0;
    var importe, descuento, cantidad;

    if ( numConceptos > 0 ) {
      for (i = 0; i < numConceptos; i++) {
        importe = parseFloat($scope.conceptInv[i].importe || 0);
        descuento =  parseFloat($scope.conceptInv[i].descuento.toString().trim().replace(",",".") * 0.01 || 0);
        cantidad =  parseFloat($scope.conceptInv[i].cantidad || 0);
        switch ($scope.conceptInv[i].iva_concepto) {
          case "1": iva_acumulado += parseFloat(importe * cantidad * (1-descuento) * 0.04 || 0); break;
          case "2": iva_acumulado += parseFloat(importe * cantidad * (1-descuento) * 0.1 || 0); break;
          case "3": iva_acumulado += parseFloat(importe * cantidad * (1-descuento) * 0.21 || 0); break; 
        }  
      }
      $scope.newGenPre.total_iva =  Math.round(iva_acumulado * 100) / 100 || 0;
      return Math.round(iva_acumulado * 100) / 100 || 0;
    } else {
      return '';
    }
  }


  $scope.calcular_base_iva = function(tipo_iva) {
    numConceptos = $scope.conceptInv.length;
    var base_acumulada = 0;
    var importe, descuento, cantidad;

    if ( numConceptos > 0 ) {
      for (i = 0; i < numConceptos; i++) {
        if ($scope.conceptInv[i].iva_concepto == tipo_iva) {
          importe = parseFloat($scope.conceptInv[i].importe || 0);
          descuento =  parseFloat($scope.conceptInv[i].descuento * 0.01 || 0);
          cantidad =  parseFloat($scope.conceptInv[i].cantidad || 0);
          base_acumulada += parseFloat(importe * cantidad * (1-descuento)  || 0);
        }
      }
      return Math.round(base_acumulada * 100) / 100 || null;
    } else {
      return 0;
    }
  }  

  //calcular total de operación en formulario
  $scope.calcular_total_operacion = function() {
    if ( $scope.newConceptInv.importe != null) {
      var importe =  parseFloat($scope.newConceptInv.importe.toString().trim().replace(",",".") || 0);
    }
    if ( $scope.newConceptInv.cantidad != null) {
      var cantidad  =  parseFloat($scope.newConceptInv.cantidad.toString().trim().replace(",",".") || 0);
    }
    var descuento = parseFloat($scope.newConceptInv.descuento.toString().trim().replace(",",".") * 0.01 || 0);
    var iva = parseFloat($scope.newConceptInv.iva_concepto.factor || 0);
    $scope.newConceptInv.total_operacion = Math.round(parseFloat(importe * cantidad * (1 - descuento) * (1 + iva) || 0) * 100) / 100 || null;
    return Math.round(parseFloat(importe * cantidad * (1 - descuento) * (1 + iva) || 0) * 100) / 100 || null;
  }

  $scope.calcular_total = function() {
    numConceptos = $scope.conceptInv.length;
    var total_acumulado = 0;
    var importe, descuento, cantidad;

    if ( numConceptos > 0 ) {
      for (i = 0; i < numConceptos; i++) {
        importe = parseFloat($scope.conceptInv[i].importe || 0);
        descuento =  parseFloat($scope.conceptInv[i].descuento * 0.01 || 0);
        cantidad =  parseFloat($scope.conceptInv[i].cantidad || 0);
        switch ($scope.conceptInv[i].iva_concepto) {
          case "0": total_acumulado += parseFloat(importe * cantidad * (1-descuento) * 1 || 0); break;
          case "1": total_acumulado += parseFloat(importe * cantidad * (1-descuento) * 1.04 || 0); break;
          case "2": total_acumulado += parseFloat(importe * cantidad * (1-descuento) * 1.1 || 0); break;
          case "3": total_acumulado += parseFloat(importe * cantidad * (1-descuento) * 1.21 || 0); break; 
        }  
      }
      $scope.newGenPre.total_presupuesto = Math.round(total_acumulado * 100) / 100 || null;
      return Math.round(total_acumulado * 100) / 100 || null;
    } else {
      return '';
    }
  }
    
  $scope.pdfPresupuesto = function ($event) {
    $event.stopPropagation();
          $http({
          method: 'POST',
          url: '/pdf/imprimir-presupuesto',
          data: {_token: $scope.csfrToken, datos_presupuesto: $scope.newGenPre, conceptos: $scope.conceptInv, datos_emisor: $scope.datosEmisor},
          responseType: 'arraybuffer' 
      }).then(function success(response) {
           var file = new Blob([response.data], {type: 'application/pdf'});
           saveAs(file, 'presupuesto.pdf');

      }, function error(response) {
              $scope.showNotification('Petición fallida: ' + response.message, 'error');
      });
  };

  $scope.pdfPresupuestoSingle = function (row) {
          $http({
          method: 'POST',
          url: '/pdf/imprimir-presupuesto',
          data: {_token: $scope.csfrToken, datos_presupuesto: row, datos_emisor: $scope.empresaActiva},
          responseType: 'arraybuffer' 
      }).then(function success(response) {
           var file = new Blob([response.data], {type: 'application/pdf'});
           saveAs(file, 'presupuesto_' + $scope.empresaActiva.nombre + '_' + moment(Date.now()).format('YYYY-MM-DD_HH-mm-ss') + '.pdf');

      }, function error(response) {
              $scope.showNotification('Petición fallida: ' + response.message, 'error');
              //console.log( 'La petición no se ha producido: ' + response.message); //lshdebug
      });
  };


  $scope.pdfPresupuestoAll = function ($event, numPresupuestos) {
    $event.stopPropagation();
      for (i = 0; i < numPresupuestos; i++) {
       $scope.pdfPresupuestoSingle($scope.genPre[i]);
      }
  };

    $scope.conceptInv = [];

    $scope.newPresupuestoConcept = function () {
      //guardar código de operación si esta visible y marcado para guardar
      if (($scope.saveCodVisible) & ($scope.newConceptInv.save_cod)) {
        if ($scope.newConceptInv.descripcion == undefined) $scope.newConceptInv.descripcion = '';
        operacion_nueva = {codigo_operacion: $scope.newConceptInv.codigo, descripcion_operacion: $scope.newConceptInv.descripcion.toUpperCase(), importe: $scope.newConceptInv.importe }
        $scope.codigoOperacionList.push(operacion_nueva);
        $scope.insertOperacionToServer(operacion_nueva);
      }

      //si han introducido , para los decimales
      $scope.newConceptInv.importe = $scope.newConceptInv.importe.toString().trim().replace(",",".");

      //guardar operación
      $scope.conceptInv.push({codigo: $scope.newConceptInv.codigo, 
        descripcion: $scope.newConceptInv.descripcion, 
        cantidad: $scope.newConceptInv.cantidad, 
        descuento: $scope.newConceptInv.descuento.toString().trim().replace(",","."), 
        importe: $scope.newConceptInv.importe, 
        iva_concepto: $scope.newConceptInv.iva_concepto.id, 
        iva_concepto_showname: $scope.newConceptInv.iva_concepto.showname, 
        iva_concepto_factor: $scope.newConceptInv.iva_concepto.factor, 
        total_operacion: $scope.newConceptInv.total_operacion });

        $scope.clear_presupuestoConceptForm();
    }

    $scope.updateGeneratePresupuesto = function () {
      //guardar cliente proveedor si es nuevo
    if ( ($scope.newGenPre.save_client) && ($scope.saveClientVisible) ){
        if ($scope.newGenPre.name == undefined) $scope.newGenPre.name = '';

        var cliente_modificado = {nombre_cliente: $scope.newGenPre.name.toUpperCase(), cif_cliente: $scope.newGenPre.cif, email: $scope.newGenPre.email, direccion: $scope.newGenPre.direccion}

        //Insertamos cliente en local
        $scope.clienteList.push(cliente_modificado);
        
        //Insertamos cliente en servidor
        $scope.insertClientToServer(cliente_modificado);
      }

    //Actualizamos cliente si ha cambiado datos (mismo NIF)
    if ( ($scope.newGenPre.save_changes) && ($scope.saveChangesVisible) )  {
        updateClient();
      }

    //---- PREPARAR INFORMACIÓN PARA GUARDAR
    $scope.base_imponible0 = $scope.calcular_base_iva(0);
    $scope.base_imponible1 = $scope.calcular_base_iva(1);
    $scope.base_imponible2 = $scope.calcular_base_iva(2);
    $scope.base_imponible3 = $scope.calcular_base_iva(3);

      $scope.newGenPre.total_iva = $scope.calcular_iva();
      $scope.newGenPre.total_presupuesto = $scope.calcular_total();

    //"ÑAPA" PORQUE AL RELACIÓN JQUERY ANGULAR ME CAMBIA A VECES EL VALOR MOMENT A STRING
    if (typeof $scope.newGenPre.date == "string") {
      fecha_insert =  moment($scope.newGenPre.date, 'DD/MM/YYYY');
    } else {
      fecha_insert =  $scope.newGenPre.date;
    }

      //paso base_imponible1..10 pero de momento recalculo en servidor.
      presupuesto_nuevo = {
          lote: $scope.lotePresupuestos,
          fecha: moment(fecha_insert).format('DD/MM/YYYY'),
          fecha_date: fecha_insert,
          idpresupuesto: $scope.newGenPre.idpresupuesto,
          concepto: $scope.newGenPre.concepto,
          control: $scope.newGenPre.control,
          cliente_nombre: $scope.newGenPre.name,
          cliente_cif: $scope.newGenPre.cif,
          cliente_email: $scope.newGenPre.email,
          cliente_direccion: $scope.newGenPre.direccion,
          tipo_presupuesto: $scope.newGenPre.type_presupuesto.name,
          forma_pago: $scope.newGenPre.forma_pago.name,
          plazo_pago: $scope.newGenPre.forma_pago.plazo,
          plazo_vencimiento: $scope.newGenPre.vencimientoPresupuesto.plazo,
          vencimiento_presupuesto_select: $scope.newGenPre.vencimientoPresupuesto,
          tipo_presupuesto_select: $scope.newGenPre.type_presupuesto,
          forma_pago_select: $scope.newGenPre.forma_pago,
          vencimiento: moment(fecha_insert).add($scope.newGenPre.vencimientoPresupuesto.plazo, 'days').format('DD/MM/YYYY'),
          base_imponible: $scope.newGenPre.base,
          base_imponible0: $scope.base_imponible0,
          base_imponible1: $scope.base_imponible1,
          base_imponible2: $scope.base_imponible2,
          base_imponible3: $scope.base_imponible3,
          total_iva: $scope.newGenPre.total_iva,
          total_presupuesto: $scope.newGenPre.total_presupuesto,
          conceptos: $scope.conceptInv,
          observaciones: $scope.newGenPre.observaciones,
          estado: "Pendiente",
      }
      var presupuesto_nuevo_imprimible = {
          lote: $scope.lotePresupuestos,
          fecha: moment(fecha_insert).format('DD/MM/YYYY'),
          idpresupuesto: $scope.newGenPre.idpresupuesto,
          control: $scope.newGenPre.control,
          cliente_nombre: $scope.newGenPre.name,
          cliente_cif: $scope.newGenPre.cif,
          cliente_email: $scope.newGenPre.email,
          cliente_direccion: $scope.newGenPre.direccion,
          tipo_presupuesto: $scope.newGenPre.type_presupuesto.name,
          forma_pago: $scope.newGenPre.forma_pago.name,
          plazo_pago: $scope.newGenPre.forma_pago.plazo,
          plazo_vencimiento: $scope.newGenPre.vencimientoPresupuesto.plazo,
          base_imponible: $scope.newGenPre.base,
          total_iva: $scope.newGenPre.total_iva,
          total_presupuesto: $scope.newGenPre.total_presupuesto,
          observaciones: $scope.newGenPre.observaciones,
          estado: "Pendiente",
        }
    //---- GUARDAR LA FACTURA EN SERVIDOR (Y SI SALE BIEN EN LOCAL)
      $scope.insertPresupuestoToServer($scope.idLotePresupuestoActual, presupuesto_nuevo, presupuesto_nuevo_imprimible, $scope.conceptInv);
    }
    

    $scope.updateLote = function () {
    }


//Funcion para ACTUALIZAR STATUS DEL LOTE (enviado por FTP o por EMAIL)
 $scope.updateLoteStatusToServer = function(idlote, status) {
  $http({
      method: 'POST',
      url: '/empresa/actualizar-status-lote-presupuestos',
      data: {
          _token: $scope.csfrToken,
          empresa_id: $scope.empresa_id,
          idlote: idlote,
          status: status,
      }
  }).then(function success(response) {
     //si se actualiza correctamente en servidor, actualizar en local lote y facturas
     //$scope.lotes_presupuestos.push(response.data.lote_actualizado);
     var presupuestos_lote_actualizado = response.data.presupuestos_lote_actualizado;

      for(var i = 0; i < presupuestos_lote_actualizado.length; i++) {
        presupuestos_lote_actualizado[i].idlote = response.data.lote_actualizado.idlote;
        
        fecha_presupuesto= moment(presupuestos_lote_actualizado[i].fecha, "DD/MM/YYYY");
        if (fecha_presupuesto.isSameOrAfter(moment($scope.fecha_inicial_presupuestos, "DD/MM/YYYY")) && fecha_presupuesto.isSameOrBefore(moment($scope.fecha_final_presupuestos, "DD/MM/YYYY"))) { //insertamos presupuesto para mostrar si esta en el rango de fechas del historial
          $scope.presupuestos_historial.push(presupuestos_lote_actualizado[i]);
        }
      }
      $scope.showNotification('Lote ' + idlote + ' enviado correctamente a la asesoria', 'success-top');
      
      $scope.$parent.lote_presupuestos_sin_enviar = 0;
      $scope.empezarNuevoLote();

  }, function error(response) {
  }); 
 }

//Función para ELIMIMAR FACTURA EN EL SERVIDOR   
  $scope.deletePresupuestoToServer = function(idlote, id_presupuesto_insertado, index) {

      $http({
          method: 'POST',
          url: '/empresa/eliminar-presupuesto',
          data: {
            _token: $scope.csfrToken,
            empresa_id: $scope.empresa_id,
            idlote: idlote,
            id_presupuesto_insertado: id_presupuesto_insertado,
          }
      }).then(function success(response) {
            $scope.genPre.splice(index, 1);
            $scope.listaImprimible.splice(index, 1);
      }, function error(response) {
          $scope.showNotification('No se ha podido eliminar el presupuesto', 'error');
      });  

  }
//Función para INSERTAR FACTURA EN EL SERVIDOR   
  $scope.insertPresupuestoToServer = function(idlote, presupuesto_nuevo, presupuesto_nuevo_imprimible, operaciones) {

      $http({
          method: 'POST',
          url: '/empresa/insertar-presupuesto',
          data: {
              _token: $scope.csfrToken,
              empresa_id: $scope.empresa_id,
              idlote: idlote,
              presupuesto_nuevo: presupuesto_nuevo,
              operaciones: operaciones,
          }
      }).then(function success(response) {

      //---- GUARDAR LA FACTURA EN LOCAL
        //Para vosualizar en tabla
        
        presupuesto_nuevo['id_presupuesto_insertado'] = response.data.id_presupuesto_insertado;
        $scope.$parent.genPre.push(presupuesto_nuevo);

        $scope.$parent.lote_presupuestos_sin_enviar = idlote;


        //Para Imprimir - Excel - Cambia formato según dónde lo queramos exportar (REVISAR SEPARAR IMPRIMIR DE FORMATO ERP)
        $scope.listaImprimible.push(presupuesto_nuevo_imprimible);

        //SI TODO OK
        $scope.xlsxvisible = true;
        // $scope.$parent.idPresupuestoActual = parseFloat(response.data.num_presupuesto_insertado) + 1; //Para idPresupuestoActual numerico
        $scope.clear_form();

          $scope.showNotification('El presupuesto ' + presupuesto_nuevo.idpresupuesto + ' se ha guardado en el servidor', 'success-top');
      }, function error(response) {
          $scope.showNotification('No se han podido guardar el presupuesto ' + presupuesto_nuevo.idpresupuesto, 'error');
      });        
  }

    //TABLA

    $scope.predicates = ['fecha', 'cliente_proveedor', 'tipo_presupuesto', 'base_imponible', 'total_iva', 'total_presupuesto', 'cuenta_cargo', 'cuenta_cargo'];
    $scope.selectedPredicate = $scope.predicates[0];

    $scope.getters={
        fecha: function (value) {
            return new Date(moment(value.fecha, 'D-M-YYYY').toISOString());
        }
    }
    
    //remove to the real data holder
    $scope.removeItem = function removeItem(row) {
        var index = $scope.conceptInv.indexOf(row);
        if (index !== -1) {
            $scope.conceptInv.splice(index, 1);
        }
    };


    $scope.removeItemPresupuesto = function removeItem(row) {
        var index = $scope.genPre.indexOf(row);
        if (index !== -1) {
            //BORRAR EN SERVIDOR
            $scope.deletePresupuestoToServer($scope.idLotePresupuestoActual, row.id_presupuesto_insertado, index);

        }
    };

    //total por operación en la tabla (para que se actualice al cambiar la tabla
    $scope.calcularOperacion = function calcularOperacion(row) {
      var importe =  parseFloat(row.importe || 0);
      var cantidad  =  parseFloat(row.cantidad || 0);
      var descuento = parseFloat(row.descuento.toString().trim().replace(",",".") * 0.01 || 0);
      var iva = parseFloat(row.iva_concepto_factor || 0);
      row.total_operacion = Math.round(parseFloat(importe * cantidad * (1 - descuento) * (1 + iva) || 0) * 100) / 100 || null;
      return Math.round(parseFloat(importe * cantidad * (1 - descuento) * (1 + iva) || 0) * 100) / 100 || null;
    }

    // FIN TABLA
    $scope.sendFTP = function ($event) {
       $event.stopImmediatePropagation();
       ejercicio = moment(Date.now()).format('MM YYYY');

        $http({
            method: 'POST',
            url: '/envios/xlsx-to-ftp',
            data: {_token: $scope.csfrToken, lista_xlsx: $scope.listaImprimible, empresa: $scope.empresaActiva.nombre, cif: $scope.empresaActiva.cif, iderp: $scope.empresaActiva.iderp, ejercicio: ejercicio},
            responseType: 'arraybuffer' 
        }).then(function success(response) {
            $scope.updateLoteStatusToServer($scope.idLotePresupuestoActual, "ftp");
            

        }, function error(response) {
                $scope.showNotification('Petición fallida: ' + response.message, 'error');
        });
    };


    $scope.sendEmail = function ($event) {
       $event.stopImmediatePropagation();

      $http({
        method: 'POST',
        url: '/email/lote-presupuestos-to-asesor',
        data: {
          _token: $scope.csfrToken,
          empresa_id: $scope.empresa_id,
          idlote: $scope.idLotePresupuestoActual,
        },   
        responseType: 'arraybuffer' 
      }).then(function success(response) {
        $scope.updateLoteStatusToServer($scope.idLotePresupuestoActual, "mail");
      }, function error(response) {
          $scope.showNotification('No se ha podido enviar el email. Por favor, vuelva a intentarlo pasados unos instantes', 'error');
      });
    };


    //Cuando enviamos el lote en curso, empezamos uno nuevo
    $scope.empezarNuevoLote = function () {
       $scope.updateLote();
       $scope.clear_lote();
    };

           
    $scope.sendEmailClient = function (row) {
      var index = $scope.genPre.indexOf(row);
      if ((index !== -1) && (row.cliente_email != undefined)) {

        $http({
          method: 'POST',
          url: '/email/presupuesto-to-cliente',
          data: {
            _token: $scope.csfrToken,
            empresa_id: $scope.empresa_id,
            presupuesto_id: row.id_presupuesto_insertado,
            cliente_email: row.cliente_email,
            cliente_nombre: row.cliente_nombre,
          },   
          responseType: 'arraybuffer' 
        }).then(function success(response) {
          $scope.showNotification('Presupuesto enviado a cliente ' + $scope.genPre[index].cliente_nombre + ' (' + $scope.genPre[index].cliente_email + ')', 'success');
        }, function error(response) {
            $scope.showNotification('No se ha podido enviar el email. Por favor, vuelva a intentarlo pasados unos instantes', 'error');
        });
      } 
      else {
        $scope.showNotification('El cliente no tiene una cuenta de correo asociada', 'error');
      }    
    };

//-------

  //generar tabla de lote de presupuestos en xlsx
  $scope.exportList2xlsx = function ($event) {
     $event.stopImmediatePropagation();
     var genPreExcel = [];
     for (var i=0; i<$scope.$parent.genPre.length; i++) { 
        presupuesto = $scope.$parent.genPre[i];
        genPreExcel.push($scope.fromFormatoStandardPresupuestosToERP(presupuesto, 'despachos'));
     } 
     $scope.$parent.exportList2xlsx(genPreExcel);
     $scope.showNotification('Descarga de fichero .xlsx correcta', 'success');
  };

//En función del ERP generar un excel a partir de un objeto con el formato necesario
  $scope.fromFormatoStandardPresupuestosToERP = function(presupuesto, formato_erp) {
    if (presupuesto.total_recargo > 0) {
      var aplicaRecargo = 1;
    } else {
      var aplicaRecargo = 0;
    }

    factor_retenciones = presupuesto.total_retenciones/presupuesto.base_imponible*100;

    switch (formato_erp) {
      case 'despachos':
        var presupuesto_formateado = {
          Lote: presupuesto.idlote,
          Presupuesto: presupuesto.idpresupuesto,
          Fecha: presupuesto.fecha,
          
          'Cliente': presupuesto.cliente_nombre,
          'CIF/DNI': presupuesto.cliente_cif,
          'Concepto': presupuesto.concepto,          
          'Control': presupuesto.control,                    
          '%Retención': number_format_es_ES(presupuesto.retenciones_factor),

          'Base Imponible': number_format_es_ES(presupuesto.base_imponible),
          'Total IVA': number_format_es_ES(presupuesto.total_iva),
          'Total Recargo': number_format_es_ES(presupuesto.total_recargo),
          'Cuota Retención': number_format_es_ES(presupuesto.total_retenciones),          
          'Importe Factura': number_format_es_ES(presupuesto.total_presupuesto),

          'Base Imponible0': number_format_es_ES(presupuesto.base_imponible0),

          'Base Imponible1': number_format_es_ES(presupuesto.base_imponible1),
          'Iva1': '4',
          'Cuota Iva1': number_format_es_ES(presupuesto.base_imponible1 * 0.04),
          '%RecEq1': '0,005',
          'Cuota Rec1': number_format_es_ES(presupuesto.base_imponible1 * 0.005  * aplicaRecargo),

          'Base Imponible2': number_format_es_ES(presupuesto.base_imponible2),
          'Iva2': '10',
          'Cuota Iva2': number_format_es_ES(presupuesto.base_imponible2 * 0.1),
          'RecEq2': '0,014',
          'Cuota Rec2': number_format_es_ES(presupuesto.base_imponible2 * 0.014  * aplicaRecargo),

          'Base Imponible3': number_format_es_ES(presupuesto.base_imponible3),
          'Iva3': '21',
          'Cuota Iva3': number_format_es_ES(presupuesto.base_imponible3 * 0.21),
          'RecEq3': '0,052',
          'Cuota Rec3': number_format_es_ES(presupuesto.base_imponible3 * 0.052  * aplicaRecargo),

          'Observaciones': presupuesto.observaciones,

        }
        break;
    }
    return presupuesto_formateado;
  }

//-------    

    $scope.exportList2pdf = function ($event) {
       $event.stopImmediatePropagation();
       $scope.$parent.exportList2xlsx($scope.$parent.genPre);
       $scope.showNotification('Descarga de fichero .pdf correcta', 'success');
    };

    //generar tabla de lote de presupuestos en pdf
    $scope.pdfPresupuestosList = function ($event) {
      $event.stopPropagation();
            $http({
            method: 'POST',
            url: '/pdf/tabla-presupuestos',
            data: {_token: $scope.csfrToken, presupuestos: $scope.$parent.genPre, empresa: $scope.empresaActiva.nombre, lote: $scope.lotePresupuestos},
            responseType: 'arraybuffer' 
        }).then(function success(response) {
             var file = new Blob([response.data], {type: 'application/pdf'});
             saveAs(file, $scope.empresaActiva.nombre + '_' + moment(Date.now()).format('YYYY-MM-DD_HH-mm-ss') + '.pdf');

        }, function error(response) {
                $scope.showNotification('Petición fallida: ' + response.message, 'error');
                //console.log( 'La petición no se ha producido: ' + response.message); //lshdebug
        });
    };

  $scope.$on('cargarPresupuestoEnPresupuestoHijo', function(event, datos_presuespuesto) {
      $scope.rellenarFormulario(datos_presuespuesto);
  });

  $scope.rellenarFormulario = function(datos_presuespuesto) {
    $scope.clear_form();


    $scope.newGenPre.vencimientoPresupuesto = get_vencimiento_select(datos_presuespuesto.plazo_vencimiento);
    $scope.newGenPre.control = datos_presuespuesto.control; 
    $scope.newGenPre.name = datos_presuespuesto.cliente_nombre;
    $scope.newGenPre.cif = datos_presuespuesto.cliente_cif;
    $scope.newGenPre.email = datos_presuespuesto.cliente_email;
    $scope.newGenPre.direccion = datos_presuespuesto.cliente_direccion;
    $scope.newGenPre.type_invoice = datos_presuespuesto.tipo_presupuesto_select;
    $scope.newGenPre.forma_pago = get_forma_pago_select(datos_presuespuesto.forma_pago);
    $scope.newGenPre.observaciones = datos_presuespuesto.observaciones;
    //Rellena $scope.conceptInv
    $scope.operacionesFromServer(datos_presuespuesto.id);
  }

  get_forma_pago_select = function (forma_pago_name) {

      var arr = $scope.data.formasPago; 
      var result  = arr.filter(function(o){return o.name == forma_pago_name;} );
      return result[0]; 

  }

  get_vencimiento_select = function (plazo_vencimiento) {

      var arr = $scope.data.vencimientoPresupuesto; 
      var result  = arr.filter(function(o){return o.plazo == plazo_vencimiento;} );
      return result[0]; 

  }

  $scope.operacionesFromServer = function (presupuesto_id) {
          $http({
          method: 'POST',
          url: '/empresa/operaciones-presupuesto',
          data: {
            _token: $scope.csfrToken,
            empresa_id: $scope.empresa_id,
            presupuesto_id: presupuesto_id,
          }
      }).then(function success(response) {
        $scope.conceptInv = response.data.operaciones;
           
      }, function error(response) {
              $scope.showNotification('No se han podido cargar las operaciones', 'error');
              //console.log( 'La petición no se ha producido: ' + response.message); //lshdebug
      });
  };


  //SUBMIT
    $scope.operacionesFormSubmit = function($event)  {
      $event.stopPropagation();
      if ($scope.operacionesForm.$invalid) {
        $scope.operacionesForm.$setSubmitted();

      } else {
        $scope.newPresupuestoConcept();
      }
    }

    $scope.generarPresupuestoFormSubmit = function($event)  {
      $event.stopPropagation();

      if (($scope.generarPresupuestoForm.$invalid) || ($scope.conceptInv.length == 0)) {
        $scope.generarPresupuestoForm.$setSubmitted();
        if ($scope.generarPresupuestoForm.$invalid) {
          $scope.showAlert('El formulario contiene datos incorrectos', 'error');
        } else {
          $scope.showAlert('Es necesario incluir al menos una operación en la factura', 'error');
        }
        
      } else {
        $scope.updateGeneratePresupuesto();
      }
    }

//CLEAR

    $scope.clear_form = function () {

      $scope.clear_presupuestoConceptPanel();
      //LIMPIAR ATRIBUTOS DE FORMULARIO
      $scope.generarPresupuestoForm.$setUntouched();
      $scope.generarPresupuestoForm.$setPristine();
      $scope.generarPresupuestoForm.$submitted = false;

    $( "#dateinput-presupuesto" ).datepicker( "setDate", moment().format('DD/MM/YYYY'));
    $scope.newGenPre.date =  moment().format('DD/MM/YYYY');    

      // $scope.newGenPre.idpresupuesto = $scope.$parent.idPresupuestoActual; //Para idPresupuestoActual numerico
      $scope.newGenPre.idpresupuesto = '';
      $scope.newGenPre.concepto = '';
      $scope.newGenPre.control = '';
      $scope.newGenPre.name = '';
      $scope.newGenPre.cif = '';
      $scope.newGenPre.email = '';
      $scope.newGenPre.direccion = '';
      $scope.newGenPre.observaciones = '';
      $scope.newGenPre.type_presupuesto = $scope.data.typePresupuestoOptions[0];
      $scope.newGenPre.forma_pago = $scope.data.formasPago[0];
      $scope.newGenPre.vencimientoPresupuesto = $scope.data.vencimientoPresupuesto[2];
      $scope.newGenPre.type_iva = $scope.data.ivaOptions[3];
      $scope.saveClientVisible = false;
      $scope.saveChangesVisible = false;
    }

    $scope.clear_presupuestoConceptForm = function () {
      //LIMPIAR ATRIBUTOS DE FORMULARIO
      $scope.operacionesForm.$setUntouched();
      $scope.operacionesForm.$setPristine();
      $scope.operacionesForm.$submitted = false;
      
      $scope.newConceptInv.codigo = '';
      $scope.newConceptInv.cantidad = '';
      $scope.newConceptInv.descuento = '';
      $scope.newConceptInv.descripcion = '';
      $scope.newConceptInv.importe = '';
      $scope.newConceptInv.cantidad = 1;
      $scope.newConceptInv.descuento = 0;
      $scope.saveCodVisible = false;
      $scope.operacionesForm.$submitted = false;
    }

    $scope.clear_presupuestoConceptPanel = function () {
      $scope.clear_presupuestoConceptForm();
      $scope.conceptInv = []; 
    }

  $scope.clear_lote = function () {
    $scope.xlsxvisible = false;
    $scope.clear_form();
    $scope.$parent.genPre = [];
    $scope.$parent.idLotePresupuestoActual++;
    $scope.lote_presupuestos_cargado_sin_enviar = false;
  }

    
}]);

