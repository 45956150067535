/**
 * Created by agf on 20/12/2016.
 */
contaApp.controller('DocumentsPanelController', ['$rootScope', '$scope', '$http', '$filter', function ($rootScope, $scope, $http, $filter) {
    var $ctrl = this;
    $ctrl.itemsByPage = 10;
    $ctrl.showActions = {};
    $ctrl.formVisible = false;
    $scope.vista_activa = null;
    $scope.disponible = function () {
        return 'disponible';
    };
    $rootScope.$on('vista_activa', function (e, a) {
        $scope.vista_activa = a.vista;
    });
    $ctrl.newRole = {
        id: 0,
        name: 'rol',
        description: 'descripcion'
    };
    $ctrl.showFormNewRole = function ($event) {
        $event.stopImmediatePropagation();
        $ctrl.formVisible = !$ctrl.formVisible;
    };
    $ctrl.createNewRole = function ($event) {
        $event.stopImmediatePropagation();
        $http.post('/admin/save/role?view=' + window.Laravel.viewId, {payload: $ctrl.newRole}).then(function (res) {
            $ctrl.newRole.id = res.data.id;
            $scope.roles.unshift($ctrl.newRole);
        })
    };
    $ctrl.selectedRow = function (row) {
        $ctrl.showActions == row.name ? $ctrl.showActions = {} : $ctrl.showActions = row.name;
    };
    $ctrl.updateField = function (row) {
        $http.post('/admin/save/role?view=' + window.Laravel.viewId, {payload: row}).then(function (res) {
            console.log(res);
        });
    };
    $ctrl.deleteRole = function ($event, row) {
        $event.stopImmediatePropagation();
        $http.post('/admin/delete/role?view=' + window.Laravel.viewId, {payload: row}).then(function (res) {
            console.log(res);
        });
        $scope.roles = $filter('filter')($scope.roles, {id: '!' + row.id});
    };
    $ctrl.cancelUpload = function ($event) {
        $event.stopImmediatePropagation();
        $ctrl.formVisible = !$ctrl.formVisible;
    };
}]);