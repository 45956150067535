contaApp.controller('EmployeeUnregisterController', ['$scope', '$http', '$filter', function($scope, $http, $filter, $visits) {

    $scope.employeeList = [
                {nombre_empleado: 'ELENA CAÑIZO', dni_empleado: '92073312B'},
                {nombre_empleado: 'ÁNGEL REYES', dni_empleado: '51307223L'},
                {nombre_empleado: 'MANUEL PEDROCHE', dni_empleado: '78719997J'},
                {nombre_empleado: 'ANDRÉS GARCÍA', dni_empleado: '10707430X'},
                {nombre_empleado: 'RAQUEL POLO', dni_empleado: '35255769N'},
                {nombre_empleado: 'NURIA BELTRAN', dni_empleado: '02040387B'},
                {nombre_empleado: 'EMILIO GÓMEZ', dni_empleado: '23507257S'},
                {nombre_empleado: 'JOSEFINA CASAS', dni_empleado: '93681389C'},
                {nombre_empleado: 'LUIS PÉREZ', dni_empleado: '07448651D'},
    ];


    $scope.newEmpUnreg = {};

    // Search employee by name
    $('#employeeSearch').autocomplete({
        source: function (request, response) {
            var term = request.term;
            var data = employeeAutoComplete(term, $scope);
            response(data);
        },
        minLength: 1,
        focus: function (event, ui) {
            $(this).val(ui.item.dni_empleado + ' - ' + ui.item.nombre_empleado);
            return false;
        },
        select: function (event, ui) {
            $(this).val(ui.item.dni_empleado + ' - ' + ui.item.nombre_empleado);
            $('#newEmpUnregName').text(ui.item.nombre_empleado);
            $scope.newEmpUnreg.dni = ui.item.dni_empleado;
            $scope.newEmpUnreg.name = ui.item.nombre_empleado;
            $scope.$digest();
            return false;
        },
        messages: {
            noResults: '',
            results: function () {
            }
        }
    }).autocomplete('instance')._renderItem = function (ul, item) {
        return $("<li>")
            .append("<span class='ac-option'>" + item.dni_empleado + " - " + item.nombre_empleado + "</span>")
            .appendTo(ul);
    };
    //.append("<a class='btn'>" + item.dni_empleado + " - " + item.nombre_empleado + "</a>")

    function employeeAutoComplete(term, $scope) {
        return $.map($scope.employeeList, function (item) {
            if (item.nombre_empleado.match(term.toUpperCase()) || item.dni_empleado.match(term)) {
                return item;
            }
            return null;
        });
    };


    $scope.updateProfileEmployeeUnregister = function ($event) {
        $event.stopPropagation();
        console.log($scope.newEmpUnreg); //lshdebug
        $http({
            method: 'POST',
            url: 'client/update-notice',
            data: {_token: $scope.csfrToken, companyCode: $scope.selectedCompany, empUnreg: $scope.newEmpUnreg}
        }).then(function success(response) {
            if (response.data.outcome) {
                $scope.showNotification('Se han guardado los cambios', 'success');
                $scope.xlsxvisible = true;
                //$scope.$parent.empUnreg = []; //lo inicializo temporalmente para no acumular
                register = {
                    fecha_baja: moment($scope.newEmpUnreg.dateunregister).format('DD/MM/YYYY'),
                    dni: $scope.newEmpUnreg.dni,
                    nombre: $scope.newEmpUnreg.name,
                }
                $scope.$parent.empUnreg.push(register);
                $scope.clear_form();
            } else {
                $scope.showNotification('No se han guardado los cambios. Se ha producido un error: ' + response.message, 'error');
                //console.log( 'Petición devuelve error: ' + response.message); //lshdebug
            }
        }, function error(response) {
                $scope.showNotification('No se han guardado los cambios. Se ha producido un error: ' + response.message, 'error');
                //console.log( 'La petición no se ha producido: ' + response.message); //lshdebug
        });
    };

    $scope.updateLote = function () {
    }

    //TABLA

    $scope.predicates = ['dni', 'nombre', 'fecha_baja'];
    $scope.selectedPredicate = $scope.predicates[0];

    $scope.getters={
        fecha_baja: function (value) {
            return new Date(moment(value.fecha_baja, 'D-M-YYYY').toISOString());
        }
    }
    
    //remove to the real data holder
    $scope.removeItem = function removeItem(row) {
        var index = $scope.$parent.empUnreg.indexOf(row);
        if (index !== -1) {
            $scope.$parent.empUnreg.splice(index, 1);
        }

    };

    $scope.editItem = function editItem(row) {
        var index = $scope.$parent.empUnreg.indexOf(row);
        if (index !== -1) {
            $scope.newEmpUnreg = row;
            $scope.$parent.empUnreg.splice(index, 1);
            window.scrollTo(0, 0);
        }
    };

    // FIN TABLA

    
  //LIMPIAR FORMULARIO
  $scope.clear_form = function () {
    $scope.newEmpUnreg = {};
  }

  $scope.clear_lote = function () {
    $scope.xlsxvisible = false;
    $scope.$parent.empUnreg = [];
    $scope.$parent.loteBajas++;
  }
    // FIN TABLA
// ACCIONES POR REMESA
  $scope.sendFTP = function ($event) {
     $event.stopImmediatePropagation();
     $scope.updateLote();
     $scope.showNotification('Fichero xlsx enviado por FTP (80.38.217.58). Se ha generado el lote ' + $scope.loteBajas, 'success');
     $scope.clear_lote();
  };

  $scope.sendEmail = function ($event) {
    $event.stopImmediatePropagation();
    $scope.updateLote();
    $scope.clear_lote(); 
    $scope.showNotification('Fichero xlsx enviado a facturas@lsh-asesor.es. Se ha generado el lote ' + $scope.loteBajas, 'success');
  };
    $scope.exportList2xlsx = function ($event) {
       $event.stopImmediatePropagation();
       $scope.$parent.exportList2xlsx($scope.$parent.empreg);
    };


}]);

    