/**
 * Created by agf on 20/12/2016.
 */
contaApp.controller('AsesoresListController', ['$rootScope', '$scope', '$http', '$filter',
    function ($rootScope, $scope, $http, $filter) {
        var currentApp = $('#wrapper').data('app-id');
        $scope.localLang = {
            selectAll: "Elegir todos",
            selectNone: "Ninguno",
            reset: "Deshacer",
            search: "Escribir para buscar...",
            nothingSelected: "Sin selección"
        };
        $scope.editModeFlag = false;
        $scope.showActions = {};
        $scope.itemsByPage = 10;
        $scope.formVisible = false;
        $scope.clientForm = {};
        $scope.usuarioDemo = {
            id: 0,
            active: 1,
            name: '',
            dni: '',
            email: '',
            mailed: 0,
            role_id: 3,
            roles: [{id: 3, name: 'Usuario'}, {id: 6, name: 'Usuario'}],
            cabecera_apps: [{id: 1, nombre: 'facturacion'}, {id: 2, nombre: 'documentos'}],
            modulos: [{id: 2, nombre_modulo: 'facturas_con_operaciones'}, {id: 4, nombre_modulo: 'documentacion_base'}],
            empresas: [{id: 0, nombre: 'sin selección'}],
            selectedEmpresas: []
        };
        $scope.currentClient = angular.copy($scope.usuarioDemo);
        $scope.userChangeEmpresa = function (userRow) {
            $http.post('/admin/save/asesor', {payload: userRow}).then(function (res) {
                userRow = res.data;
            });
        };
        $scope.selectedRow = function (userRow, $event) {
            $event.stopImmediatePropagation();
            $event.preventDefault();
            if ($scope.showActions == userRow.name) {
                $scope.showActions = {}
            } else {
                $scope.showActions = userRow.name;
            }
        };
        $scope.hasApp = function (userRow, key) {
            if (!angular.isUndefined(userRow)) {
                return ($filter('filter')(userRow.cabecera_apps, {'nombre': key})).length === 1;
            }
            return false;
        };
        $scope.hasModule = function (userRow, key) {
            if (!angular.isUndefined(userRow)) {
                return ($filter('filter')(userRow.modulos, {'nombre_modulo': key})).length === 1;
            }
            return false
        };
        $scope.newUserBtn = function () {
            $scope.formVisible = true;
            $('#asesor-form-wrapper').attr('colspan', 7);
            $scope.currentClient = angular.copy($scope.usuarioDemo);
            $scope.currentClient.empresas = angular.copy($scope.companies);

            $scope.editModeFlag = false;
            markCheckboxes();
        };
        $scope.editMode = function ($event, userRow, $index) {
            $event.stopImmediatePropagation();
            $event.preventDefault();
            $('#asesor-form-wrapper').attr('colspan', 7);
            $scope.editModeFlag = true;
            $scope.formVisible = true;
            angular.copy(userRow, $scope.currentClient);
            markCheckboxes();
        };
        $scope.load_empresas_list = function () {
            $http.get(currentApp + '/1/empresa_list').then(function (res) {
                var resutls = [];
                angular.forEach(res.data.empresasList, function (o, i) {
                    o.avilableFolders = angular.copy($scope.adminFolders);
                    var carpeta = o.carpeta.display_name;
                    // console.log(carpeta);
                    angular.forEach(o.avilableFolders, function (obj, ii) {
                        var lista = obj.name;
                        // console.log(lista);
                        // console.log(carpeta == lista);
                        if (carpeta == lista) obj.selected = true;
                    });
                    resutls.push(o);
                });
                $scope.empresas_list = resutls;
            });
        };
        $scope.cancelUserEdition = function ($event) {
            $event.stopImmediatePropagation();
            $event.preventDefault();
            $('#asesor-form-wrapper').attr('colspan', 1);
            $scope.formVisible = false;
        };
        $scope.saveUserEdition = function ($event) {
            $event.stopImmediatePropagation();
            $event.preventDefault();

            if ( $('form[name="newAsesorForm"]').hasClass('ng-valid-pwmatch') ||
                 !$scope.currentClient.cambiarContrasena ) {

                
                $http.post('/admin/save/asesor', {payload: $scope.currentClient}).then(function (res) {
                    
                    switch (res.data.message) {
                        case 'insert_email_repetido':
                            $scope.showNotification('El email ya existe. Por favor, inténtelo con otro email', 'warning');
                            break;

                        case 'insert_dni_repetido':
                            $scope.showNotification('El DNI ya existe. Por favor, inténtelo con otro DNI', 'warning');
                            break;

                        case 'insert_no_dni':
                            $scope.showNotification('Es necesario que inserte un DNI', 'warning');
                            break;

                        default:
                            $scope.asesoresList = res.data.asesoresList;
                            $('#asesor-form-wrapper').attr('colspan', 1);
                            $scope.formVisible = false;
                    }

                });
            }

        };
        $scope.deleteUser = function ($event, userRow, $index) {
            $event.stopImmediatePropagation();
            $event.preventDefault();
            $http.post('/admin/delete/user', {payload: {id: userRow.id}}).then(function (res) {
                $scope.asesoresList = $filter('filter')($scope.asesoresList, {id: '!' + userRow.id});
            });
        };
        function markCheckboxes() {
            $scope.currentClient.appInvoice = $scope.hasApp($scope.currentClient, 'facturacion');
            $scope.currentClient.appDocs = $scope.hasApp($scope.currentClient, 'documentos');
            $scope.currentClient.moduleLaboral = $scope.hasModule($scope.currentClient, 'laboral');
            $scope.currentClient.moduleInvoicesOps = $scope.hasModule($scope.currentClient, 'facturas_con_operaciones');
            $scope.currentClient.moduleInvoicesNoOps = $scope.hasModule($scope.currentClient, 'facturas_sin_operaciones');
        };

    }]);