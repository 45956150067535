
contaApp.controller('SelectCompanyController', ['$scope', '$http', '$filter', function($scope, $http, $filter, $visits) {

    $scope.companyList = [
        {nombre_empleado: "PEDRO CAMPILLO SEDANO", dni_empleado: "11830794J"},
        {nombre_empleado: "PEDRO LUIS ENCINA DELGADO", dni_empleado: "22594593T"},
    ];

    /* $scope.selectCompany = function ($event) {
        companyId = $($event.target).data('company');
        $scope.setActiveCompany(companyId);
        setViewVisible('#clientMainPanel', '#generatedInvoiceView');
        $('#generatedInvoicesLink').addClass('active');

        $scope.$parent.empresaActiva = $scope.$parent.empresas[companyId];
        $scope.$parent.ide = companyId;
    }

    $scope.setActiveCompany = function (companyId) {
        section = $("#companies-list");
        var i = 0;
        $.each($(section).find('li'), function (i, o) {
            if ( i == companyId ) {
                $(o).addClass('active');
            } else {
                $(o).removeClass('active');
            }
            i++;
        });
    }

    $scope.showCompanyProfile = function ($event) {
        $scope.selectCompany($event);
        setViewVisible('#clientMainPanel', '#modifyCompanyView');
        $('#generatedInvoicesLink').removeClass('active');
    }*/
    
    
}]);

    