contaApp.controller('RecordGeneratedInvoicesController', ['$scope', '$http', '$filter', function($scope, $http, $filter, $visits) {


//SUBMIT EMITIDAS
  $scope.rangoFechasFormSubmit = function($event)  {
    $event.stopPropagation();

    if ($scope.rangoFechasEmitidas.inicio > $scope.rangoFechasEmitidas.final ) {
      $scope.rangoFechasForm.$setSubmitted();
      $scope.showAlert('La fecha de inicio debe ser anterior a la fecha de fin', 'error');
    } else {
      $scope.getFacturasEmitidasFromServer($scope.empresa_id,moment( $scope.rangoFechasEmitidas.inicio).format('DD/MM/YYYY'), moment( $scope.rangoFechasEmitidas.final).format('DD/MM/YYYY'));
      $scope.clear_form();
    }
  }

  $scope.clear_form = function () {
      //LIMPIAR ATRIBUTOS DE FORMULARIO
      $scope.rangoFechasForm.$setUntouched();
      $scope.rangoFechasForm.$setPristine();
      $scope.rangoFechasForm.$submitted = false;
  }

//SUBMIT RECIBIDAS
  $scope.rangoFechasRecibidasFormSubmit = function($event)  {
    $event.stopPropagation();

    if ($scope.rangoRecibidas.inicio > $scope.rangoRecibidas.final ) {
      $scope.rangoFechasRecibidasForm.$setSubmitted();
      $scope.showAlert('La fecha de inicio debe ser anterior a la fecha de fin', 'error');
    } else {
      $scope.getFacturasRecibidasFromServer($scope.empresa_id,moment( $scope.rangoRecibidas.inicio).format('DD/MM/YYYY'), moment( $scope.rangoRecibidas.final).format('DD/MM/YYYY'));
      $scope.clear_form();
    }
  }

  $scope.clear_form = function () {
      //LIMPIAR ATRIBUTOS DE FORMULARIO
      $scope.rangoFechasRecibidasForm.$setUntouched();
      $scope.rangoFechasRecibidasForm.$setPristine();
      $scope.rangoFechasRecibidasForm.$submitted = false;
  }

//Para ordenar tabla
  $scope.getters = {
    fecha: function (value) {
        return new Date(moment(value.created_at, moment.ISO_8601).toISOString());
    },
    ftp: function (value) {
        return value.enviado_ftp ? 1 : 0 ;
    },
    email: function (value) {
        return value.enviado_mail ? 1 : 0  ;
    },
  }

    $scope.viewRegisterEmitidas = function (row) {
      $scope.idSingleRecord = row.id;
      $scope.$parent.ver_todas_facturas_emitidas = false;
      $scope.$parent.vista_srcgi = "cliente";
      var aux = angular.copy($scope.facturas_emitidas);
      $scope.$parent.facturas_emitidas_recibidas_porlote = aux.filter(
        function(currentValue, index, aux) { 

          return ( currentValue.idlote == row.idlote );
        }

      );

      $scope.mostrarVista('singleRecordGeneratedInvoicesView');      
    };

    $scope.viewAllRegisterEmitidas = function () {
      $scope.$parent.ver_todas_facturas_emitidas = true;
      $scope.$parent.vista_srcgi = "cliente";
      $scope.$parent.facturas_emitidas_recibidas_porlote = $scope.facturas_emitidas;
      $scope.mostrarVista('singleRecordGeneratedInvoicesView');
    }

    $scope.viewRegisterRecibidas = function (row) {
      $scope.idSingleRecord = row.id;
      $scope.$parent.ver_todas_facturas_recibidas = false;
      $scope.$parent.vista_srcgi = "proveedor";
      var aux = angular.copy($scope.facturas_recibidas);
      $scope.$parent.facturas_emitidas_recibidas_porlote = aux.filter(
        function(currentValue, index, aux) { 

          return ( currentValue.idlote == row.idlote );
        }
      );
      $scope.mostrarVista('singleRecordGeneratedInvoicesView');

        consola('facturas_emitidas_recibidas_porlote', $scope.facturas_emitidas_recibidas_porlote);
    };

    $scope.viewAllRegisterRecibidas = function () {
      $scope.$parent.ver_todas_facturas_recibidas = true;
      $scope.$parent.vista_srcgi = "proveedor";
      $scope.$parent.facturas_emitidas_recibidas_porlote = $scope.facturas_recibidas;
      $scope.mostrarVista('singleRecordGeneratedInvoicesView');

        consola('facturas_emitidas_recibidas_porlote', $scope.facturas_emitidas_recibidas_porlote);
    }

 }]);