/**
 * Created by agf on 20/12/2016.
 */
contaApp.controller('FolderController', ['$scope', '$http', function ($scope, $http) {
    var $ctrl = this;
    $ctrl.itemsByPage = 10;
    $ctrl.showActions = {};
    $ctrl.loadFolder = function (folderName) {
        $http.post('/easydocs/list', {folder: folderName}).then(function (res) {
            var results = [];
            angular.forEach(res.data, function (v, k) {
                results.push({name: k, type: v.type, size: v.size, date: v.day + '/' + v.month})
            });
            console.log(results);
            $scope.mainFolderContent = results;
            if ($scope.currentLocation.length == 0) $scope.currentLocation.push({name: folderName});
        });
    };
    $ctrl.selectedRow = function (row) {
        console.log(row);
        $ctrl.showActions == row.name ? $ctrl.showActions = {} : $ctrl.showActions = row.name;
    };
    $ctrl.open = function ($event, row) {
        $event.stopImmediatePropagation();
        console.log('row type: ' + row.type);
        switch (row.type) {
            case 'directory':
                $scope.currentLocation.push({name: row.name});
                $ctrl.loadFolder($scope.currentLocation.map(function (e) {
                    return e.name;
                }).join('/'));
                break;
            case 'file':

        }
    };
    $ctrl.crumbClick = function (crumb) {
        console.log(crumb);
    };
    $ctrl.downloadFile = function ($event, row) {
        $event.stopImmediatePropagation();
        console.log(row);
    }
}])
;